import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TripTiksClaimsService } from './trip-tiks-claims.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { PaginationQuery } from '../../issuing/_shared/models/issuing.models';
import { TripTiksClaimsVm, TripTiksClaimsSearchQuery, TripTikClaimDto } from '../models/trip-tik-claim.model';


export class TripTiksClaimsDataSource implements DataSource<TripTikClaimDto> {
    public TripTiksClaimsSubject = new BehaviorSubject<TripTikClaimDto[]>([]);
    public countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private tripTiksClaimsService: TripTiksClaimsService, private loader: LoadingService) { }

    load(pagination: PaginationQuery, query: TripTiksClaimsSearchQuery | string = null, statusId?: number, pursuingZoneId?: number) {
        this.tripTiksClaimsService[(query == null || typeof query == 'string') ? 'getClaims' : 'search'](pagination, <any>query, statusId, pursuingZoneId)
            .pipe(
                catchError(() => of([])),
                finalize(() => { })
            )
            .subscribe((result: TripTiksClaimsVm) => {
                // console.log(result);
                this.TripTiksClaimsSubject.next(result.list);
                this.countSubject.next(result.recordsCount);
                this.loader.load(LoaderType.Nav, false);
                this.loader.load(LoaderType.Body, false);
            });
    }

    connect(): Observable<TripTikClaimDto[]> {
        return this.TripTiksClaimsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.TripTiksClaimsSubject.complete();
        this.countSubject.complete();
    }
}
