import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Role } from 'src/app/core/models/enums/role';
import { AuthGuardService as AuthGuard } from './core/auth/auth-guard.service';

import { AccountUsersComponent } from './features/security/containers/account-users/account-users.component';
import { VehicleColorsComponent } from './features/lookups/containers/vehicle-colors/vehicle-colors.component';
import { StockTypesComponent } from './features/lookups/containers/stock-types/stock-types.component';
import { AccountTypesComponent } from './features/lookups/containers/account-types/account-types.component';
import { AccountStatusesComponent } from './features/lookups/containers/account-statuses/account-statuses.component';
import { CitiesComponent } from './features/lookups/containers/cities/cities.component';
import { UsersGroupsComponent } from './features/security/containers/users-groups/users-groups.component';
import { SecurityGroupsComponent } from './features/security/containers/security-groups/security-groups.component';
import { PopulateComponent } from './features/stock/containers/populate/populate.component';
import { StocksAvailableComponent } from './features/stock/containers/stocks-available/stocks-available.component';
import { StocksTotalsComponent } from './features/stock/containers/stocks-totals/stocks-totals.component';
import { LoginComponent } from './core/components/login/login.component';
import { MainComponent } from './core/components/main/main.component';
import { CountriesComponent } from './features/lookups/containers/countries/countries.component';
import { AccountsComponent } from './features/accounts/containers/accounts/accounts.component';
import { TransactionsComponent } from './features/stock/containers/transactions/transactions.component';
import { TransactComponent } from './features/stock/containers/transact/transact.component';
import { UndoTryComponent } from './features/stock/containers/undo-try/undo-try.component';
import { UndoRequestsComponent } from './features/stock/containers/undo-requests/undo-requests.component';
import { VehicleMakesComponent } from './features/lookups/containers/vehicle-makes/vehicle-makes.component';
import { ManageAccountComponent } from './features/accounts/containers/manage-account/manage-account.component';
import { UserProfileComponent } from './features/accounts/containers/user-profile/user-profile.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ManageUserComponent } from './features/security/containers/manage-user/manage-user.component';
import { TranslationResolver } from './shared/helpers/translation.resolver';
import { IntLicensesComponent } from './features/issuing/int-licenses/containers/int-licenses/int-licenses.component';
import { ManageIntLicenseComponent } from './features/issuing/int-licenses/containers/manage-int-license/manage-int-license.component';
import { ViewIntLicenseComponent } from './features/issuing/int-licenses/containers/view-int-license/view-int-license.component';
import { TripTiksComponent } from './features/issuing/trip-tik/containers/trip-tiks/trip-tiks.component';
import { ViewTripTikComponent } from './features/issuing/trip-tik/containers/view-trip-tik/view-trip-tik.component';
import { ManageTripTikComponent } from './features/issuing/trip-tik/containers/manage-trip-tik/manage-trip-tik.component';
import { VehicleTypesComponent } from './features/lookups/containers/vehicle-types/vehicle-types.component';
import { VehicleModelsComponent } from './features/lookups/containers/vehicle-models/vehicle-models.component';
import { CurrenciesComponent } from './features/lookups/containers/currencies/currencies.component';
import { TripTiksClaimsComponent } from './features/trip-tiks-claims/containers/trip-tiks-claims/trip-tiks-claims.component';
import { VerificationComponent } from './features/issuing/_shared/containers/verification/verification.component';
import { DocumentsRevokeRequestsComponent } from './features/issuing/_shared/containers/documents-revoke-requests/documents-revoke-requests.component';
import { DashboardComponent } from './features/statistics/containers/dashboard/dashboard.component';
import { UpdateStatusTripTikClaimComponent } from './features/trip-tiks-claims/containers/update-status-trip-tik-claim/update-status-trip-tik-claim.component';
import { ManageTripTikClaimComponent } from './features/trip-tiks-claims/containers/manage-trip-tik-claim/manage-trip-tik-claim.component';
import { ViewTripTikClaimComponent } from './features/trip-tiks-claims/containers/view-trip-tik-claim/view-trip-tik-claim.component';
import { TripTiksClaimsLogsComponent } from './features/trip-tiks-claims/containers/trip-tiks-claims-logs/trip-tiks-claims-logs.component';
import { ConfigResolver } from './shared/helpers/config.resolver';
import { TripTikClaimAttachmentsComponent } from './features/trip-tiks-claims/containers/trip-tik-claim-attachments/trip-tik-claim-attachments.component';
import { RecoverPasswordComponent } from './core/components/recover-password/recover-password.component';
import { CustomsPortsComponent } from './features/lookups/containers/customs-ports/customs-ports.component';
import { PursuingZonesComponent } from './features/lookups/containers/pursuing-zones/pursuing-zones.component';
import { ExpiredDocumentsQueryComponent } from './features/issuing/_shared/containers/expired-documents-query/expired-documents-query.component';
import { ViewAccountComponent } from './features/accounts/containers/view-account/view-account.component';
import { IssuedDocumentsQueryComponent } from './features/issuing/_shared/containers/issued-documents-query/issued-documents-query.component';
//import { AppConfigResolver } from './shared/helpers/app-config.resolver';
import { TableComponent } from './Nweebaa-Update/table/table.component';

const routes: Routes = [
  { path: 'verify/:key', data: { title: '_APP_TITLE' }, component: VerificationComponent },
  { path: 'verify-license/:key', redirectTo: '/verify/:key' },    //old-system
  { path: 'login', data: { title: '_APP_TITLE' }, component: LoginComponent, resolve: { x: TranslationResolver/*, y: AppConfigResolver */ }, runGuardsAndResolvers: 'always' },
  { path: 'recover-password', data: { title: '_APP_TITLE' }, component: RecoverPasswordComponent, resolve: { x: TranslationResolver/*, y: AppConfigResolver */ }, runGuardsAndResolvers: 'always' },
  { path: '', pathMatch: 'full', redirectTo: '/ar' },
  {
    path: ':lang', canActivate: [AuthGuard], component: MainComponent, resolve: { x: TranslationResolver/*, y: AppConfigResolver*/, z: ConfigResolver }, children: [
      { path: '', data: { title: '_APP_TITLE' }, canActivate: [AuthGuard], pathMatch: 'full', component: DashboardComponent },  
      { path: 'table-page', canActivate: [AuthGuard], data: { title: 'تربتك نويبع' ,securityRoles: [Role.GOMROK_NWEEBA]},component: TableComponent },                                              //home component
      {
        path: 'lookups', canActivate: [AuthGuard], data: { title: 'LKP_LINK_BASIC_DATA', securityRoles: [Role.LOOKUPS_CREATE, Role.LOOKUPS_UPDATE, Role.LOOKUPS_DELETE] }, children: [
          { path: '', pathMatch: 'full', redirectTo: 'countries' },                                                                             //default route for parent
          { path: 'countries', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_COUNTRIES' }, component: CountriesComponent, runGuardsAndResolvers: 'always' },
          { path: 'cities', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_CITIES' }, component: CitiesComponent },
          { path: 'account-statuses', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_ACCOUNT_STATUSES' }, component: AccountStatusesComponent },
          //{ path: 'account-types', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_ACCOUNT_TYPES' }, component: AccountTypesComponent },
          { path: 'stock-types', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_STOCK_TYPES' }, component: StockTypesComponent },
          { path: 'vehicle-colors', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_VEHICLE_COLORS' }, component: VehicleColorsComponent },
          { path: 'vehicle-makes', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_VEHICLE_MAKES' }, component: VehicleMakesComponent },
          { path: 'vehicle-types', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_VEHICLE_TYPES' }, component: VehicleTypesComponent },
          { path: 'vehicle-models', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_VEHICLE_MODELS' }, component: VehicleModelsComponent },
          { path: 'currencies', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_CURRENCIES' }, component: CurrenciesComponent },
          { path: 'customs-ports', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_CUSTOMS_PORTS' }, component: CustomsPortsComponent },
          { path: 'pursuing-zones', canActivate: [AuthGuard], data: { title: 'LKP_TITLE_PURSUING_ZONES' }, component: PursuingZonesComponent }
        ]
      },
      {
        path: 'stocks', canActivate: [AuthGuard], data: { title: 'STK_TITLE_STOCK' }, children: [
          { path: '', pathMatch: 'full', redirectTo: 'totals' },                                                                                //default route for parent
          { path: 'totals', canActivate: [AuthGuard], data: { title: 'STK_TITLE_STOCKS_TOTALS', securityRoles: [Role.STOCKS_TRANSACTIONS_READ] }, component: StocksTotalsComponent },
          { path: 'totals/:accountId', canActivate: [AuthGuard], data: { title: 'STK_TITLE_STOCKS_TOTALS', securityRoles: [Role.STOCKS_TRANSACTIONS_READ] }, component: StocksTotalsComponent },
          { path: 'available', canActivate: [AuthGuard], data: { title: 'STK_TITLE_AVAILABLE_STOCKS', securityRoles: [Role.STOCKS_TRANSACTIONS_CREATE, Role.STOCKS_UNDO_REQUESTS_CREATE, Role.INT_LICENSES_CREATE, Role.INT_LICENSES_UPDATE, Role.TRIPTIKS_CREATE, Role.TRIPTIKS_UPDATE] }, component: StocksAvailableComponent },
          { path: 'available/:accountId', canActivate: [AuthGuard], data: { title: 'STK_TITLE_AVAILABLE_STOCKS', securityRoles: [Role.STOCKS_TRANSACTIONS_CREATE, Role.STOCKS_UNDO_REQUESTS_CREATE, Role.INT_LICENSES_CREATE, Role.INT_LICENSES_UPDATE, Role.TRIPTIKS_CREATE, Role.TRIPTIKS_UPDATE] }, component: StocksAvailableComponent },
          { path: 'system-available', canActivate: [AuthGuard], data: { title: 'STK_TITLE_AVAILABLE_SYSTEM_STOCKS', securityRoles: [Role.SYSTEM_STOCKS_READ], mode: 'SYSTEM' }, component: StocksAvailableComponent },

          { path: 'transactions', canActivate: [AuthGuard], data: { title: 'STK_TITLE_TRANSACTIONS', securityRoles: [Role.STOCKS_TRANSACTIONS_READ] }, component: TransactionsComponent },
          { path: 'transact', canActivate: [AuthGuard], data: { title: 'STK_TITLE_TRANSACT', securityRoles: [Role.STOCKS_TRANSACTIONS_CREATE] }, component: TransactComponent },
          { path: 'populate', canActivate: [AuthGuard], data: { title: 'STK_TITLE_POPULATE', securityRoles: [Role.SYSTEM_STOCKS_CREATE], mode: 'POPULATE' }, component: PopulateComponent },
          { path: 'depopulate', canActivate: [AuthGuard], data: { title: 'STK_TITLE_DEPOPULATE', securityRoles: [Role.SYSTEM_STOCKS_DELETE], mode: 'DEPOPULATE' }, component: PopulateComponent },
          {
            path: 'undo', canActivate: [AuthGuard], data: { title: 'STK_TITLE_UNDOING' }, children: [
              { path: '', pathMatch: 'full', redirectTo: 'try' },                                                                                 //default route for parent
              { path: 'try', canActivate: [AuthGuard], data: { title: 'STK_TITLE_UNDO_REQUEST', securityRoles: [Role.STOCKS_UNDO_REQUESTS_CREATE] }, component: UndoTryComponent },
              { path: 'requests', canActivate: [AuthGuard], data: { title: 'STK_TITLE_UNDO_REQUESTS', securityRoles: [Role.STOCKS_UNDO_REQUESTS_READ] }, component: UndoRequestsComponent }
            ]
          },
        ]
      },
      {
        path: 'accounts', canActivate: [AuthGuard], data: { title: 'ACC_TITLE_ACCOUNTS', securityRoles: [Role.ACCOUNTS_READ] }, children: [
          { path: '', pathMatch: 'full', redirectTo: 'list' },
          // { path: 'me', canActivate: [AuthGuard], data: { title: 'ACC_TITLE_USER_PROFILE' }, component: UserProfileComponent },                                                                          //default route for parent
          { path: 'list', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: AccountsComponent },
          { path: 'view/:id', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: ViewAccountComponent },
          { path: 'add', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', securityRoles: [Role.ACCOUNTS_CREATE] }, component: ManageAccountComponent },
          { path: 'update/:id', canActivate: [AuthGuard], data: { title: '_ACTION_UPDATE', securityRoles: [Role.ACCOUNTS_UPDATE] }, component: ManageAccountComponent },
        ]
      },
      {
        path: 'security', canActivate: [AuthGuard], data: { title: 'SEC_TITLE_SECURITY' }, children: [
          { path: '', pathMatch: 'full', redirectTo: 'groups' },                                                                              //default route for parent
          {
            path: 'groups', canActivate: [AuthGuard], data: { title: 'SEC_TITLE_SECURITY_GROUPS', securityRoles: [Role.SECURITY_GROUPS_READ] }, children: [
              { path: '', pathMatch: 'full', redirectTo: 'list' },
              { path: 'list', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW', mode: 'LIST' }, component: SecurityGroupsComponent },
              { path: 'add', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'ADD', securityRoles: [Role.SECURITY_GROUPS_CREATE] }, component: SecurityGroupsComponent },
              { path: 'update/:id', canActivate: [AuthGuard], data: { title: '_ACTION_UPDATE', mode: 'UPDATE', securityRoles: [Role.SECURITY_GROUPS_UPDATE] }, component: SecurityGroupsComponent },
            ]
          },
          {
            path: 'users', canActivate: [AuthGuard], data: {
              title: 'SEC_LINK_USERS', securityRoles: [Role.USERS_READ]
            }, children: [
              { path: '', pathMatch: 'full', redirectTo: 'list' },                                                                              //default route for parent
              { path: 'list', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: AccountUsersComponent },
              { path: 'list/:accountId', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: AccountUsersComponent },
              { path: 'update/:id', canActivate: [AuthGuard], data: { title: '_ACTION_UPDATE', securityRoles: [Role.USERS_UPDATE] }, component: ManageUserComponent },
              { path: 'add', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', securityRoles: [Role.USERS_CREATE] }, component: ManageUserComponent },
              { path: 'groups', canActivate: [AuthGuard], data: { title: 'SEC_TITLE_USERS_GROUPS', securityRoles: [Role.USERS_SECURITY_GROUPS_READ] }, component: UsersGroupsComponent },
            ]
          },
        ]
      },
      {
        path: 'issuing', canActivate: [AuthGuard], data: { title: '_TITLE_ISSUING', securityRoles: [Role.INT_LICENSES_READ, Role.TRIPTIKS_READ] }, children: [
          { path: 'query', canActivate: [AuthGuard], data: { title: 'ISS_TITLE_ISSUED_DOCUMENTS_QUERY' }, component: IssuedDocumentsQueryComponent },
          { path: 'revoke/requests', canActivate: [AuthGuard], data: { title: 'ISS_TITLE_DOCUMENTS_REVOKE_REQUESTS', securityRoles: [Role.INT_LICENSES_REVOKE_REQUESTS_READ, Role.TRIPTIKS_REVOKE_REQUESTS_READ] }, component: DocumentsRevokeRequestsComponent },
          {
            path: 'int-licenses', canActivate: [AuthGuard], data: { title: 'LIC_TITLE_LICENSES', securityRoles: [Role.INT_LICENSES_READ] }, children: [
              { path: '', pathMatch: 'full', redirectTo: 'list' },
              { path: 'list', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: IntLicensesComponent },
              { path: 'view/:id', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: ViewIntLicenseComponent },
              { path: 'add', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'ADD', securityRoles: [Role.INT_LICENSES_CREATE] }, component: ManageIntLicenseComponent },
              { path: 'add/:id', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'ADD', securityRoles: [Role.INT_LICENSES_CREATE] }, component: ManageIntLicenseComponent },
              { path: 'clone/:key', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'CLONE', securityRoles: [Role.INT_LICENSES_CREATE] }, component: ManageIntLicenseComponent },
              { path: 'update/:id', canActivate: [AuthGuard], data: { title: '_ACTION_UPDATE', mode: 'UPDATE', securityRoles: [Role.INT_LICENSES_UPDATE] }, component: ManageIntLicenseComponent },
            ]
          },
          {
            path: 'trip-tiks', canActivate: [AuthGuard], data: { title: 'TRP_TITLE_TRIPTIKS', securityRoles: [Role.TRIPTIKS_READ] }, children: [
              { path: '', pathMatch: 'full', redirectTo: 'list' },
              { path: 'list', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: TripTiksComponent },
              { path: 'view/:id', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: ViewTripTikComponent },
              { path: 'add', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'ADD', securityRoles: [Role.TRIPTIKS_CREATE] }, component: ManageTripTikComponent },
              { path: 'add/:id', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'ADD', securityRoles: [Role.TRIPTIKS_CREATE] }, component: ManageTripTikComponent },
              { path: 'clone/:key', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'CLONE', securityRoles: [Role.TRIPTIKS_CREATE] }, component: ManageTripTikComponent },
              { path: 'update/:id', canActivate: [AuthGuard], data: { title: '_ACTION_UPDATE', mode: 'UPDATE', securityRoles: [Role.TRIPTIKS_UPDATE] }, component: ManageTripTikComponent },
            ]
          },
        ]
      },
      {
        path: 'trip-tiks-claims', canActivate: [AuthGuard], data: { title: 'TRP_CLM_TITLE_TRIPTIK_CLAIMS', securityRoles: [Role.TRIPTIKS_CLAIMS_READ] }, children: [
          { path: '', pathMatch: 'full', redirectTo: 'list' },
          { path: 'list', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: TripTiksClaimsComponent },
          { path: 'view/:id', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: ViewTripTikClaimComponent },
          { path: 'view/attachments/:id', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW' }, component: TripTikClaimAttachmentsComponent },
          { path: 'update-status/:id', canActivate: [AuthGuard], data: { title: '_ACTION_UPDATE_STATUS', securityRoles: [Role.TRIPTIKS_CLAIMS_UPDATE] }, component: UpdateStatusTripTikClaimComponent },
          { path: 'add', canActivate: [AuthGuard], data: { title: '_ACTION_ADD', mode: 'ADD', securityRoles: [Role.TRIPTIKS_CLAIMS_CREATE] }, component: ManageTripTikClaimComponent },
          { path: 'logs', canActivate: [AuthGuard], data: { title: '_ACTION_VIEW', securityRoles: [Role.TRIPTIKS_CLAIMS_LOGS_READ] }, component: TripTiksClaimsLogsComponent },
        ]
      },

      { path: 'me', canActivate: [AuthGuard], data: { title: 'ACC_TITLE_USER_PROFILE' }, component: UserProfileComponent },
    ]
  },
  // { path: 'uploads/:stockTypeId/:accountId/:objectId', canActivate: [AuthGuard], data: { title: 'ACC_TITLE_USER_PROFILE' }, component: DownloadComponent },
  //{ path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
