import { AlertService } from 'src/app/shared/services/alert.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { UserSecurityGroupsDto, SecurityGroup, UserDto, UsersSecurityGroupsVm } from '../../models/security-groups.model';
import { SecurityService } from '../../services/security.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import * as _ from 'lodash';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UpdateModal } from 'src/app/shared/models/modal';
import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/core/models/enums/role';
import { Language } from 'src/app/core/models/enums/language';


@Component({
    selector: 'mac-users-groups',
    templateUrl: './users-groups.component.html',
    styleUrls: ['./users-groups.component.scss'],
    providers: [SecurityService]
})
export class UsersGroupsComponent implements OnInit {
    usersGroupsForm: FormGroup;
    securityGroups: SecurityGroup[];
    cashedVm: UsersSecurityGroupsVm;
    displayedColumns: any[] = [];
    objectsCol: {};
    dataSource = new MatTableDataSource<UserSecurityGroupsDto>();
    selections: SelectionModel<string>[] = [];
    loading: boolean;
    langIndex: number = 0;

    get displayedColumnsTitles() { return this.displayedColumns.map(c => c.titles[0]/*[this.langIndex]*/); }
    get Role(){
        return Role;
    }

    constructor(private fb: FormBuilder,
        private securityService: SecurityService,
        private alert: AlertService,
        private loader: LoadingService,
        private confirmService: ConfirmService,
        public auth: AuthService,
        private translate: TranslateService) { }


    ngOnInit() {
        this.langIndex = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.langIndex = t.lang == 'ar' ? Language.Arabic : Language.English);
        this.dataSource.data = [];
        this.formInit();

        this.objectsCol = { id: 0, titles: [this.langIndex == 0 ? 'المستخدمين': 'Users'] };
        this.displayedColumns = [this.objectsCol];

        //fetch data from server
        this.loader.load(LoaderType.Nav);
        this.securityService.getUsersSecurityGroups().then(vm => {
            this.cashedVm = vm;
            this.dataSource = new MatTableDataSource<UserSecurityGroupsDto>(vm.usersGroups);
            this.securityGroups = vm.availableGroups;
            this.securityGroups.forEach(() => this.selections.push(new SelectionModel<string>(true, [])));
            this.displayedColumns.push(...this.securityGroups);
            this.addCheckboxes(vm.usersGroups);
        }).finally(() => this.loader.load(LoaderType.Nav, false));
    }

    isColSelected(groupId: string) {
        const i = this.getGroupIndex(groupId);
        const numSelected = this.selections[i].selected.length;
        const numRows = this.dataSource.data.length - 1; //excluding current user

        return numSelected == numRows;
    }

    isRowSelected(row: UserSecurityGroupsDto, groupId: string) {
        const i = this.getGroupIndex(groupId);
        return this.selections[i].isSelected(row.user.id + "," + groupId);
    }

    colToggle(groupId: string) {
        const i = this.getGroupIndex(groupId);
        const colSelected = this.isColSelected(groupId);

        this.dataSource.data.filter(d => d.user.id != this.auth.currentUser.userId)
            .forEach((row, _i) => {
                const value = row.user.id + "," + groupId;
                const matrixIndex = (this.displayedColumns.length - 1) * _i + i;
                if (!colSelected) {
                    if (!this.selections[i].isSelected(value))
                        this.cellToggle(row, groupId, matrixIndex);
                    this.selections[i].select(value);
                } else {
                    this.cellToggle(row, groupId, matrixIndex);
                    this.selections[i].deselect(value);
                }
            });
    }

    cellToggle(row: UserSecurityGroupsDto, groupId: string, matrixIndex: number) {
        const i = this.getGroupIndex(groupId);

        const value = row.user.id + "," + groupId;
        let control = (this.usersGroupsForm.controls.usersGroups as FormArray).controls[matrixIndex];

        if (!control.disabled) {
            !this.isRowSelected(row, groupId) ?
                control.setValue(value) :
                control.reset();

            this.selections[i].toggle(value);
        }
    }

    getGroupIndex(groupId: string) {
        return this.securityGroups.findIndex(x => x.id == groupId);
    }

    //todo: include confirmation pop-up
    clearSelections() {
        //confirm first
        this.selections.forEach(s => s.clear());
    }


    onSubmit() {
        let modal = this.confirmService.confirm(new UpdateModal({
            title: 'SEC_LINK_USERS', 
            type: 'SEC_TITLE_USERS_GROUPS', 
            action: '_ACTION_ASSIGN',
            confirmQuestion: 'SEC_USER_GROUPS_CONFIRM_UPDATE_CONFIRMATION_QUESTION',
            note: 'SEC_USER_GROUPS_CONFIRM_UPDATE_NOTE',
            dangerNote: ' ',
            submitText: '_ACTION_ASSIGN'
        }));
   
        modal.then(m => {
            if (m.indexOf('CONFIRMED') > -1)
                this.update();
        });
    }

    private update() {
        this.loader.load(LoaderType.Body);
        const dto = this.mapCheckboxes();
        this.securityService.updateUsersSecurityGroups(dto)
            .then(r => {
                if (r)
                    this.alert.success('_ACTION_ASSIGNING');
                else
                    this.alert.failure('_ACTION_ASSIGNING');
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }

    private mapCheckboxes() {
        const formArr = this.usersGroupsForm.controls.usersGroups as FormArray;
        const result = new UsersSecurityGroupsVm();
        result.availableGroups = _.cloneDeep(this.cashedVm.availableGroups);
        result.usersGroups = _.cloneDeep(this.cashedVm.usersGroups);

        let matrixIndex = 0;
        result.usersGroups.forEach(ug => {
            ug.user.names = [];
            ug.securityGroups = [];
            result.availableGroups.forEach(group => {
                group.titles = group.descriptions = [];
                ug.securityGroups.push(formArr.controls[matrixIndex++].value ? group.id : null);
            });
        });
        return result;
    }

    // private _loading(status: boolean = true) {
    //     this.loading = status;
    // }

    private formInit() {
        this.usersGroupsForm = this.fb.group({
            usersGroups: this.fb.array([])
        });
    }

    private addCheckboxes(usersGroups: UserSecurityGroupsDto[], empty: boolean = false) {
        this.clearSelections();
        let matrixIndex = 0;
        let formArray = (this.usersGroupsForm.controls.usersGroups as FormArray);
        formArray.clear();
        usersGroups.forEach((ug, i) => {
            ug.securityGroups.forEach((groupId, _i) => {
                const value = !empty && groupId ? ug.user.id + "," + groupId : null;

                const control = ug.user.id == this.auth.currentUser.userId 
                || !this.auth.currentUser.isInRole(Role.USERS_SECURITY_GROUPS_UPDATE) ?
                    new FormControl({ value: value, disabled: true })
                    : new FormControl(value);
                formArray.push(control);

                if (!empty && groupId)
                    this.cellToggle(ug, groupId, matrixIndex);

                matrixIndex++;
            });
        });
    }

}
