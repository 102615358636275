import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import { ConfigVm, ConfigDto } from '../models/config';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class StaticConfig {
    static config: ConfigDto[] = [];
    get() {
        //const lang = null;
        return this.api.get<ConfigVm>(EndPoint.CONFIG, null, null, false).toPromise()
            .then(result => StaticConfig.config = result.list);
    }

    constructor(private api: ApiHandler) { }

    static getConfigKeyValuePairs(configObj: ConfigObject, lang: Language = null) {
        return _.cloneDeep(this.config).find(x => x.id == configObj).keyValuePairs
            .map(pair => {
                pair.value = lang != undefined && lang != null && pair.localized ? pair.value[lang] : pair.value;
                return pair;
            });
    }

    static getConfigValue(configObj: ConfigObject, key: number, lang: Language = null) {
        const keyValuePair = _.cloneDeep(this.config).find(x => x.id == configObj).keyValuePairs.find(x => x.key == key);
        return lang != undefined && lang != null && keyValuePair.localized ? keyValuePair.value[lang] : keyValuePair.value;
    }
}