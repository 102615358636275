import { Component, OnInit } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { TripTiksClaimsService } from '../../services/trip-tiks-claims.service';
import { ActivatedRoute } from '@angular/router';
import { TripTikClaimDetailsDto } from '../../models/trip-tik-claim.model';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { TripTikClaimStatus } from 'src/app/core/models/enums/triptik-claim-status';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';

@Component({
  selector: 'mac-view-trip-tik-claim',
  templateUrl: './view-trip-tik-claim.component.html',
  styleUrls: ['./view-trip-tik-claim.component.scss'],
  providers: [TripTiksClaimsService, FileLoaderService]
})
export class ViewTripTikClaimComponent implements OnInit {

  dto = new TripTikClaimDetailsDto();

  get TripTikClaimStatus() { return TripTikClaimStatus; };
  get Role() { return Role; };

  constructor(private loader: LoadingService,
    private tripTiksClaimsService: TripTiksClaimsService,
    private fileLoader: FileLoaderService,
    public auth: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loader.load(LoaderType.Nav);
    const tripTikClaimId = this.route.snapshot.paramMap.get('id');

    this.tripTiksClaimsService.get(tripTikClaimId)
      .then(result => {
        this.dto = result.item;
        //console.log(this.dto);

        this.dto.logs = this.dto.logs.map(log => {
          log.status.titles = log.status.parentId ? [`${log.status.parentTitles} - ${log.status.titles}`] : log.status.titles;
          return log;
        });

        // console.log(this.dto);

        this.dto.logs.forEach((log, i) => {
          log.attachments.forEach(async (att) => {
            att.url = await this.fileLoader.loadAsync(<string>att.url)
          })
        });


      })
      .finally(() => this.loader.load(LoaderType.Nav, false))

    // this.attachments.forEach(async att => {
    //   const file = await this.fileLoader.loadAsync(att.src);
    //   att.src = file ? file : this.defaultImage;
    // });

    //this.dto.attachments[0] = await this.fileLoader.loadAsync(this.dto.attachments[0]);
  }

}
