import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Language } from 'src/app/core/models/enums/language';

const NON_ENGLISH_REGEX = /^[\u0621-\u064A0-9-_., ]*$/;
const NON_ARABIC_REGEX = /^[a-zA-Z0-9-_., ]*$/;
const ENGLISH_ONLY_REGEX = /^[a-zA-Z]*$/;
const ENGLISH_AND_SPACES_REGEX = /^[a-zA-Z. ]*$/;
const NUMBERS_ONLY_REGEX = /^\d*$/;
const USERNAME_REGEX = /^[A-Za-z0-9]+(?:[_][A-Za-z0-9]+)*$/; //letters, numbers, and underscores (inside, not in a row), no spaces
//const PASSPORT_ID_OR_IDENTITY_ID_REGEX = /^[A-Za-z0-9]*$/;  //letters, numbers


class RangeValidation {
    constructor(public min: number, public max: number) { }
}


//todo: fetch from db config

const vehicleWeightInKG = new RangeValidation(1, 100000);//"_ERR_OUT_OF_RANGE_VEHICLE_WEIGHT_IN_KG");
const vehicleHorsePower = new RangeValidation(1, 10000);//, //"_ERR_OUT_OF_RANGE_VEHICLE_HORSE_POWER");
const vehicleCylindersCount = new RangeValidation(1, 100);//, //"_ERR_OUT_OF_RANGE_VEHICLE_CYLINDERS_COUNT");
const vehicleManufactureYear = new RangeValidation(1900, 2100);//, //"_ERR_OUT_OF_RANGE_VEHICLE_MANUFACTURE_YEAR");
const vehicleSeatsCount = new RangeValidation(1, 100);//, //"_ERR_OUT_OF_RANGE_VEHICLE_SEATS_COUNT");
const vehicleSpareTiresCount = new RangeValidation(1, 2);//, //"_ERR_OUT_OF_RANGE_VEHICLE_SPARE_TIRES_COUNT");
const vehicleValue = new RangeValidation(1, 10000000);//,// "_ERR_OUT_OF_RANGE_VEHICLE_VALUE");

const ADDRESS_REGEX = /^$|^[\u0621-\u064A0-9 ]{3,100}$/
const PASSPORT_ID_OR_IDENTITY_ID_REGEX = /^[a-zA-Z0-9]+$/;  //letters, numbers
const EMAIL_REGEX = /^$|^.*@.*\..*$/;
const PHONE_NO_REGEX = /^$|^[0-9]{7,15}$/;
const TRIPTIK_NAME_REGEX = /^$|^[\u0621-\u064Aa-zA-Z0-9. ]+$/;

export function vehicleWeightInKGRangeValidator(control: AbstractControl) {
    return control.value >= vehicleWeightInKG.min && control.value <= vehicleWeightInKG.max ? null : { invalidVehicleWeightInKGRange: true };
}

export function vehicleHorsePowerRangeValidator(control: AbstractControl) {
    //enable null   
    return !control.value || (control.value >= vehicleHorsePower.min && control.value <= vehicleHorsePower.max) ? null : { invalidVehicleHorsePowerRange: true };
}

export function vehicleCylindersCountRangeValidator(control: AbstractControl) {
    return control.value >= vehicleCylindersCount.min && control.value <= vehicleCylindersCount.max ? null : { invalidVehicleCylindersCountRange: true };
}

export function vehicleManufactureYearRangeValidator(control: AbstractControl) {
    return control.value >= vehicleManufactureYear.min && control.value <= vehicleManufactureYear.max ? null : { invalidVehicleManufactureYearRange: true };
}

export function vehicleSeatsCountRangeValidator(control: AbstractControl) {
    return control.value >= vehicleSeatsCount.min && control.value <= vehicleSeatsCount.max ? null : { invalidVehicleSeatsCountRange: true };
}

export function vehicleSpareTiresCountRangeValidator(control: AbstractControl) {
    return control.value >= vehicleSpareTiresCount.min && control.value <= vehicleSpareTiresCount.max ? null : { invalidVehicleSpareTiresCountRange: true };
}

export function vehicleValueRangeValidator(control: AbstractControl) {
    return control.value >= vehicleValue.min && control.value <= vehicleValue.max ? null : { invalidVehicleValueRange: true };
}


export function nonEnglishCharactersValidator(control: AbstractControl) {
    return NON_ENGLISH_REGEX.test(control.value) ? null : { nonEnglishOnly: true };
}

export function nonArabicCharactersValidator(control: AbstractControl) {
    return NON_ARABIC_REGEX.test(control.value) ? null : { nonArabicOnly: true };
}

export function englishOnlyValidator(control: AbstractControl) {
    return ENGLISH_ONLY_REGEX.test(control.value) ? null : { englishOnly: true };
}

export function englishAndSpacesValidator(control: AbstractControl) {
    return ENGLISH_AND_SPACES_REGEX.test(control.value) ? null : { englishAndSpacesOnly: true };
}

export function numbersOnlyValidator(control: AbstractControl) {
    return NUMBERS_ONLY_REGEX.test(control.value) ? null : { invalidNumbersOnly: true };
}

export function phoneNumberValidator(control: AbstractControl) {
    return PHONE_NO_REGEX.test(control.value) ? null : { invalidPhoneNumber: true };
}

export function emailValidator(control: AbstractControl) {
    return EMAIL_REGEX.test(control.value) ? null : { invalidEmail: true };
}

export function usernameValidator(control: AbstractControl) {
    return USERNAME_REGEX.test(control.value) ? null : { invalidUsername: true };
}

export function passportIdOrIdentityIdValidator(control: AbstractControl) {
    return PASSPORT_ID_OR_IDENTITY_ID_REGEX.test(control.value) ? null : { invalidPassportIdOrIdentityId: true };
}

export function _passportIdOrIdentityIdValidator(ctrl1: any, ctrl2: any, control: AbstractControl) {
    // console.log(control, ctrl1, ctrl2);
    return control.value != ctrl1.value && control.value != ctrl2.value ? null : { _invalidPassportIdOrIdentityId: true };
}

export function addressValidator(control: AbstractControl) {
    return ADDRESS_REGEX.test(control.value) ? null : { invalidAddress: true };
}

export function triptikNameValidator(control: AbstractControl) {
    return TRIPTIK_NAME_REGEX.test(control.value) ? null : { invalidTriptikName: true };
}

export function standardLocalizedControlValidators(lang: Language): ValidatorFn[] {
    return [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        lang == Language.Arabic ? nonEnglishCharactersValidator : nonArabicCharactersValidator];
}

export function comparePasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password').value;
    let confirmPassword = group.get('passwordConfirm').value;

    return pass === confirmPassword ? null : { passwordMismatch: true }
}
