import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
//import { CountryDto, VehicleMakesVm, VehicleMakeDto } from 'src/app/core/nswag.api';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { LookupVm, LookupDto } from '../models/lookup';
import { CountryDto } from '../models/country';

@Injectable()
export class VehicleMakesService {
    constructor(private api: ApiHandler) { }

    get(lang: Language = null) {
        return this.api.get<LookupVm>(EndPoint.VEHICLE_MAKES, null, { lang }).toPromise();
    }

    create(dto: LookupDto) {
        return this.api.create<LookupDto, number>(EndPoint.VEHICLE_MAKES, dto).toPromise();
    }

    update(dto: CountryDto) {
        return this.api.update(EndPoint.VEHICLE_MAKES, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.VEHICLE_MAKES, id).toPromise();
    }
}