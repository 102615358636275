import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, filter } from 'rxjs/operators';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { UsersService } from './users.service';
import { UserDto, UsersVm } from '../models/user';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { AccountMetaDto } from '../../accounts/models/account';


export class UsersDataSource implements DataSource<UserDto> {
   // add variables to hold the data and number of total records retrieved asynchronously
   // BehaviourSubject type is used for this purpose
   public UsersSubject = new BehaviorSubject<UserDto[]>([]);
   public ParentsSubject = new BehaviorSubject<AccountMetaDto[]>([]);

   // to show the total number of records
   public countSubject = new BehaviorSubject<number>(0);
   public counter$ = this.countSubject.asObservable();

   constructor(private usersService: UsersService, private loader: LoadingService) {

   }

   loadUsers(pageIndex: number, pageSize: number, sort: string, direction: string, filter: string = '', accountId: number = 0) {
      this.usersService.getUsers(pageIndex, pageSize, sort, direction, filter, accountId)
         .pipe(
            catchError(() => of([])),
            finalize(() => { })
         )
         .subscribe((result: UsersVm) => {
            result.users.forEach(user => {
               user.parentAccounts = populateAccountsSelect([user.parentAccounts])[0];
            });
            this.UsersSubject.next(result.users);
            this.ParentsSubject.next(result.parentAccounts);
            this.countSubject.next(result.recordsCount);
            this.loader.load(LoaderType.Nav, false);
            this.loader.load(LoaderType.Body, false);
         });
   }

   connect(): Observable<UserDto[]> {
      return this.UsersSubject.asObservable();
   }

   disconnect(collectionViewer: CollectionViewer): void {
      this.UsersSubject.complete();
      this.ParentsSubject.complete();
      this.countSubject.complete();
   }
}
