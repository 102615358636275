import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class TranslationResolver {

    constructor(private translate: TranslateService){
    }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any>{
        return this.translate.get("last.dummy").toPromise();  
    }
}