import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StockTypeAvailableStocksDto } from '../../models/available-stocks';
import { StocksService } from '../../services/stocks.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { ActivatedRoute, Router } from '@angular/router';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import * as _ from 'lodash';

@Component({
    selector: 'mac-stocks-available',
    templateUrl: './stocks-available.component.html',
    styleUrls: ['./stocks-available.component.scss'],
    providers: [StocksService, AccountsService]
})
export class StocksAvailableComponent implements OnInit {
    displayedColumns = ['from', 'to', 'count'];
    availableStocks: StockTypeAvailableStocksDto[];
    systemView: boolean;
    accounts: LookupDto[];
    accountId: number;

    @ViewChild('tabGroup') tabGroup;

    get Math() {
        return Math;
    }

    constructor(private stocksService: StocksService,
        private accountsService: AccountsService,
        private loader: LoadingService,
        private route: ActivatedRoute,
        public auth: AuthService,
        public translate: TranslateService,
        private router: Router) { }

    async ngOnInit() {
        this.loader.load(LoaderType.Nav);
        try {
            this.accounts = await (await this.accountsService.getChildrenAccountsLookup(!this.auth.currentUser.isSystemAccount ? this.auth.currentUser.accountId : 0)).list;
        } catch { }
        //this.accounts = this.auth.currentUser.isSystemAccount ? await (await this.accountsService.getChildrenAccountsLookup(0, false)).list : [];
        this.accountId = Number(this.route.snapshot.paramMap.get('accountId'));
        this.accountId = Number(this.accountId != 0 ? this.accountId : this.auth.currentUser.accountId);
        this.systemView = this.route.snapshot.data.mode == 'SYSTEM';

        //console.log(Number(this.accountId));

        this.displayedColumns = ['from', 'to', 'count'];

        if (!this.systemView) {

            this.stocksService.getAvailableStocks(0, this.accountId)
                .then(d => this.availableStocks = d.stockTypesAvailableStocks)
                .finally(() => this.loader.load(LoaderType.Nav, false));

        } else {
            this.displayedColumns.push('available', 'unavailable');
            this.stocksService.getSystemAvailableStocks(0, false, false)
                .then(d => this.availableStocks = d.stockTypesAvailableStocks)
                .finally(() => this.loader.load(LoaderType.Nav, false));
        }
    }

    filterWithAccount(id) {
        this.accountId = id;
        this.loader.load(LoaderType.Body);
        this.stocksService.getAvailableStocks(0, this.accountId)
            .then(d => {
                this.availableStocks = d.stockTypesAvailableStocks;
                this.router.navigate([`/${this.translate.currentLang}/stocks/available/${this.accountId}`], { replaceUrl: true });
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }


    sumAvailableStocks(availableStock: number[][], all = true) {
        let total = 0;
        availableStock.forEach(item => {
            if (all || (!all && item[0] > 0))
                total += item.length > 1 ? Math.abs(item[1]) - Math.abs(item[0]) + 1 : 1;
        });
        return total;
    }

    //serial status == 1 || -1
    sumAvailableStocksFilter(availableStock: number[][], serialStatus: number) {
        let total = 0;
        availableStock.forEach(item => {
            if ((serialStatus == -1 && item[0] < 0) || (serialStatus == 1 && item[0] > 0))
                total += item.length > 1 ? Math.abs(item[1]) - Math.abs(item[0]) + 1 : 1;
        });
        return total;
    }

    onSerialStatusChange(status: number) {
        //console.log($event);

        // if(status == 1)
        //     this.displayedColumns = this.displayedColumns

        this.displayedColumns = ['from', 'to', 'count'];

        switch (status) {
            case 1:
                this.displayedColumns.push('available');
                break;

            case -1:
                this.displayedColumns.push('unavailable');
                break;

            default:
                this.displayedColumns.push('available', 'unavailable');
                break;
        }
        this.serialStatus = status;

    }

    get Number() { return Number; };
    serialStatus: number;

    checkBoxChange($event, obj: StockTypeAvailableStocksDto, available: boolean) {
        console.log($event, obj);
        if ($event.checked) {

            const test = [...obj.availableStock.filter(x => x[0] > -1)];
            console.log(test);
            obj.availableStock = test;



        }
    }

    onExportToExcel(all: boolean = true) {
        let data = _.cloneDeep(all ? this.availableStocks : [this.availableStocks[this.tabGroup.selectedIndex]]);

        //data.forEach(d => d.availableStock)

        if (this.serialStatus)
            data = data.map(x => {
                x.availableStock = x.availableStock.filter(y => (this.serialStatus == 1 && y[0] > 0) || (this.serialStatus == -1 && y[0] < 0))
                return x;
            })

        console.log(data);
        this.exportToExcel(data);
    }

    private exportToExcel(data: StockTypeAvailableStocksDto[]) {
        const rows: string[][] = [];

        let wscols = [
            { width: 24 },
            { width: 14 },
            { width: 14 },
            { width: 14 }
        ];

        let autofilter = { ref: 'A1:D1' };

        const headers = [
            this.translate.instant('LKP_FIELD_STOCK_TYPE'),
            `${this.translate.instant('_LABEL_FROM')} ${this.translate.instant('_LABEL_SERIAL')}`,
            `${this.translate.instant('_LABEL_TO')} ${this.translate.instant('_LABEL_SERIAL')}`,
            this.translate.instant('STK_LABEL_QUANTITY')
        ];

        if (this.systemView) {
            wscols.push({ width: 14 });
            autofilter = { ref: 'A1:E1' };
            headers.push(`${this.translate.instant('_FIELD_AVAILABLE')} ${this.translate.currentLang == 'ar' ? '؟' : '?'}`)
        }

        rows.push(headers);
        // let _total = 0;
        // let _availableTotal = 0;

        for (let row of data) {
            for (let stockTypeData of row.availableStock) {
                const _row = [
                    row.stockType.titles[0],
                    Math.abs(stockTypeData[0]).toString(),
                    Math.abs(stockTypeData.length > 1 ? stockTypeData[1] : stockTypeData[0]).toString(),
                    (stockTypeData.length > 1 ? stockTypeData[1] - stockTypeData[0] + 1 : 1).toString()
                ];

                if (this.systemView)
                    _row.push(this.translate.instant(stockTypeData[0] > 0 ? '_ACTION_YES' : '_ACTION_NO'))

                rows.push(_row);
            }
        }
        // rows.push([
        //     this.translate.instant('_LABEL_TOTAL'),
        //     '',
        //     '',
        //     _total
        // ])

        exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Available-Stocks');
    }
}
