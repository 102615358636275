import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { Language } from './models/enums/language';
import { EndPoint } from './models/enums/end-point';
import { ValidationDto } from './models/validation';
import { AlertService } from '../shared/services/alert.service';
// import { AlertService2 } from '../shared/services/alert.service2';
import { isArray } from 'util';
import { Login } from './models/login';
import { environment } from 'src/environments/environment';
// import { AppConfig } from './services/app-config.service';
//import * as AppConfig from '../../assets/config.json';
// import { AlertService2 } from '../shared/services/alert.service2';

let httpOptions = <HttpOptions>{
    headers: new HttpHeaders({
        "Content-Type": "application/json",
        // "Accept": "application/json",
        "Accept": "*/*",
        "Authorization": "",
        "Accept-Languages": "ar"
    }),
    body: {},
    responseType: "json",
    observe: "body",
    withCredentials: false,
    reportProgress: false,
    params: null
};
class HttpOptions {
    headers?: HttpHeaders | { [header: string]: string | string[]; };
    observe?: "body";
    params?: HttpParams | { [param: string]: string | string[]; };
    reportProgress?: boolean;
    responseType?: "json";
    withCredentials?: boolean;
}


@Injectable()
export class ApiHandler {
    //API_BASE_URL: string;
    _lang: Language; //todo: change with application changing language;
    _apiBaseUrl: string;
    //options_;
    //auth: AuthService;
    //alert: AlertService;

    constructor(private http: HttpClient, private translate: TranslateService, private alert: AlertService) {
        ////console.log(this.http, translate, _snackBar);

        this._apiBaseUrl = environment.apiBaseUrl; //AppConfig.apiBaseUrl;
        // console.log(this.translate);
        // console.warn(this.translate.currentLang);
        // console.warn(this.translate.store.currentLang);

        // const _currentLang = this.translate.store.currentLang;
        // console.log(_currentLang);

        this.translate.store.onLangChange.subscribe(t => {
            //console.log(t.lang);
            //this._lang = translate.currentLang == 'ar' ? Language.Arabic : Language.English;
            this._lang = t.lang == 'ar' ? Language.Arabic : Language.English;
            (<HttpHeaders>httpOptions.headers).set("Accept-Languages", t.lang);


            //   .append("","");

            //todo: refactor
            httpOptions.headers = new HttpHeaders({
                "Content-Type": "application/json",
                // "Accept": "application/json",
                "Accept": "*/*",
                "Authorization": "",
                "Accept-Languages": t.lang
            });

            //console.log(httpOptions.headers);

        });

        ////console.log(this.alert);
        //this.API_BASE_URL = environment.apiBaseUrl;

        //this._lang = translate.currentLang == 'ar' ? Language.Arabic : Language.English;


        // (<HttpHeaders>httpOptions.headers).set("Accept-Languages", _currentLang);
        // console.log((<HttpHeaders>httpOptions.headers).get("Accept-Languages"));

        // this.translate.onLangChange.subscribe(t => {
        //     this._lang = t.lang == 'ar' ? Language.Arabic : Language.English;
        //     (<HttpHeaders>httpOptions.headers).set("Accept-Languages", _currentLang);
        //     console.log((<HttpHeaders>httpOptions.headers).get("Accept-Languages"));
        // });
    }

    getAuthHeader() {
        return null; //todo: inject token
    }
    // handleError<T>(err){
    //     //console.log(err);   //todo: handle errors
    // }


    public login(username: string, password: string) {
        return this.http.post<Login>(`${this._apiBaseUrl}/${EndPoint.AUTH}`, { username, password });
    }

    private handleError(err: HttpErrorResponse) {
        //console.log(err);
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }

        switch (err.status) {
            case 401:
                AuthService.logout();
                break;

            case 400:
                let messages = [];
                // for (let key in err.error)
                //     for (let error of err.error[key])
                //         messages.push(`${this.translate.instant(key)}: ${this.translate.instant(error)}`);

                for (let key in err.error)
                    for (let error of err.error[key])
                        messages.push(`${key}: ${error}`);
                this.alert._error(messages);
                break;

            case 404:
                window.location.href = `/${this.translate.currentLang}/not-found`;

            default:    //500
                console.error(err);
        }



        return throwError(err.error);



        // if (err.status == 401) {

        // } else if (err.status == 400) {

        // }
        //this.alert.error(errorMessage);
        //AlertService2.error('testse');
        // //console.log(this.alert);
        // if (!this.alert)
        //     this.alert = new AlertService(this._snackBar, this.translate);
        // this.alert = this.injector.get(AlertService);
        ////console.log(this.alert);
        ////console.log(this.http, this.translate, this._snackBar);
        //this._snackBar.open('test');
        //this.alert.error('test');
        ////console.log(isArray(err.error));

        //console.error(err);
        ////console.log(err);

        // for (let e in err.error) {
        //     //console.log(err.error[e]);
        //     //message += '<br>' + err.error[e][0];
        //     //messages.push(err.error[e][0]);
        // }

        //if (!(err.error instanceof ProgressEvent)) {

        ////console.log(messages);

        //}


    }

    public validate(dto: ValidationDto): Observable<boolean> {
        const _url = `${this._apiBaseUrl}/${EndPoint.ASYNC_VALIDATION}`;

        return this.http.post(_url, JSON.stringify(dto), httpOptions)
            .pipe(
                map(Boolean),
                catchError(this.handleError.bind(this))
            );
    }

    public get<T>(url: EndPoint, params = null, queryParams: {} = null, localized: boolean = true): Observable<T> {
        let _url = `${this._apiBaseUrl}/${url}`;
        if (params != undefined) {
            if (Array.isArray(params))
                params.forEach(p => _url += `/${p}`);
            else
                _url += `/${params}`;
        }

        //this.LANG = this.LANG ? Language.Arabic : this.LANG;
        if (queryParams) {
            for (const [index, [key, value]] of Object.entries(Object.entries(queryParams)))
                _url += `${Number(index) == 0 ? '?' : '&'}${key}=${value != undefined && value != null ? value : ''}`;

            if (_url.indexOf('lang') == -1 && localized)
                _url += `&lang=${this._lang}`;
        } else
            _url += localized ? `?lang=${this._lang}` : '';

        //console.warn(_url);
        //console.log(httpOptions);

        if (url == EndPoint.ISSUING_VERIFY) {
            // (<HttpHeaders>httpOptions.headers).delete('authorization');
            // (<HttpHeaders>httpOptions.headers).delete('content-Type');
            // const proxyurl = "https://cors-anywhere.herokuapp.com/";
            // _url =  proxyurl + _url;
            httpOptions.headers = new HttpHeaders({
                "Accept": "*/*",
                "Accept-Languages": "ar",

            });
        }
        //console.log(httpOptions);

        return this.http.get<T>(_url, httpOptions)
            .pipe(
                tap(data => data),
                catchError(this.handleError.bind(this))
            );
    }

    public create<TObject, TResult>(url: EndPoint, object: TObject): Observable<TResult> {
        const _url = `${this._apiBaseUrl}/${url}`;

        return this.http.post<TResult>(_url, JSON.stringify(object), httpOptions)
            .pipe(
                map(data => data),
                catchError(this.handleError.bind(this))
            );
    }

    public update(url: EndPoint, object: any,/*, withId: boolean = true,*/ args: (number | string | boolean)[] = []): Observable<boolean> {
        let _url = `${this._apiBaseUrl}/${url}`;
        //_url += withId ? `/${object.id}` : ``;

        if (args.length > 0)
            args.forEach(a => _url += `/${a}`);

        return this.http.put(_url, JSON.stringify(object), httpOptions)
            .pipe(
                map(Boolean),
                catchError(this.handleError.bind(this))
            );
    }

    public delete(url: EndPoint, id: number | string): Observable<boolean> {
        const _url = `${this._apiBaseUrl}/${url}/${id}`;

        return this.http.delete(_url, httpOptions)
            .pipe(
                map(Boolean),
                catchError(this.handleError.bind(this))
            )
    }
}
