import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StaticConfig } from 'src/app/features/lookups/services/config';

@Injectable()
export class ConfigResolver {
    constructor(private config: StaticConfig){
    }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any>{
        return this.config.get();  
    }
}