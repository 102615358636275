import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { StockTypesVm, StockTypeDto } from '../models/stock-type';

@Injectable()
export class StockTypesService {
    constructor(private api: ApiHandler) { }

    get(lang: Language = null) {
        //console.log(lang);
        return this.api.get<StockTypesVm>(EndPoint.STOCK_TYPES, null, { lang }).toPromise();
    }

    getURO(lang: Language = null) {
        return this.api.get<StockTypesVm>(EndPoint.STOCK_TYPES_URO, null, { lang }).toPromise();
    }

    getAllowed(accountId: number = 0, transactAllowedOnly: boolean = false) {
        return this.api.get<StockTypesVm>(EndPoint.STOCK_TYPES_ALLOWED, [accountId ? accountId : 0, transactAllowedOnly]).toPromise();
    }

    create(dto: StockTypeDto) {
        return this.api.create<StockTypeDto, number>(EndPoint.STOCK_TYPES, dto).toPromise();
    }

    update(dto: StockTypeDto) {
        return this.api.update(EndPoint.STOCK_TYPES, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.STOCK_TYPES, id).toPromise();
    }
}