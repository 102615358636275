import { Component, Injectable, Directive, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmState, ConfirmOptions, IModalOptions } from '../models/modal';

@Injectable()
export class ConfirmService {

  constructor(private modalService: NgbModal, private state: ConfirmState) {}

  /**
   * Opens a confirmation modal
   * @param options the options for the modal (title and message)
   * @returns {Promise<any>} a promise that is fulfilled when the user chooses to confirm, and rejected when
   * the user chooses not to confirm, or closes the modal
   */
  confirm(options: IModalOptions): Promise<any> {
    this.state.options = options;
    //delete is the default modal
    //this.state.modal = this.modalService.open(options.confirmType == 'update' ? this.state.updateTemplate : this.state.deleteTemplate);
    this.state.modal = this.modalService.open(this.state.template);
    return this.state.modal.result;
  }
}
