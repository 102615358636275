import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { CountriesVm, CountryDto } from '../models/country';

@Injectable()
export class CountriesService {
    constructor(private api: ApiHandler) { }

    get(lang: Language = null) {
        //console.log(lang);
        return this.api.get<CountriesVm>(EndPoint.COUNTRIES, null, { lang }).toPromise();
    }

    create(dto: CountryDto) {
        return this.api.create<CountryDto, number>(EndPoint.COUNTRIES, dto).toPromise();
    }

    update(dto: CountryDto) {
        return this.api.update(EndPoint.COUNTRIES, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.COUNTRIES, id).toPromise();
    }
}