import { LookupDto } from 'src/app/features/lookups/models/lookup';
import * as _ from 'lodash';

export function populateAccountsSelect(list: LookupDto[]) {
  //return list;
  //console.log(list);

  return list.map(dto => {
    let _titles = [];
    dto.titles.forEach((t, i) => {
      _titles.push(`<span class='${(i != dto.titles.length - 1) ? 'grey' : ''}'">${t}</span>`);
    });
    dto.titles = [_titles.join('&nbsp;//&nbsp;')];
    return dto;
  });
}
