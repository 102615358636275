import { ConfirmService } from './../../../../shared/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ResolveUndoCmd } from './../../models/resolve-undo.cmd';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { merge, fromEvent } from 'rxjs';
import { StocksService } from '../../services/stocks.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { UndoRequestsDataSource } from '../../helpers/undo-requests-datasource';
import { undoRequestStatusLocale, UndoRequestStatus, UndoRequestDto } from '../../models/undo-requests';
import { AlertService } from 'src/app/shared/services/alert.service';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { UpdateModal } from 'src/app/shared/models/modal';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { MatSelect } from '@angular/material/select';
import { Language } from 'src/app/core/models/enums/language';

@Component({
    selector: 'mac-undo-requests',
    templateUrl: './undo-requests.component.html',
    styleUrls: ['./undo-requests.component.scss'],
    providers: [StocksService, StockTypesService]
})
export class UndoRequestsComponent implements OnInit {
    moment = moment;
    undoRequestStatusLocale = undoRequestStatusLocale;
    displayedColumns: string[] = ['created', 'fromAccountTitles', 'toAccountTitles', 'username', 'range', 'undoRequestStatus', 'stockType', 'reason', 'tools'];
    dataSource: UndoRequestsDataSource;
    stockTypes: StockTypeDto[] = [];
    lang: Language;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('filter') filter: ElementRef;
    @ViewChild(MatSelect, { static: true }) stockType: MatSelect;

    get UndoRequestStatus() {
        return UndoRequestStatus;
    }

    constructor(private loader: LoadingService,
        private stocksService: StocksService,
        private stockTypesService: StockTypesService,
        private alert: AlertService,
        private translate: TranslateService,
        private confirmService: ConfirmService,
        public auth: AuthService) { }

    ngOnInit() {
        this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
        this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

        if (!this.auth.currentUser.isInRole(Role.STOCKS_UNDO_REQUESTS_UPDATE))
            this.displayedColumns.splice(this.displayedColumns.length - 1, 1);

        //bug: will take a second to be loaded
        this.stockTypesService.get(this.lang).then(result => this.stockTypes = result.list);

        this.loader.load(LoaderType.Nav);
        this.sort.active = 'created';
        this.sort.direction = 'desc';
        this.paginator.pageSize = 10;
        this.dataSource = new UndoRequestsDataSource(this.stocksService, this.loader);
        this.dataSource.loadUndoRequests(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
    }

    ngAfterViewInit() {
        this.dataSource.counter$
            .pipe(
                tap((count) => {
                    this.paginator.length = count;
                })
            ).subscribe();

        merge(this.paginator.page, this.sort.sortChange)
            .pipe(
                tap(() => this.dataSource.loadUndoRequests(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.stockType.value, this.filter.nativeElement.value ? this.filter.nativeElement.value.trim() : null))
            ).subscribe(() => this.loader.load(LoaderType.Body));

        merge(fromEvent(this.filter.nativeElement, 'change'), this.stockType.valueChange)
            .pipe(
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.dataSource.loadUndoRequests(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, this.stockType.value, this.filter.nativeElement.value.trim())
                })
            ).subscribe(() => this.loader.load(LoaderType.Body));
    }

    getUndoRequestStatusClass(status: UndoRequestStatus) {
        switch (status) {
            case UndoRequestStatus.Rejected:
                return `text-danger`;

            case UndoRequestStatus.Approved:
                return `text-success`;

            default:
                return '';
        }
    }

    tryResolveRequest(row: UndoRequestDto, approve: boolean = false) {
        let modal = this.confirmService.confirm(new UpdateModal({
            type: 'STK_TITLE_UNDO_REQUEST',
            confirmQuestion: approve ? 'STK_UNDO_REQUEST_CONFIRM_UPDATE_STATUS_CONFIRMATION_QUESTION_APPROVE' : 'STK_UNDO_REQUEST_CONFIRM_UPDATE_STATUS_CONFIRMATION_QUESTION_REJECT',
            title: row.fromAccountTitles[0],
            action: approve ? '_ACTION_APPROVE' : '_ACTION_REJECT',
            submitText: approve ? '_ACTION_APPROVE' : '_ACTION_REJECT',
            submitClass: approve ? 'btn-warning' : 'btn-danger',
            note: approve ? 'STK_UNDO_REQUEST_CONFIRM_UPDATE_STATUS_APPROVE_NOTE' : 'STK_UNDO_REQUEST_CONFIRM_UPDATE_STATUS_REJECT_NOTE'
        }));
        modal.then(m => {
            if (m.indexOf('CONFIRMED') > -1)
                this.resolveRequest(row.transactionId, row.stockType.id, approve);
        });
    }

    onStockTypeChange(stockTypeId) {

    }

    onExportToExcel(all: boolean = true) {
        this.loader.load(LoaderType.Body);
        if (all)
            this.stocksService.getUndoRequests(0, this.paginator.length, this.sort.active, this.sort.direction)
                .toPromise()
                .then(data => {
                    this.exportToExcel(data.undoRequests);
                }).finally(() => this.loader.load(LoaderType.Body, false));
        else
            this.dataSource.connect().subscribe(data => {
                this.exportToExcel(data);
                this.loader.load(LoaderType.Body, false);
            }).unsubscribe();
    }

    private resolveRequest(transactionId: number, stockTypeId: number, approve: boolean) {
        this.loader.load(LoaderType.Body);
        const cmd: ResolveUndoCmd = {
            approve: approve,
            stockTypeId: stockTypeId,
            transactionId: transactionId
        }
        this.stocksService.resolveUndo(cmd)
            .then(result => {
                if (result) {
                    this.alert.success(approve ? '_ACTION_APPROVING' : '_ACTION_REJECTING');
                    const i = this.dataSource.undoRequestsSubject.value.findIndex(i => i.transactionId == cmd.transactionId);
                    this.dataSource.undoRequestsSubject.value[i].undoRequestStatus = approve ? UndoRequestStatus.Approved : UndoRequestStatus.Rejected;
                } else this.alert.failure('_ACTION_APPROVING');
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }

    private exportToExcel(data: UndoRequestDto[]) {
        const wscols = [
            { width: 14 },
            { width: 24 },
            { width: 24 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 14 },
            { width: 16 },
            { width: 16 }
        ];
        const autofilter = { ref: 'A1:I1' };
        const rows: string[][] = [];

        const headers = [
            this.translate.instant('_LABEL_DATE'),
            `${this.translate.instant('_LABEL_FROM')} ${this.translate.instant('ACC_LABEL_ACCOUNT')}`,
            `${this.translate.instant('_LABEL_TO')} ${this.translate.instant('ACC_LABEL_ACCOUNT')}`,
            this.translate.instant('SEC_FIELD_USERNAME'),
            `${this.translate.instant('_LABEL_FROM')} ${this.translate.instant('_LABEL_SERIAL')}`,
            `${this.translate.instant('_LABEL_TO')} ${this.translate.instant('_LABEL_SERIAL')}`,
            this.translate.instant('STK_LABEL_QUANTITY'),
            this.translate.instant('STK_LABEL_UNDO_REQUEST_STATUS'),
            this.translate.instant('LKP_FIELD_STOCK_TYPE'),
            this.translate.instant('_FIELD_REASON')
        ];

        rows.push(headers);
        for (let row of data) {
            rows.push([
                moment(row.created).format('DD/MM/YYYY'),
                row.fromAccountTitles[0],
                row.toAccountTitles ? row.toAccountTitles[0] : this.translate.instant('STK_LABEL_PRIMARY_STOCK'),
                row.username ? row.username : '-',
                row.fromRange.toString(),
                row.toRange.toString(),
                (row.toRange - row.fromRange + 1).toString(),
                this.translate.instant(undoRequestStatusLocale(row.undoRequestStatus)),
                row.stockType.titles[0],
                row.reason ? row.reason : '-'
            ]);
        }

        exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Stock-Return-Requests');
    }
}