import { Component, OnInit, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/shared/services/utils';
import { TripTikDetailsDto } from '../../models/trip-tik';

@Component({
  selector: 'mac-receipt-print',
  templateUrl: './receipt-print.component.html',
  styleUrls: ['./receipt-print.component.scss']
})
export class ReceiptPrintComponent implements OnInit {
  dto: TripTikDetailsDto;
  issueDate: Date;

  constructor(private elRef: ElementRef, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  async print(dto: TripTikDetailsDto) {
    this.dto = dto;
    this.issueDate = this.dto.issued ? this.dto.issued : new Date();

    setTimeout(() => {
      const printHtmlContent = this.elRef.nativeElement.innerHTML;
      Utils.printHtml(printHtmlContent);
    }, 500);
  }
}
