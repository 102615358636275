// export enum Role {
//     LOOKUPS_CREATE,                             //system
//     LOOKUPS_READ ,
//     LOOKUPS_UPDATE,                             //system
//     LOOKUPS_DELETE,                             //system
//     ACCOUNTS_CREATE,
//     ACCOUNTS_READ,
//     ACCOUNTS_UPDATE,
//     ACCOUNTS_DELETE,
//     USERS_CREATE,
//     USERS_READ,
//     USERS_UPDATE,
//     USERS_DELETE,
//     SECURITY_GROUPS_CREATE,
//     SECURITY_GROUPS_READ,
//     SECURITY_GROUPS_UPDATE,
//     SECURITY_GROUPS_DELETE,
//     USERS_SECURITY_GROUPS_READ,
//     USERS_SECURITY_GROUPS_UPDATE,
//     STOCKS_TRANSACTIONS_CREATE,
//     STOCKS_TRANSACTIONS_READ,
//     STOCKS_UNDO_REQUESTS_CREATE,
//     STOCKS_UNDO_REQUESTS_READ,
//     STOCKS_UNDO_REQUESTS_UPDATE,
//     STOCKS_UNDO_REQUESTS_DELETE,
//     SYSTEM_STOCKS_CREATE,                       //system
//     SYSTEM_STOCKS_READ,                         //system
//     SYSTEM_STOCKS_UPDATE,                       //system
//     SYSTEM_STOCKS_DELETE                        //system
// }


export enum Role {
    LOOKUPS_CREATE = 11,                             //system
    LOOKUPS_READ = 12,
    LOOKUPS_UPDATE = 13,                             //system
    LOOKUPS_DELETE = 14,                             //system
    ACCOUNTS_CREATE = 21,
    ACCOUNTS_READ = 22,
    ACCOUNTS_UPDATE = 23,
    ACCOUNTS_DELETE = 24,
    USERS_CREATE = 31,
    USERS_READ = 32,
    USERS_UPDATE = 33,
    USERS_DELETE = 34,
    SECURITY_GROUPS_CREATE = 41,
    SECURITY_GROUPS_READ = 42,
    SECURITY_GROUPS_UPDATE = 43,
    SECURITY_GROUPS_DELETE = 44,
    USERS_SECURITY_GROUPS_READ = 52,
    USERS_SECURITY_GROUPS_UPDATE = 53,
    STOCKS_TRANSACTIONS_CREATE = 61,
    STOCKS_TRANSACTIONS_READ = 62,
    STOCKS_UNDO_REQUESTS_CREATE = 71,
    STOCKS_UNDO_REQUESTS_READ = 72,
    STOCKS_UNDO_REQUESTS_UPDATE = 73,
    STOCKS_UNDO_REQUESTS_DELETE = 74,
    SYSTEM_STOCKS_CREATE = 81,                       //system
    SYSTEM_STOCKS_READ = 82,                         //system
    SYSTEM_STOCKS_UPDATE = 83,                       //system
    SYSTEM_STOCKS_DELETE = 84,                       //system
    INT_LICENSES_CREATE = 91,
    INT_LICENSES_READ = 92,
    INT_LICENSES_UPDATE = 93,
    INT_LICENSES_DELETE = 94,
    INT_LICENSES_REVOKE_REQUESTS_CREATE = 101,
    INT_LICENSES_REVOKE_REQUESTS_READ = 102,
    INT_LICENSES_REVOKE_REQUESTS_UPDATE = 103,
    INT_LICENSES_REVOKE_REQUESTS_DELETE = 104,
    TRIPTIKS_CREATE = 111,
    TRIPTIKS_READ = 112,
    TRIPTIKS_UPDATE = 113,
    TRIPTIKS_DELETE = 114,
    TRIPTIKS_REVOKE_REQUESTS_CREATE = 121,
    TRIPTIKS_REVOKE_REQUESTS_READ = 122,
    TRIPTIKS_REVOKE_REQUESTS_UPDATE = 123,
    TRIPTIKS_REVOKE_REQUESTS_DELETE = 124,
    TRIPTIKS_CLAIMS_CREATE = 131,
    TRIPTIKS_CLAIMS_READ = 132,
    TRIPTIKS_CLAIMS_UPDATE = 133,
    // TRIPTIKS_CLAIMS_LOGS_READ = 142,
    // TRIPTIKS_CLAIMS_STATUS_UPDATE = 143,
    TRIPTIKS_CLAIMS_STATUS_UPDATE = 143,
    TRIPTIKS_CLAIMS_LOGS_READ = 152,
    GOMROK_NWEEBA = 153,
}