import { Component, OnInit } from '@angular/core';
import { LoadingService, LoaderType } from '../../services/loading';

@Component({
  selector: 'mac-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private loader: LoadingService) { }

  ngOnInit() {
    //    this.loader.load(LoaderType.Nav, false);
  }
  ngAfterViewInit() {
    //todo: check why loader keep loading ?
    setTimeout(() => {
      this.loader.load(LoaderType.Nav);
      this.loader.load(LoaderType.Nav, false);
    }, 1000);


  }

}
