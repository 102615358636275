import { Injectable } from '@angular/core';
import { StockTransactionsSearchQuery, TransactionsVm } from '../models/transactions';
import { StocksTotalsVm } from '../models/stocks-totals';
import { AvailableStocksVm } from '../models/available-stocks';
import { UndoRequestsVm } from '../models/undo-requests';
import { ApiHandler } from 'src/app/core/api-handler';
import { TransactCmd } from '../models/transact.cmd';
import { PopulateCmd } from '../models/populate.cmd';
import { TryUndoCmd } from '../models/try-undo.cmd';
import { ResolveUndoCmd } from '../models/resolve-undo.cmd';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { PaginationQuery } from '../../issuing/_shared/models/issuing.models';



@Injectable()
export class StocksService {
    constructor(private api: ApiHandler) { }

    getTransactions(pagination: PaginationQuery, query: string = '') {
        return this.api.get<TransactionsVm>(EndPoint.STOCKS_TRANSACTIONS, null, { ...pagination, query });
    }

    searchTransactions(pagination: PaginationQuery, query: StockTransactionsSearchQuery) {
        return this.api.get<TransactionsVm>(EndPoint.STOCKS_TRANSACTIONS_SEARCH, null, { ...pagination, ...query });
    }

    getStockTotals(pageIndex: number = 0, pageSize: number, sort: string, direction: string, filter: string = '', accountId: number = 0) {
        return this.api.get<StocksTotalsVm>(EndPoint.STOCKS_TOTALS, accountId, { pageIndex, pageSize, sort, direction, filter });
    }

    getUndoRequests(pageIndex: number = 0, pageSize: number, sort: string, direction: string, stockTypeId: number = 0, filter: string = '') {
        return this.api.get<UndoRequestsVm>(EndPoint.STOCKS_UNDO_REQUESTS, [stockTypeId], { pageIndex, pageSize, sort, direction, filter });
    }

    getAvailableStocks(stockTypeId: number = 0, accountId = 0) {
        return this.api.get<AvailableStocksVm>(EndPoint.STOCKS_AVAILABLE, [stockTypeId, accountId])
            .toPromise();
    }

    getSystemAvailableStocks(stockTypeId: number, gaps: boolean = false, threshold: boolean = true) {
        return this.api.get<AvailableStocksVm>(EndPoint.STOCKS_SYSTEM_AVAILABLE, [stockTypeId, gaps, threshold])
            .toPromise();
    }

    transact(cmd: TransactCmd) {
        return this.api.create<TransactCmd, boolean>(EndPoint.STOCKS_TRANSACT, cmd).toPromise();
    }

    populate(cmd: PopulateCmd) {
        return this.api.create<PopulateCmd, boolean>(EndPoint.STOCKS_POPULATE, cmd).toPromise();
    }

    tryUndo(cmd: TryUndoCmd) {
        return this.api.create<TryUndoCmd, boolean>(EndPoint.STOCKS_UNDO_TRY, cmd).toPromise();
    }

    resolveUndo(cmd: ResolveUndoCmd) {
        return this.api.create<ResolveUndoCmd, boolean>(EndPoint.STOCKS_UNDO_RESOLVE, cmd).toPromise();
    }

}

