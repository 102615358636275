import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/models/enums/language';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { LoaderType, LoadingService } from 'src/app/shared/services/loading';
import { AccountDto } from '../../models/account';
import { AccountsService } from '../../services/accounts.service';

@Component({
  selector: 'mac-view-account',
  templateUrl: './view-account.component.html',
  styleUrls: ['./view-account.component.scss'],
  providers: [
    AccountsService,
    FileLoaderService
  ]
})
export class ViewAccountComponent implements OnInit {

  accountId: number;
  dto: AccountDto;
  lang: Language;
  //_stockTypes: string[] = [];
  _parentAccounts: string[] = [];

  constructor(private loader: LoadingService,
    public translate: TranslateService,
    private service: AccountsService,
    private route: ActivatedRoute,
    private fileLoader: FileLoaderService) { }

  async ngOnInit() {

    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.accountId = Number(this.route.snapshot.paramMap.get("id"));

    if (this.accountId) {
      //update model, get account details from server and update form values


      var result = await this.service.getAccount(this.accountId);
      this.dto = result.account;
      result.parentAccounts

      //this._stockTypes = this.dto.stockTypes.map(x => x.titles[0]);
      this._parentAccounts = result.parentAccounts.reverse().map(x => x.titles[0]).slice(1);

      this.dto.attachments.forEach(async (att, i) => {
        att.url = await this.fileLoader.loadAsync(<string>att.url);
      });

      //console.log(this.dto);
    }

    this.loader.load(LoaderType.Nav, false);
  }

}
