
export class PostTripTikCmd {
    constructor(form) {
        this.serial = form.serial;
        this.stockTypeId = form.stockTypeId;
        //this.value = form.value;
        //this.currencyId = form.currencyId;

        this.cName = form.steps[0].name;
        this.cResidenceCountryId = form.steps[0].residenceCountryId;
        this.cNationalityId = form.steps[0].nationalityId;
        this.cLocalAddress = form.steps[0].localAddress;
        this.cIdentityID = form.steps[0].identityID;
        this.cPassportID = form.steps[0].passportID;
        this.cLocalPhoneNumber = form.steps[0].localPhoneNumber;
        this.cIntPhoneNumber = form.steps[0].intPhoneNumber;
        this.cIntAddress = form.steps[0].intAddress;
        //drivers?
        this.cDrivers = form.steps[0].drivers;
        this.cDrivers.forEach((d, i) => {
            d.attachments =
                Object.keys(form.steps[2].attachments[i + 1])
                    .map(function (key) {
                        return form.steps[2].attachments[i + 1][key];
                    })
        });

        this.cAttachments = Object.keys(form.steps[2].attachments[0])
            .map(function (key) {
                return form.steps[2].attachments[0][key];
            });

        this.vPlateID = form.steps[1].plateID;
        this.vRegistrationCountryId = form.steps[1].registrationCountryId;
        this.vSeatsCount = form.steps[1].seatsCount;
        this.vSpareTiresCount = form.steps[1].spareTiresCount;

        this.vEquipments = form.steps[1].equipments;
        this.vValue = form.steps[1].value;
        this.vCurrencyId = form.steps[1].currencyId;

        this.vWeightInKG = form.steps[1].weightInKG;
        this.vHorsePower = form.steps[1].horsePower;
        //this.vColorId = form.steps[1].colorId;
        this.vColors = form.steps[1].colors;
        this.vCylindersCount = form.steps[1].cylindersCount;
        this.vEngineID = form.steps[1].engineID;
        this.vChassisID = form.steps[1].chassisID;
        this.vManufactureYear = form.steps[1].manufactureYear;
        this.vTypeId = form.steps[1].typeId;
        this.vMakeId = form.steps[1].makeId;
        this.vModelId = form.steps[1].modelId;
        this.vExtras = form.steps[1].extras;
        this.vUpholsteryType = form.steps[1].upholsteryType;
        this.vOptions = form.steps[1].options;


        //this.vCurrencyId = 1;
    }

    id: string;
    serial: number;
    stockTypeId: number;
    //value: number;
    //currencyId: number;

    //cust
    cName: string;
    cResidenceCountryId: number;
    cNationalityId: number;
    cLocalAddress: string;
    cIntAddress: string;
    cIdentityID: string;
    cPassportID: string;
    cLocalPhoneNumber: string;
    cIntPhoneNumber: string;
    cAttachments: string[];
    cDrivers: {
        name: string,
        passportIDorIdentityID: string,
        address: string,
        phoneNumber: string,
        attachments: string[]
    }[];
    //vehicle
    vPlateID: string;
    vRegistrationCountryId: number;
    vSeatsCount: number;
    vSpareTiresCount: number;
    vEquipments: string;
    vValue: number;
    vCurrencyId: number;
    vWeightInKG: number;
    vHorsePower?: number;
    //vColorId: number;
    vColors: number[];
    vCylindersCount: number;
    vEngineID: string;
    vChassisID: string;
    vManufactureYear: number;
    vTypeId: number;
    vMakeId: number;
    vModelId: number;
    vExtras: string;

    vUpholsteryType?: number;
    vOptions: number[];
}
