import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private apiUrl = 'https://gomrok-api.macidp.org/api/Upload';

  constructor(private http: HttpClient) { }

  uploadFiles(files: File[], tripTikDocumentId: string) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file, file.name);
    });

    return this.http.post(`${this.apiUrl}?tripTikDocumentId=${tripTikDocumentId}`, formData);
  }

  checkFiles(tripTikDocumentId: string) {
    return this.http.get<{ Exists: boolean, Files?: string[] }>(`${this.apiUrl}/check?tripTikDocumentId=${tripTikDocumentId}`);
  }
}
