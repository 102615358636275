import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { Language } from 'src/app/core/models/enums/language';
import { TripTiksClaimsService } from '../../services/trip-tiks-claims.service';
import { Gender } from 'src/app/core/models/enums/gender';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal, UpdateModal } from 'src/app/shared/models/modal';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { CountryDto } from 'src/app/features/lookups/models/country';
import { IntLicensePermitClass } from 'src/app/core/models/enums/license-permit-class';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { TripTiksClaimsDataSource } from '../../services/trip-tiks-claims.datasource';
import { PaginationQuery } from 'src/app/features/issuing/_shared/models/issuing.models';
import { TripTikClaimDto, TripTikClaimLogDto } from '../../models/trip-tik-claim.model';
import { TripTiksClaimsLogsDataSource } from '../../services/trip-tiks-claims-logs.datasource';
import { CustomsPortDto } from 'src/app/features/lookups/models/customs-port';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { CustomsPortsService } from 'src/app/features/lookups/services/customs-ports.service';
import { PursuingZonesService } from 'src/app/features/lookups/services/pursuing-zones';
import { TripTikClaimsStatusesService } from 'src/app/features/lookups/services/trip-tik-claims-statuses.service';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { QRCodeErrorCorrectionLevel } from 'angularx-qrcode';
import { Utils } from 'src/app/shared/services/utils';

@Component({
  selector: 'mac-trip-tiks-claims-logs',
  templateUrl: './trip-tiks-claims-logs.component.html',
  styleUrls: ['./trip-tiks-claims-logs.component.scss'],
  providers: [
    TripTiksClaimsService,
    TripTikClaimsStatusesService,
    PursuingZonesService,
    AccountsService,
    StockTypesService,
    CustomsPortsService
  ]
})
export class TripTiksClaimsLogsComponent implements OnInit {
  moment = moment;
  //displayedColumns: string[] = ['entranceID', 'created', 'entranceDate', 'name', 'accountTitles', 'serial', 'plateID', 'portTitles', 'followUpCity', 'latestStatus', 'tools'];
  displayedColumns: string[] = ['created', 'createdBy', 'holderName', 'serial', 'stockType', 'issued', 'customsPort', 'latestStatus', 'note'];
  dataSource: TripTiksClaimsLogsDataSource;
  lang: Language;
  advancedSearchMode: boolean;
  pursuingZones: LookupDto[] = [];
  accounts: LookupDto[] = [];
  customsPorts: CustomsPortDto[] = [];
  cities: LookupDto[] = [];
  statuses: LookupDto[] = [];
  stockTypes: StockTypeDto[] = [];
  query: any = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild('filterBtn') filterBtn: ElementRef;

  get Role() { return Role; };
  get Gender() { return Gender; };
  get PrintObject() { return PrintObject; };
  get IntLicensePermitClass() { return IntLicensePermitClass };
  get Object() { return Object; };

  constructor(private loader: LoadingService,
    private tripTiksClaimsService: TripTiksClaimsService,
    private tripTikClaimsStatusesService: TripTikClaimsStatusesService,
    private pursuingZonesService: PursuingZonesService,
    public translate: TranslateService,
    private stockTypesService: StockTypesService,
    private customsPortsService: CustomsPortsService,
    private accountsService: AccountsService,
    public auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.formInit();

    Promise.all([
      this.stockTypesService.get(this.lang)
        .then(result => this.stockTypes = result.list.filter(x => x.documentType == _DocumentType.TripTikDocument)),
      // this.pursuingZonesService.get(this.lang)
      //   .then(result => this.pursuingZones = result.list),
      this.pursuingZonesService.get(this.lang)
        .then(result => {
          this.pursuingZones = !this.auth.currentUser.isAdministrator ?
            result.list.filter(x => this.auth.currentUser.claimsCountries.map(y => Number(y)).indexOf(x.id) > -1)
            : result.list;
        }),
      this.tripTikClaimsStatusesService.get(0, this.lang)
        .then(result => {
          this.statuses = result.list.filter(x => x.parentId).map(item => {
            item.titles = [`${item.parentTitles} - ${item.titles}`];
            return item;
          })
        }),
      this.customsPortsService.get(this.lang)
        .then(result => this.customsPorts = result.list)
    ]);

    //if system account, accounts will be fetched from server, otherwise current account details will be fetched from local auth service
    if (this.auth.currentUser.isSystemAccount) {
      this.accountsService.getChildrenAccountsLookup(0, false)
        .then(result => {
          this.accounts = result.list;
        }).catch(e => this.searchForm.get('accountId').disable());
    } else {
      this.accounts = [<LookupDto>{ id: this.auth.currentUser.accountId, titles: [this.auth.currentUser.accountTitles[this.lang]] }];
      this.searchForm.patchValue({ accountId: this.auth.currentUser.accountId });
      this.searchForm.get('accountId').disable();
    }

    this.route.queryParamMap.subscribe(p => {
      //console.warn(p);

      if (!p.has('pageIndex')) {
        //console.warn('ready to reload!');
        this.advancedSearchMode = false;
        this.activeFilters = false;
        this.filter.nativeElement.value = '';
        this.paginator.page.emit({ pageIndex: 0, pageSize: this.paginator.pageIndex, length: this.paginator.length });
      }
    });

    //let query = null;

    if (this.route.snapshot.queryParams.query) {
      this.query = this.route.snapshot.queryParams.query;
      this.filter.nativeElement.value = this.query;
    }
    else if (this.route.snapshot.queryParams.accountId
      || this.route.snapshot.queryParams.countryId
      || this.route.snapshot.queryParams.stockTypeId
      || this.route.snapshot.queryParams.created
      || this.route.snapshot.queryParams.entranceDate
      || this.route.snapshot.queryParams.entranceID
      || this.route.snapshot.queryParams.holderName
      || this.route.snapshot.queryParams.vehiclePlateID
      || this.route.snapshot.queryParams.customsPortId
      || this.route.snapshot.queryParams.pursuingZoneId
      || this.route.snapshot.queryParams.latestStatusId
      || this.route.snapshot.queryParams.serial) {
      this.activeFilters = true;
      this.searchForm.patchValue(this.route.snapshot.queryParams);

      //fix int for ng-select
      this.searchForm.patchValue({ accountId: !isNaN(this.route.snapshot.queryParams.accountId) ? parseInt(this.route.snapshot.queryParams.accountId) : null });

      this.query = this.searchForm.value;
    }

    this.fetchData(this.query);
  }

  ngAfterViewInit() {
    this.dataSource.counter$
      .pipe(
        tap((count) => {
          this.paginator.length = count;
        })
      ).subscribe();

    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap(() => {
          this.dataSource.loadTripTiksClaimsLogs({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction },
            this.advancedSearchMode || this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim())
        })
      ).subscribe(() => this.loader.load(LoaderType.Body));

    merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'))
      .pipe(
        tap(() => {
          this.advancedSearchMode = false;
          this.activeFilters = false;
          this.searchForm.reset();
          this.paginator.pageIndex = 0;
          this.dataSource.loadTripTiksClaimsLogs({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.filter.nativeElement.value.trim())
        })
      ).subscribe(() => this.loader.load(LoaderType.Body));

    this.dataSource.connect()
      .subscribe(() => {
        let pagination: PaginationQuery = { pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction };
        let query = this.activeFilters ? this.searchForm.value : this.filter.nativeElement.value.trim();
        this.query = query;

        if (this.activeFilters)
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, ...query }, replaceUrl: true });
        else
          this.router.navigate(['.'], { relativeTo: this.route, queryParams: { ...pagination, query }, replaceUrl: true });
      });
  }

  fetchData(query) {
    this.paginator.pageIndex = this.route.snapshot.queryParams.pageIndex ? this.route.snapshot.queryParams.pageIndex : 0;
    this.paginator.pageSize = this.route.snapshot.queryParams.pageSize ? this.route.snapshot.queryParams.pageSize : 25;
    this.sort.active = this.route.snapshot.queryParams.sort ? this.route.snapshot.queryParams.sort : 'created';
    this.sort.direction = this.route.snapshot.queryParams.dir ? this.route.snapshot.queryParams.dir : 'desc';
    this.dataSource = new TripTiksClaimsLogsDataSource(this.tripTiksClaimsService, this.loader);
    this.dataSource.loadTripTiksClaimsLogs({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, query);
  }

  toggleAdvancedSearch() {
    this.advancedSearchMode = !this.advancedSearchMode;
    this.filter.nativeElement.value = '';
  }

  onExportToExcel(all: boolean = true) {
    this.loader.load(LoaderType.Body);
    if (all)
      this.tripTiksClaimsService[(this.query == null || typeof this.query == 'string') ? 'getClaimsLogs' : 'searchClaimsLogs']({ pageIndex: 0, pageSize: this.paginator.length, sort: this.sort.active, dir: this.sort.direction }, <any>this.query)
        .toPromise()
        .then(data => {
          this.exportToExcel(data.list);
        }).finally(() => this.loader.load(LoaderType.Body, false));
    else
      this.dataSource.connect().subscribe(data => {
        this.exportToExcel(data);
        this.loader.load(LoaderType.Body, false);
      }).unsubscribe();
  }

  private exportToExcel(data: TripTikClaimLogDto[]) {
    //  const wscols = [
    //     { width: 12 },
    //     { width: 28 },
    //     { width: 12 },
    //     { width: 18 },
    //     { width: 28 },
    //     { width: 18 },
    //     { width: 18 },
    //     { width: 18 },
    //     { width: 18 }
    //  ];
    //  const autofilter = { ref: 'A1:I1' };
    //  const rows: string[][] = [];

    //  const headers = [
    //     this.translate.instant('_LABEL_SERIAL'),
    //     this.translate.instant('_FIELD_NAME'),
    //     this.translate.instant('_LABEL_GENDER'),
    //     this.translate.instant('_FIELD_PHONE_NO'),
    //     this.translate.instant('ACC_FIELD_ACCOUNT_TITLE'),
    //     this.translate.instant('ISS_FIELD_PASSPORT_NUMBER'),
    //     this.translate.instant('ISS_LABEL_ISSUE_DATE'),
    //     this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('LIC_LABEL_PRINT_OBJECT_LICENSE') }),
    //     this.translate.instant('ISS_LABEL_PRINT_OBJECT_PRINTED_COUNT', { param: this.translate.instant('LIC_LABEL_PRINT_OBJECT_STICKER') }),
    //  ];

    //  rows.push(headers);
    //  for (let row of data) {
    //     rows.push([
    //        row.serial,
    //        row.name,
    //        this.translate.instant(row.gender == Gender.Male ? '_LABEL_MALE' : '_LABEL_FEMALE'),
    //        row.phoneNumber,
    //        row.accountTitles[0],
    //        row.passportID,
    //        moment(row.issued).format('DD/MM/YYYY'),
    //        this.printObjectsCount(row.printLogs, PrintObject.IntLicenseCard),
    //        this.printObjectsCount(row.printLogs, PrintObject.IntLicenseSticker)
    //     ]);
    //  }

    //  exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Int-Licenses');
  }

  onSubmit() {
    if (this.created.value)
      this.created.setValue(Utils.convertToServerDateTimeString(this.created.value));

    if (this.entranceDate.value)
      this.entranceDate.setValue(Utils.convertToServerDateTimeString(this.entranceDate.value));

    this.activeFilters = true;

    this.paginator.pageIndex = 0;
    this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
  }

  get created() { return this.searchForm.get('created'); };
  get entranceDate() { return this.searchForm.get('entranceDate'); };
  searchForm: FormGroup;
  activeFilters: boolean;

  resetSearch() {
    this.advancedSearchMode = false;
    if (this.activeFilters) {
      this.activeFilters = false;
      this.paginator.pageIndex = 0;
      this.paginator.page.emit({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, length: this.paginator.length });
    }

    this.activeFilters = false;

    setTimeout(() => {
      this.searchForm.reset();
      this.query = null;
    }, 500);
  }

  private formInit() {
    this.searchForm = this.fb.group({
      accountId: [null],
      stockTypeId: [''],
      serial: [''],
      created: [''],
      entranceDate: [''],
      entranceID: [''],
      holderName: [''],
      vehiclePlateID: [''],
      customsPortId: [''],
      pursuingZoneId: [''],
      latestStatusId: ['']
    });
  }
}