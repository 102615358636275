import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { LookupVm, LookupDto } from '../models/lookup';

@Injectable()
export class PursuingZonesService {
    constructor(private api: ApiHandler) { }

    get(lang = null) {
        return this.api.get<LookupVm>(EndPoint.TRIPTIK_CLAIMS_PURSUING_ZONES, null, { lang }).toPromise();
    }

    create(dto: LookupDto) {
        return this.api.create<LookupDto, number>(EndPoint.TRIPTIK_CLAIMS_PURSUING_ZONES, dto).toPromise();
    }

    update(dto: LookupDto) {
        return this.api.update(EndPoint.TRIPTIK_CLAIMS_PURSUING_ZONES, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.TRIPTIK_CLAIMS_PURSUING_ZONES, id).toPromise();
    }
}