import { Component, OnInit, Inject } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { IssuingService } from '../../services/issuing.service';
import { DocumentValidityVm } from '../../models/issuing.models';

@Component({
  selector: 'mac-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
  providers: [IssuingService]
})
export class VerificationComponent implements OnInit {
  dto: DocumentValidityVm;
  key: string;
  loading: boolean = true;

  constructor(@Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private loader: LoadingService,
    private issuingService: IssuingService) {
    //this.loadStyle("en"); 
    LoadingService.style.next(false);
  }

  ngOnInit(): void {

    // setTimeout(() => {
    //   this.loader.load(LoaderType.Nav, false);
    //   this.loader.styleLoading.emit(false);
    //   LoadingService.style.next(false);

    this.translate.use('en');

    this.key = this.route.snapshot.paramMap.get('key');

    this.issuingService.verify(this.key)
      .then(result => { //console.log(result); 
        this.dto = result 
      })
      .finally(() => this.loading = false);


  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.loader.load(LoaderType.Nav, false);
      //LoadingService.style.next(false);
    }, 500);

    //this.loader.load(null, false);
  }


  // private loadStyle(lang: string) {

  //   const head = this.document.getElementsByTagName('head')[0];
  //   const body = this.document.getElementsByTagName('body')[0];

  //   body.setAttribute('dir', lang == 'ar' ? 'rtl' : 'ltr');
  //   const styleFileName = lang == 'ar' ? 'bootstrap-rtl.css' : 'bootstrap.css';
  //   let themeLink = this.document.getElementById('bootstrap') as HTMLLinkElement;
  //   if (themeLink) {
  //     themeLink.href = styleFileName;
  //   } else {
  //     const style = this.document.createElement('link');
  //     style.id = 'bootstrap';
  //     style.rel = 'stylesheet';
  //     style.href = styleFileName;
  //     //will be always overriden by global styles.scss
  //     head.prepend(style);
  //   }
  // }

}
