import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { CountryDto } from 'src/app/features/lookups/models/country';
import { CountriesService } from 'src/app/features/lookups/services/countries';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { StocksService } from 'src/app/features/stock/services/stocks.service';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import * as moment from 'moment';
import { Utils } from 'src/app/shared/services/utils';
// import { isArray } from 'util';
import { ActivatedRoute, Router } from '@angular/router';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import * as _ from 'lodash';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { AuthService } from 'src/app/core/auth/auth.service';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { CurrenciesService } from 'src/app/features/lookups/services/currencies';
import { VehicleColorsService } from 'src/app/features/lookups/services/vehicle-colors';
import { VehicleModelsService } from 'src/app/features/lookups/services/vehicle-models';
import { VehicleMakesService } from 'src/app/features/lookups/services/vehicle-makes';
import { VehicleTypesService } from 'src/app/features/lookups/services/vehicle-types';
import { CurrencyDto } from 'src/app/features/lookups/models/currency';
import { VehicleModelDto } from 'src/app/features/lookups/models/vehicle-model';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import { TripTiksService } from '../../services/trip-tiks.service';
import { PostTripTikCmd } from '../../models/create.cmd';
import { StaticConfig } from 'src/app/features/lookups/services/config';
import { ConigKeyValuePair } from 'src/app/features/lookups/models/config';
import { TripTikDetailsDto } from '../../models/trip-tik';
import { MatStepper } from '@angular/material/stepper';
import { Role } from 'src/app/core/models/enums/role';
import { threadId } from 'worker_threads';
import { numbersOnlyValidator, nonArabicCharactersValidator, passportIdOrIdentityIdValidator, nonEnglishCharactersValidator, addressValidator, phoneNumberValidator, vehicleSeatsCountRangeValidator, vehicleManufactureYearRangeValidator, vehicleWeightInKGRangeValidator, vehicleCylindersCountRangeValidator, vehicleHorsePowerRangeValidator, vehicleValueRangeValidator, comparePasswords, _passportIdOrIdentityIdValidator } from 'src/app/shared/services/custom-validators';
import { _DocumentType } from 'src/app/core/models/enums/document-type';

@Component({
    selector: 'mac-manage-trip-tik',
    templateUrl: './manage-trip-tik.component.html',
    styleUrls: ['./manage-trip-tik.component.scss'],
    providers: [
        CountriesService,
        StocksService,
        TripTiksService,
        FileLoaderService,
        StockTypesService,
        CurrenciesService,
        VehicleColorsService,
        VehicleMakesService,
        VehicleModelsService,
        VehicleTypesService
    ]
})
export class ManageTripTikComponent implements OnInit {
    lang: Language;
    countries: CountryDto[] = [];
    vehicleRegistrationCountries: CountryDto[] = [];
    stockTypes: StockTypeDto[] = [];
    currencies: CurrencyDto[] = [];
    vehicleColors: LookupDto[] = [];
    vehicleModels: VehicleModelDto[] = [];
    vehicleMakes: LookupDto[] = [];
    vehicleTypes: LookupDto[] = [];
    vehicleUpholsteryTypes: ConigKeyValuePair[] = [];
    vehicleSpareTiresCountOptions: number[] = [];
    vehicleCylindersCountRange: number[] = [];
    vehicleManufactureYearRange: number[] = [];
    vehicleSeatsCountRange: number[] = [];
    vehicleOptions: ConigKeyValuePair[] = [];
    form: FormGroup;
    availableSerials: number[];
    updateMode: boolean;
    //_loadingGif: string = 'assets/images/Ring-Preloader.gif';
    dateFormat = 'L';
    dto: TripTikDetailsDto = new TripTikDetailsDto();
    mode: 'ADD' | 'UPDATE' | 'CLONE';
    objectId: string;
    key: string;

    @ViewChild(MatStepper, { static: true }) stepper: MatStepper;

    get moment() { return moment };
    get Object() { return Object };
    get ConfigObject() { return ConfigObject; };

    get steps() { return this.form.get('steps') as FormArray };
    get step1() { return this.steps.get([0]) };
    get step2() { return this.steps.get([1]) };
    get step3() { return this.steps.get([2]) };

    //doc - global
    get serial() { return this.form.get('serial') };
    get stockType() { return this.form.get('stockTypeId') };
    //get value() { return this.form.get('value') };
    //get currency() { return this.form.get('currencyId') };

    //cust - step1
    get cName() { return this.step1.get('name') };
    get cResidenceCountry() { return this.step1.get('residenceCountryId') };
    get cNationality() { return this.step1.get('nationalityId') };
    get cIdentityID() { return this.step1.get('identityID') };
    get cPassportID() { return this.step1.get('passportID') };
    get cLocalAddress() { return this.step1.get('localAddress') };
    get cLocalPhoneNumber() { return this.step1.get('localPhoneNumber') };
    get cIntAddress() { return this.step1.get('intAddress') };
    get cIntPhoneNumber() { return this.step1.get('intPhoneNumber') };
    get drivers() { return (this.step1 as FormGroup).get('drivers') as FormArray };

    //vehc - step2
    get vPlateID() { return this.step2.get('plateID') };
    get vRegistrationCountry() { return this.step2.get('registrationCountryId') };
    get vMake() { return this.step2.get('makeId') };
    get vModel() { return this.step2.get('modelId') };
    get vType() { return this.step2.get('typeId') };
    get vSeatsCount() { return this.step2.get('seatsCount') };
    get vManufactureYear() { return this.step2.get('manufactureYear') };
    get vWeightInKG() { return this.step2.get('weightInKG') };
    get vCylindersCount() { return this.step2.get('cylindersCount') };
    get vHorsePower() { return this.step2.get('horsePower') };
    get vColors() { return this.step2.get('colors') };
    get vUpholsteryType() { return this.step2.get('upholsteryType') };
    get vEngineID() { return this.step2.get('engineID') };
    get vChassisID() { return this.step2.get('chassisID') };
    get vOptions() { return this.step2.get('options') as FormArray };
    get vSpareTiresCount() { return this.step2.get('spareTiresCount') };
    get vEquipments() { return this.step2.get('equipments') };
    get vExtras() { return this.step2.get('extras') };
    get vValue() { return this.step2.get('value') };
    get vCurrency() { return this.step2.get('currencyId') };

    //att - step3
    get attachments() { return this.step3.get('attachments') as FormArray };

    constructor(private loader: LoadingService,
        private fb: FormBuilder,
        private countriesService: CountriesService,
        private stocksService: StocksService,
        public translate: TranslateService,
        private tripTiksService: TripTiksService,
        private stockTypesService: StockTypesService,
        private currenciesService: CurrenciesService,
        private vehicleColorsService: VehicleColorsService,
        private vehicleMakesService: VehicleMakesService,
        private vehicleModelsService: VehicleModelsService,
        private vehicleTypesService: VehicleTypesService,
        private route: ActivatedRoute,
        public fileLoader: FileLoaderService,
        private alert: AlertService,
        private router: Router,
        private auth: AuthService) { }

    async ngOnInit() {
        this.loader.load(LoaderType.Nav);

        moment.locale('en-gb');
        document.querySelector('mat-card').classList.remove('overflow-hidden');
        this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
        this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);
        this.mode = this.route.snapshot.data.mode;

        this.formInit();

        //disable form initially
        this.form.disable();
        //this.drivers.controls.forEach(g => {g.disable()});


        // console.log(this.form);
        // console.log(this.form.enabled, this.form.disabled);

        this.stockType.enable();
        // console.log(this.form);
        // console.log(this.form.enabled, this.form.disabled);

        // console.log(this.stockType.value);

        const startYearConfig = StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleManufactureYearRange);
        this.vehicleManufactureYearRange = Utils.spreadRange([Number(startYearConfig[0].value), (new Date().getUTCFullYear() + 1)], true);
        this.vehicleUpholsteryTypes = StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleUpholsteryTypes, this.lang);
        this.vehicleSpareTiresCountOptions = Utils.spreadRange(StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleSpareTiresCountOptions).map(d => Number(d.value)));
        this.vehicleCylindersCountRange = Utils.spreadRange(StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleCylindersCountRange).map(d => Number(d.value)));
        this.vehicleSeatsCountRange = Utils.spreadRange(StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleSeatsCountRange).map(d => Number(d.value)));
        this.vehicleOptions = StaticConfig.getConfigKeyValuePairs(ConfigObject.VehicleOptions, this.lang);

        this.vehicleOptions.forEach(_ => this.vOptions.push(new FormControl(true)));

        await Promise.all([
            this.stockTypesService.getAllowed()
                .then(result => this.stockTypes = result.list.filter(x => x.documentType == _DocumentType.TripTikDocument)),
            this.countriesService.get(this.lang)
                .then(result => this.countries = this.vehicleRegistrationCountries = result.list),
            this.currenciesService.get(this.lang)
                .then(result => {
                    this.currencies = result.list;
                    if (this.currencies)
                        this.vCurrency.setValue(this.currencies[0].id);
                }),
            this.vehicleColorsService.get(this.lang)
                .then(result => this.vehicleColors = result.list),
            this.vehicleMakesService.get(this.lang)
                .then(result => this.vehicleMakes = result.list),
            this.vehicleTypesService.get(this.lang)
                .then(result => this.vehicleTypes = result.list)
        ]);

        this.stepper._stepHeader.forEach(h => h._getHostElement().addEventListener('click', (ev) => {
            this.navigateAttempt();
        }));

        this.objectId = this.route.snapshot.paramMap.get('id');
        this.key = this.route.snapshot.paramMap.get('key');

        //const _stockTypeId = this.stockTypes[0].id;

        // this.form.patchValue({ stockTypeId: _stockTypeId });
        //this.availableSerials = Utils.spreadRanges((await this.stocksService.getAvailableStocks(_stockTypeId)).stockTypesAvailableStocks[0].availableStock);


        if (this.objectId || this.key) {
            this.updateMode = this.mode == 'UPDATE';

            if (this.mode == 'CLONE') {
                this.dto = await (await this.tripTiksService.getByHashedSerial(this.key)).item;
            } else
                this.dto = await (await this.tripTiksService.get(this.objectId)).item;

            //console.log(this.dto);

            if (!this.dto || (this.updateMode && this.dto.issued)) {    //will ignore if cloning
                this.router.navigate([`/${this.translate.currentLang}/not-found`]);
                return;
            }
            if (this.updateMode) {
                this.availableSerials = [this.dto.serial];
                this.form.patchValue({ stockTypeId: this.dto.stockType.id });
                this.onSelectStockType();
            }
            //this.availableSerials.unshift(this.dto.serial);
            // else {
            //     if (this.availableSerials.length > 0)
            //         this.form.patchValue({ serial: this.availableSerials[0] });
            //     else {
            //         this.alert.failure("TRP_ALERT_NO_STOCK_AVAILABLE", false);
            //         // this.form.disable();
            //         return;
            //     }
            // }

            //if (this.updateMode && this.dto.stockType.id != _stockTypeId) {

            //}

            if (this.dto.customer.drivers)
                this.dto.customer.drivers.forEach((driver, i) => {
                    this.driverFormInit(driver);
                });

            this.form.patchValue({
                stockTypeId: this.updateMode ? this.dto.stockType.id : null,
                serial: this.updateMode ? this.dto.serial : null,// this.availableSerials[0],
                //value: this.dto.value,
                steps: [
                    {
                        name: this.dto.customer.owner.name,
                        residenceCountryId: this.dto.customer.owner.residenceCountryId,
                        nationalityId: this.dto.customer.owner.nationalityId ? this.dto.customer.owner.nationalityId : null,
                        identityID: this.dto.customer.owner.identityID,
                        passportID: this.dto.customer.owner.passportID,
                        localAddress: this.dto.customer.owner.address.item1,
                        intAddress: this.dto.customer.owner.address.item2,
                        localPhoneNumber: this.dto.customer.owner.phoneNumber.item1,
                        intPhoneNumber: this.dto.customer.owner.phoneNumber.item2,
                        drivers: this.dto.customer.drivers
                    },
                    {
                        registrationCountryId: this.dto.vehicle.registrationCountryId,
                        plateID: this.dto.vehicle.plateID,
                        makeId: this.dto.vehicle.make ? this.dto.vehicle.make.id : '',
                        modelId: this.dto.vehicle.model ? this.dto.vehicle.model.id : '',
                        typeId: this.dto.vehicle.type ? this.dto.vehicle.type.id : '',
                        seatsCount: this.dto.vehicle.seatsCount,
                        manufactureYear: this.dto.vehicle.manufactureYear,
                        weightInKG: this.dto.vehicle.weightInKG,
                        cylindersCount: this.dto.vehicle.cylindersCount,
                        horsePower: this.dto.vehicle.horsePower,
                        colors: this.dto.vehicle.colors ? this.dto.vehicle.colors.map(d => d.id) : '',
                        upholsteryType: this.dto.vehicle.upholsteryType,
                        engineID: this.dto.vehicle.engineID,
                        chassisID: this.dto.vehicle.chassisID,
                        options: this.dto.vehicle.options,
                        spareTiresCount: this.dto.vehicle.spareTiresCount,
                        equipments: this.dto.vehicle.equipments,
                        extras: this.dto.vehicle.extras,
                        value: this.dto.vehicle.value,
                        currencyId: this.dto.vehicle.currency ? this.dto.vehicle.currency.id : ''
                    },
                    {
                        attachments: [
                            {
                                identityOrPassport: this.dto.customer.owner.attachments[0],
                                drivingLicense: this.dto.customer.owner.attachments[1],
                                vehicleRegistration: this.dto.customer.owner.attachments[2]
                            }
                        ]
                    }
                ]
            });

            this.attachments.controls.forEach(async (group: FormGroup, i) => {
                for (let c in group.controls) {
                    let path = group.controls[c].value;
                    if (this.key)
                        path = `${path}?key=${this.key}`;
                    //console.log(path);
                    group.controls[c].setValue(await this.fileLoader.loadAsync(path));
                }
            });

        } /*else {
            if (this.availableSerials.length > 0)
                this.form.patchValue({ serial: this.availableSerials[0] });
            else {
                this.alert.failure("TRP_ALERT_NO_STOCK_AVAILABLE", false);
                //this.form.disable();
            }
        }*/

        if (!this.updateMode) {
            this.form.disable();
            this.stockType.enable();
        }
        //this.drivers.controls.forEach(g => {g.disable()});


        // console.log(this.form);
        // console.log(this.form.enabled, this.form.disabled);



        this.loader.load(LoaderType.Nav, false);
    }

    isExpired(expiryDate) {
        return moment(expiryDate).isBefore(Date.now());
    }

    addDriver() {
        if (this.availableSerials && this.availableSerials.length > 0)
            this.driverFormInit();
    }

    removeDriver(i) {
        if (this.availableSerials && this.availableSerials.length > 0) {
            this.drivers.removeAt(i);
            this.attachments.removeAt(i + 1);
        }
    }

    fetchModels(makeId) {
        this.vModel.reset();
        this.vehicleModelsService.get(makeId, this.stockType.value, this.lang)
            .then(result => {
                this.vehicleModels = result.list;
            });
    }

    async onSelectStockType() {
        //console.log(this.stockType.value);
        this.loader.load(LoaderType.Body);
        this.availableSerials = Utils.spreadRanges((await this.stocksService.getAvailableStocks(this.stockType.value)).stockTypesAvailableStocks[0].availableStock);
        if (this.availableSerials.length > 0 || (this.updateMode && Number(this.stockType.value) == this.dto.stockType.id)) {
            if (this.updateMode && Number(this.stockType.value) == this.dto.stockType.id)
                this.availableSerials.unshift(this.dto.serial);
            this.form.patchValue({ serial: this.availableSerials[0] });
            this.form.enable();
            this.form.markAsUntouched();

            //if تربتيك يمن لوحات سعودية
            if (Number(this.stockType.value) == 3) {  //temp //todo: refactor (many-to-many join)

                this.vehicleRegistrationCountries = [...this.countries.filter(x => x.abbreviation == "SA")];
                this.vRegistrationCountry.setValue(this.vehicleRegistrationCountries[0].id);


                //this.vRegistrationCountry.disable();
            } else {
                this.vehicleRegistrationCountries = [...this.countries];
                //todo: check value after swapping
                if (!this.updateMode)
                    this.vRegistrationCountry.reset();
            }

        } else {
            this.alert.failure("TRP_ALERT_NO_STOCK_AVAILABLE", false);
            this.availableSerials = [];
            this.serial.reset();
            
            this.form.disable();

            if (this.stockTypes.length > 1)
                this.stockType.enable();

            this.stepper.selectedIndex = 0;
        }
        this.loader.load(LoaderType.Body, false);
    }

    navigateAttempt(i: number = null) {

        //console.log('nav', this.stepper.selected);
        //if (this.stockType.value) {
        //  console.log('test');
        const ctrl = (i != null ? this.steps.controls[i] : this.stepper.selected.stepControl) as FormGroup;
        //console.warn('attempt', i, ctrl);
        // console.log(ctrl);
        // if (!this.stockType.value) {
        //     ctrl.setErrors({ required: true });
        //     ctrl.markAsDirty();
        //     ctrl.markAsTouched();
        // }

        if (!ctrl.valid)
            Utils.validateAllFormFields(ctrl);
        // }

    }

    //attachments
    preview($event, control: FormControl | AbstractControl) {
        const tgt = $event.target || window.event.srcElement,
            files = Array.isArray($event) ? $event : tgt.files;

        if (FileReader && files && files.length) {
            const fr = new FileReader();
            fr.onload = () => {
                if (fr.result.toString().length <= 2 * 2 ** 20)
                    control.setValue(fr.result.toString());
                else
                    alert('file limit is 2 MB.');
            };
            fr.readAsDataURL(files[0]);
        }
        else {
            //console.error('not supported!');
        }
    }

    fileDropped(file, control: FormControl | AbstractControl): void {
        control.setValue(file.url);
    }

    removeImage(control: FormControl | AbstractControl) {
        control.reset();
        control.markAsTouched();
    }

    onSubmit() {
        //console.log(this.form.value);

        if (!this.availableSerials || this.availableSerials.length == 0)
            return;

        if (!this.form.valid)
            return Utils.validateAllFormFields(this.form);

        this.loader.load(LoaderType.Body);


        this.attachments.controls.forEach((grp, i) => {
            const _attachments = this.attachments.controls[i];
            for (let ctrl of Object.keys((<FormGroup>grp).controls))
                if (this.attachments.controls[i].get(ctrl).value instanceof Object)
                    this.attachments.controls[i].get(ctrl).setValue(this.attachments.controls[i].get(ctrl).value.changingThisBreaksApplicationSecurity)
        });
        // console.log(this.attachments);

        //update checkboxes value
        this.vOptions.controls.forEach((ctrl, i) => {
            ctrl.setValue(this.vehicleOptions[i].key);
        });

        const cmd = new PostTripTikCmd(this.form.value);
        //console.log(cmd);

        if (!this.updateMode) {
            cmd.id = null;
            if (this.auth.currentUser.isInRole(Role.TRIPTIKS_CREATE))
                this.tripTiksService.create(cmd)
                    .then(id => {
                        if (id) {
                            this.alert.success(DefaultAlertAction.ADDING);
                            setTimeout(() => {
                                this.router.navigate(['/', this.translate.currentLang, 'issuing', 'trip-tiks', 'view', id])
                            }, 1000);
                        } else this.alert.failure(DefaultAlertAction.ADDING);
                    })
                    .finally(() => this.loader.load(LoaderType.Body, false));
        }
        else {
            cmd.id = this.dto.id;
            if (this.auth.currentUser.isInRole(Role.TRIPTIKS_UPDATE))
                this.tripTiksService.update(cmd)
                    .then(result => {
                        if (result) {
                            this.alert.success(DefaultAlertAction.UPDATING);

                            setTimeout(() => {
                                this.router.navigate(['/', this.translate.currentLang, 'issuing', 'trip-tiks', 'view', cmd.id])
                            }, 1000);

                        } else this.alert.failure(DefaultAlertAction.UPDATING);
                    })
                    .finally(() => this.loader.load(LoaderType.Body, false));
        }
    }

    private formInit() {
        this.form = this.fb.group({
            stockTypeId: ['', [Validators.required]],
            serial: ['', [Validators.required]],
            steps: this.fb.array([
                this.fb.group({
                    name: ['', [Validators.required, Validators.maxLength(35)]],
                    residenceCountryId: [null, [Validators.required]],
                    nationalityId: [null, [Validators.required]],
                    identityID: ['', [Validators.required, passportIdOrIdentityIdValidator, Validators.minLength(6), Validators.maxLength(15)]],
                    // _identityID: [''],
                    passportID: ['', [Validators.required, passportIdOrIdentityIdValidator, Validators.minLength(6), Validators.maxLength(15)]],
                    // _passportID: [''],
                    localAddress: ['', [Validators.required, addressValidator]],
                    localPhoneNumber: ['', [Validators.required, phoneNumberValidator]],
                    intAddress: ['', [Validators.required, addressValidator]],
                    intPhoneNumber: ['', [Validators.required, phoneNumberValidator]],
                    drivers: this.fb.array([])
                }),
                this.fb.group({
                    registrationCountryId: [null, [Validators.required]],
                    plateID: ['', [Validators.required, Validators.maxLength(12)]],
                    makeId: [null, [Validators.required]],
                    modelId: [null, [Validators.required]],
                    typeId: ['', [Validators.required]],
                    seatsCount: ['', [Validators.required, vehicleSeatsCountRangeValidator]],
                    manufactureYear: ['', [Validators.required, vehicleManufactureYearRangeValidator]],
                    weightInKG: ['', [Validators.required, vehicleWeightInKGRangeValidator]],
                    cylindersCount: ['', [Validators.required, vehicleCylindersCountRangeValidator]],
                    horsePower: ['', [/*Validators.required, */vehicleHorsePowerRangeValidator]],
                    //colorId: ['', [Validators.required]],
                    colors: ['', [Validators.required]],
                    upholsteryType: ['', [/*Validators.required*/]],
                    engineID: ['', [nonArabicCharactersValidator]], //todo: regex
                    chassisID: ['', [Validators.required, nonArabicCharactersValidator]], //todo: regex
                    options: this.fb.array([]),
                    spareTiresCount: [0],
                    equipments: [''],
                    extras: [''],
                    value: ['', [Validators.required, vehicleValueRangeValidator]],
                    currencyId: ['', [Validators.required]]
                }),
                this.fb.group({
                    attachments: this.fb.array([
                        this.fb.group({
                            identityOrPassport: ['', [Validators.required]],
                            drivingLicense: ['', [Validators.required]],
                            vehicleRegistration: ['', [Validators.required]]
                        })
                    ])
                })
            ])
        })
    }

    private driverFormInit(data?: DriverForm) {
        this.drivers.push(this.fb.group({
            name: [data ? data.name : '', [Validators.required, Validators.maxLength(35)]],
            // _passportIDorIdentityID: this.fb.group({
            //     ownerID: ['', [Validators.required, Validators.minLength(6)]],  //todo: regex for password pattern
            //     passwordConfirm: ['']
            // }, { validator: comparePasswords }),
            // passportIDorIdentityID: [data ? data.passportIDorIdentityID : '', [Validators.required, passportIdOrIdentityIdValidator, Validators.minLength(6), Validators.maxLength(15)]],
            passportIDorIdentityID: [data ? data.passportIDorIdentityID : '', [Validators.required, passportIdOrIdentityIdValidator, _passportIdOrIdentityIdValidator.bind(this, this.cPassportID, this.cIdentityID), Validators.minLength(6), Validators.maxLength(15)]],
            address: [data ? data.address : '', [Validators.required, addressValidator]],
            phoneNumber: [data ? data.phoneNumber : '', [Validators.required, phoneNumberValidator]]
        }));
        this.attachments.push(this.fb.group({
            identityOrPassport: [data && data.attachments ? data.attachments[0] : '', [Validators.required]],
            authorization: [data && data.attachments ? data.attachments[1] : '', [Validators.required]]
        }));
    }
}

interface DriverForm {
    name: string,
    passportIDorIdentityID: string,
    address: string,
    phoneNumber: string,
    attachments: string[]
}