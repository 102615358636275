import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AvailableStock, AvailableStocksVm } from '../../models/available-stocks';
import { StockSliderComponent } from '../../components/stock-slider/stock-slider.component';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { StocksService } from '../../services/stocks.service';
import { TryUndoCmd } from '../../models/try-undo.cmd';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Language } from 'src/app/core/models/enums/language';
import { LookupDto } from 'src/app/features/lookups/models/lookup';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { FormBuilder, FormGroup, NgForm, FormArray } from '@angular/forms';
import { Utils } from 'src/app/shared/services/utils';
//import { StockType } from 'src/app/core/models/enums/stock-type';

@Component({
  selector: 'mac-undo-try',
  templateUrl: './undo-try.component.html',
  styleUrls: ['./undo-try.component.scss'],
  providers: [StockTypesService, StocksService]
})
export class UndoTryComponent implements OnInit {
  selectedStock: number[];
  availableStock: AvailableStock;
  stockTypes: StockTypeDto[];
  stockType: StockTypeDto = { id: 0, titles: ['', ''] };
  customReason: any;
  reason: LookupDto;
  reasons: LookupDto[] = [
    { id: 1, titles: ['تالف', 'Destroyed'] },
    { id: 2, titles: ['عيوب طباعة', 'Printing Issues'] },
    { id: 0, titles: ['آخر', 'Other'] }
  ];
  lang: Language;

  @ViewChild(StockSliderComponent) slider: StockSliderComponent;
  @ViewChild('f', { static: true }) form: NgForm;

  get _customReason() { return this.form.controls.customReason }
  get _reason() { return this.form.controls.reason }

  constructor(private loader: LoadingService,
    private alert: AlertService,
    private router: Router,
    private stockTypesService: StockTypesService,
    private translate: TranslateService,
    private stocksService: StocksService,
    public auth: AuthService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);
    this.stockTypesService.getAllowed(this.auth.currentUser.accountId)
      .then(result => {
        //this.stockTypes = result.list.filter(x => x.id == StockType.License);
        this.stockTypes = result.list;
        if (this.stockTypes.length == 1) {
          this.stockType = this.stockTypes[0];
          this.onSelectStockType()
        }
      } /*this.stockTypes = result.list*/)
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //   //console.log(this.form.controls);
    //   //console.log(this.form.form.controls);
    //   //console.log(Object.keys(this.form.controls))
    //   //console.log(this.form.controls.customReason);
    // }, 500);


  }
  reset() {
    //this.fromAccountId = this.fromAccounts[0].id;
    //this.toAccountId = this.toAccounts[0].id;
    this.stockType = { id: 0, titles: [] };
    this.selectedStock = null;
    this._reason.reset();
    this._customReason.reset();
  }

  onSelectStockType() {
    this.selectedStock = null;
    this.loadAvailableStock();
  }

  onSelectRange() {
    setTimeout(() => {
      this.slider.transactionDetails.alertClass = 'primary';
      this.slider.transactionDetails.operation = 'STK_TITLE_UNDO';
      this.slider.transactionDetails.stockType = this.stockType.titles[0];
      this.slider.onSelectRange(this.selectedStock);
    });
  }

  onSubmit() {
    if (this.reason && this.reason.id > 0)
      this._customReason.setErrors(null);

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form.form);

    this.loader.load(LoaderType.Body);
    const cmd: TryUndoCmd = {
      stockTypeId: this.stockType.id,
      fromRange: this.slider.fromRange,
      toRange: this.slider.toRange,
      reason: this.reason && this.reason.id > 0 ? this.reason.titles[this.lang] : this._customReason.value
      //cancel: false   //todo: include an undo try cancel request,
    }
    this.stocksService.tryUndo(cmd)
      .then(result => {
        result ?
          this.alert.success(!this.auth.currentUser.isSystemAccount ? '_ACTION_REQUESTING' : 'STK_TITLE_UNDOING')
          : this.alert.failure(!this.auth.currentUser.isSystemAccount ? '_ACTION_REQUESTING' : 'STK_TITLE_UNDOING');

        if (result)
          this.router.navigate(['/', this.translate.currentLang, 'stocks', 'transactions']);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  private loadAvailableStock() {
    this.loader.load(LoaderType.Body);
    this.stocksService
      .getAvailableStocks(this.stockType.id)
      .then(data => this.renderAvailableStock(data))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  private renderAvailableStock(vm: AvailableStocksVm) {
    this.availableStock = new AvailableStock();
    this.availableStock.singles = [];
    this.availableStock.ranges = [];

    vm.stockTypesAvailableStocks[0].availableStock.forEach(s => {
      s.length > 1 ? this.availableStock.ranges.push(s)
        : this.availableStock.singles.push(s);
    });
  }
}
