export class AvailableStock {
    constructor() {
        this.singles = [];
        this.ranges = [];
    }
    singles: number[][];
    ranges: number[][];
}

export class AvailableStocksVm {
    stockTypesAvailableStocks: StockTypeAvailableStocksDto[]
}

export class StockTypeAvailableStocksDto {
    availableStock: number[][];
    //unavailableStock: number[][];
    stockType: {
        id: number;
        titles: string[];
    }
}