import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { IntLicensesService } from './int-licenses.service';
import { IntLicenseDto, IntLicensesVm, IntLicensesSearchQuery } from '../models/int-license';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { PaginationQuery } from '../../_shared/models/issuing.models';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';


export class IntLicensesDataSource implements DataSource<IntLicenseDto> {
    // add variables to hold the data and number of total records retrieved asynchronously
    // BehaviourSubject type is used for this purpose
    public IntLicensesSubject = new BehaviorSubject<IntLicenseDto[]>([]);

    // to show the total number of records
    public countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private intLicensesService: IntLicensesService, private loader: LoadingService) { }

    loadIntLicenses(pagination: PaginationQuery, query: IntLicensesSearchQuery | string = null) {
        ////console.warn(query);
        // //console.warn(query instanceof IntLicensesSearchQuery);

        //this.intLicensesService.search(pagination, query)
        this.intLicensesService[(query == null || typeof query == 'string') ? 'getLicenses' : 'search'](pagination, <any>query)
            .pipe(
                catchError(() => of([])),
                finalize(() => { })
            )
            .subscribe((result: IntLicensesVm) => {
                //console.log(result);
                result.list.forEach(document => {
                    document.parentAccounts = populateAccountsSelect([document.parentAccounts])[0];
                });
                this.IntLicensesSubject.next(result.list);
                this.countSubject.next(result.recordsCount);
                this.loader.load(LoaderType.Nav, false);
                this.loader.load(LoaderType.Body, false);
            });
    }

    connect(): Observable<IntLicenseDto[]> {
        return this.IntLicensesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.IntLicensesSubject.complete();
        this.countSubject.complete();
    }
}
