export class UndoRequestsVm {
    undoRequests: [];
    recordsCount: number;
}

export class UndoRequestDto {
    transactionId: number;
    fromAccountId: number;
    fromAccountTitles: string[];
    toAccountId: number;
    toAccountTitles: string[];
    username: string;
    fromRange: number;
    toRange: number;
    undoRequestStatus: UndoRequestStatus
    stockType: {
        id: number;
        titles: string[];
    }
    createdBy: string;
    created: Date;
    reason: string;
}

export enum UndoRequestStatus {
    Rejected = -2,
    Removed = -1,
    New = 0,
    Approved = 1,
}

export function undoRequestStatusLocale(status: UndoRequestStatus) {

    let _key = "_LABEL_UNDO_REQUEST_STATUS_";

    switch (status) {
        case UndoRequestStatus.Rejected:
            return `${_key}REJECTED`;

        case UndoRequestStatus.New:
            return `${_key}NEW`;

        case UndoRequestStatus.Approved:
            return `${_key}APPROVED`;

        case UndoRequestStatus.Removed:
            return `${_key}REMOVED`;

        default:
            return "ERROR";
    }
}