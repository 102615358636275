//import { AccountTypeDto } from 'src/app/core/nswag.api';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { AccountTypesService } from '../../services/account-types';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal } from 'src/app/shared/models/modal';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { nonArabicCharactersValidator, nonEnglishCharactersValidator, standardLocalizedControlValidators } from 'src/app/shared/services/custom-validators';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { AccountTypeDto } from '../../models/account-type';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { Utils } from 'src/app/shared/services/utils';

@Component({
  selector: 'mac-account-types',
  templateUrl: './account-types.component.html',
  styleUrls: ['./account-types.component.scss'],
  providers: [AccountTypesService]
})
export class AccountTypesComponent implements OnInit {

  displayedColumns: string[] = ['accountType', 'titles', 'tools'];
  dataSource: MatTableDataSource<AccountTypeDto>;
  editingRowId: number = null;
  deletingRowId: number = null;
  model: AccountTypeDto;
  submitted: boolean;
  accountTypes: AccountTypeDto[];
  loading: boolean;
  form: FormGroup;
  langIndex: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get titles() { return this.form.get('titles') as FormArray; }
  get parentId() { return this.form.get('parentId'); }
  get Role() { return Role; };

  constructor(private service: AccountTypesService,
    public auth: AuthService,
    private alert: AlertService,
    private loader: LoadingService,
    private confirm: ConfirmService,
    private fb: FormBuilder,
    private translate: TranslateService) { }

  ngOnInit() {
    this.langIndex = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.langIndex = t.lang == 'ar' ? Language.Arabic : Language.English);
    this.loader.load(LoaderType.Nav);
    this.formInit();
    this.service.get(null)
      .then(result => {
        this.accountTypes = [...result.list];
        ////console.log(this.accountTypes);
        this.dataSource = new MatTableDataSource(result.list);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  add() {
    if (this.dataSource.data.findIndex(i => i.id == 0) > -1)
      return;

    const maxId = this.accountTypes[this.accountTypes.length - 1].id;
    ////console.log(maxId);

    this.model = { id: 0, parentId: maxId, titles: ['', ''] };
    this.dataSource.data.unshift(this.model);

    this.form.patchValue({ titles: ['', ''] });

    this.editingRowId = 0;

    this.parentId.reset();
    this.titles.controls.forEach(c => c.reset());

    this.parentId.setValue(maxId);

    this.deletingRowId = null;
    this.dataSource.filter = "";
    this.paginator.firstPage();
  }

  tryDelete(row: AccountTypeDto) {
    let modal = this.confirm.confirm(new DeleteModal({ type: 'LKP_TITLE_ACCOUNT_TYPE', title: row.titles[this.langIndex] }));
    modal.then(m => {
      if (m.indexOf('CONFIRMED') > -1) {
        //delete handler
        this.delete(row.id);
      }
    });
  }

  delete(id) {
    this.loader.load(LoaderType.Body);
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = null;
    this.deletingRowId = id;

    this.service.delete(id).then(status => {
      if (status) {
        const i = this.dataSource.data.findIndex(o => o.id == id);
        this.dataSource.data.splice(i, 1);
        this.dataSource.filter = "";
        this.alert.success(DefaultAlertAction.DELETING);
      } else this.alert.failure(DefaultAlertAction.DELETING);
    }).catch(e => {
      ////console.log(e);
      //this.alert.error();
    }).finally(() => {
      this.deletingRowId = null;
      this.loader.load(LoaderType.Body, false);
    });
  }

  edit(id) {
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = id;
    this.model = id > 0 ? [...this.dataSource.data].find(c => c.id == id) : null;

    this.form.patchValue({
      titles: id > 0 ? this.model.titles : ['', ''],
      parentId: id > 0 ? this.model.parentId : ''
    });
  }

  onSubmit() {
    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);
    if (this.deletingRowId == null) {
      if (this.editingRowId == 0) {
        this.service.create(this.form.value).then(id => {
          if (id) {
            this.model.id = <number>id;
            this.model.parentTitles = this.accountTypes.find(a => a.id == this.model.parentId).parentTitles;

            this.dataSource.data[0].titles = this.form.value.titles;
            this.dataSource.data[0].parentTitles = this.accountTypes.find(a => a.id == this.model.parentId).parentTitles;

            this.dataSource.filter = "";
            this.alert.success(DefaultAlertAction.ADDING);
          } else this.alert.failure(DefaultAlertAction.ADDING);
        }).catch(e => this.edit(null))
          .finally(() => {
            this.editingRowId = null;
            this.deletingRowId = null;
            this.loader.load(LoaderType.Body, false);
          })
      } else {
        const cmd = this.form.value as AccountTypeDto;
        cmd.id = this.editingRowId;
        this.service.update(cmd)
          .then(status => {
            if (status) {
              const i = this.dataSource.data.findIndex(i => i.id == this.editingRowId);
              this.dataSource.data[i].titles = this.form.value.titles;
              this.alert.success(DefaultAlertAction.UPDATING);
            } else this.alert.failure(DefaultAlertAction.UPDATING);
          })
          .finally(() => {
            this.editingRowId = null;
            this.loader.load(LoaderType.Body, false);
          })
      }
    }
  }

  applyFilter(e: any) {
    this.dataSource.filter = e.target.value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.edit(null);
  }

  private formInit() {
    this.form = this.fb.group({
      parentId: ['', [Validators.required]],
      titles: this.fb.array([
        ['', standardLocalizedControlValidators(Language.Arabic)],
        ['', standardLocalizedControlValidators(Language.English)]
      ])
    });
  }

}
