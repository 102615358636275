import { TransactionType } from 'src/app/core/models/enums/transaction-type';

export function transactionTypeLocale(type: TransactionType) {
    switch (type) {
        case TransactionType.Depopulate:
            return `STK_TITLE_DEPOPULATE`;

        case TransactionType.Return:
            return `STK_TITLE_UNDO`;

        case TransactionType.Issue:
            return `STK_LABEL_TRANSACTION_TYPE_ISSUE`;

        case TransactionType.Transfer:
            return `STK_TITLE_TRANSACT`;

        case TransactionType.Populate:
            return `STK_TITLE_POPULATE`;

        default:
            return "ERROR";
    }
}
