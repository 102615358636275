import { StockTypesService } from './../../services/stock-types';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal } from 'src/app/shared/models/modal';
import { Language } from 'src/app/core/models/enums/language';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { nonArabicCharactersValidator, nonEnglishCharactersValidator, standardLocalizedControlValidators } from 'src/app/shared/services/custom-validators';
import { Utils } from 'src/app/shared/services/utils';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { PostStockTypeCmd, StockTypeDto } from '../../models/stock-type';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { threadId } from 'worker_threads';

@Component({
  selector: 'mac-stock-types',
  templateUrl: './stock-types.component.html',
  styleUrls: ['./stock-types.component.scss'],
  providers: [StockTypesService]
})
export class StockTypesComponent implements OnInit {

  displayedColumns: string[] = ['titles', 'documentType', 'rangeMin', 'rangeMax', 'isUnifiedRelease', 'hexColor', 'tools'];
  dataSource: MatTableDataSource<StockTypeDto>;
  editingRowId: number = null;
  deletingRowId: number = null;
  model: StockTypeDto;
  submitted: boolean;
  loading: boolean;
  langIndex: number = 0;
  form: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  get Role() { return Role; };

  constructor(private service: StockTypesService,
    public auth: AuthService,
    private alert: AlertService,
    private loader: LoadingService,
    private confirmService: ConfirmService,
    private fb: FormBuilder,
    private translate: TranslateService) { }

  ngOnInit() {
    this.langIndex = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.langIndex = t.lang == 'ar' ? Language.Arabic : Language.English);
    this.loader.load(LoaderType.Nav);
    this.formInit();
    this.service.get(null)
      .then(result => {
        this.dataSource = new MatTableDataSource(result.list);

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  add() {
    if (this.dataSource.data.findIndex(i => i.id == 0) > -1)
      return;

    this.model = <StockTypeDto>{ id: 0, titles: ['', ''], documentType: null, rangeMin: null, rangeMax: null, isUnifiedRelease: false, hexColor: null };
    this.dataSource.data.unshift(this.model);

    this.form.patchValue({ titles: ['', ''], documentType: 0, rangeMin: null, rangeMax: null, isUnifiedRelease: false, hexColor: null });

    this.editingRowId = 0;

    this.titles.controls.forEach(c => c.reset());
    this.documentType.reset();
    this.rangeMin.reset();
    this.rangeMax.reset();
    this.isUnifiedRelease.reset();
    this.hexColor.reset();

    this.deletingRowId = null;
    this.dataSource.filter = "";
    this.paginator.firstPage();
  }

  tryDelete(row: StockTypeDto) {
    let modal = this.confirmService.confirm(new DeleteModal({ type: 'LKP_TITLE_STOCK_TYPE', title: row.titles[this.langIndex] }));
    modal.then(m => {
      if (m.indexOf('CONFIRMED') > -1)
        this.delete(row.id);
    });
  }

  delete(id) {
    this.loader.load(LoaderType.Body);
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = null;
    this.deletingRowId = id;

    this.service.delete(id).then(status => {
      if (status) {
        const i = this.dataSource.data.findIndex(o => o.id == id);
        this.dataSource.data.splice(i, 1);
        this.dataSource.filter = "";
        this.alert.success(DefaultAlertAction.DELETING);
      } else this.alert.failure(DefaultAlertAction.DELETING);
    })
      .finally(() => {
        this.deletingRowId = null;
        this.loader.load(LoaderType.Body, false);
      });
  }

  edit(id) {
    if (this.editingRowId == 0) {
      this.dataSource.data.shift();
      this.dataSource.filter = "";
    }
    this.editingRowId = id;
    this.model = id > 0 ? [...this.dataSource.data].find(c => c.id == id) : null;
    //console.log(this.model);

    this.form.patchValue({
      titles: id > 0 ? this.model.titles : ['', ''],
      documentType: id > 0 ? this.model.documentType : '',
      rangeMin: id > 0 ? this.model.rangeMin : '',
      rangeMax: id > 0 ? this.model.rangeMax : '',
      isUnifiedRelease: id > 0 ? this.model.isUnifiedRelease : false,
      hexColor: id > 0 ? this.model.hexColor : ''
    });
  }

  onSubmit() {
    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);

    const cmd = this.form.value as PostStockTypeCmd;
    //console.log(cmd);
    cmd.isUnifiedRelease = cmd.isUnifiedRelease == true;
    if (this.deletingRowId == null) {
      if (this.editingRowId == 0) {
        this.service.create(cmd).then(id => {
          if (id) {
            this.model.id = <number>id;
            this.model.titles = cmd.titles;

            this.model.documentType = cmd.documentType;
            this.model.isUnifiedRelease = cmd.isUnifiedRelease;
            this.model.rangeMin = cmd.rangeMin;
            this.model.rangeMax = cmd.rangeMax;
            this.model.hexColor = cmd.hexColor;

            this.alert.success(DefaultAlertAction.ADDING);
          } else this.alert.failure(DefaultAlertAction.ADDING);
        }).catch(e => this.edit(null))
          .finally(() => {
            this.loader.load(LoaderType.Body, false);
            this.editingRowId = null;
            this.deletingRowId = null;
            this.dataSource.filter = "";
          });
      } else {

        cmd.id = this.editingRowId;
        this.service.update(cmd).then(status => {
          if (status) {
            //     console.log(this.model);
            const i = this.dataSource.data.findIndex(i => i.id == this.editingRowId);
            //this.dataSource.data[i].titles = cmd.titles;
            this.dataSource.data[i].titles = cmd.titles;
            this.dataSource.data[i].documentType = cmd.documentType;
            this.dataSource.data[i].isUnifiedRelease = cmd.isUnifiedRelease;
            this.dataSource.data[i].rangeMin = cmd.rangeMin;
            this.dataSource.data[i].rangeMax = cmd.rangeMax;
            this.dataSource.data[i].hexColor = cmd.hexColor;
            // this.hexColor.setValue(cmd.hexColor);
            this.alert.success(DefaultAlertAction.UPDATING);
          } else this.alert.failure(DefaultAlertAction.UPDATING);
        }).finally(() => {
          this.editingRowId = null;
          this.loader.load(LoaderType.Body, false);
        });
      }
    }
  }

  applyFilter(e: any) {
    this.dataSource.filter = e.target.value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.edit(null);
  }

  get titles() { return this.form.get('titles') as FormArray; };
  get documentType() { return this.form.get('documentType'); };
  get isUnifiedRelease() { return this.form.get('isUnifiedRelease'); };
  get hexColor() { return this.form.get('hexColor'); };
  get rangeMin() { return this.form.get('rangeMin'); };
  get rangeMax() { return this.form.get('rangeMax'); };

  private formInit() {
    this.form = this.fb.group({
      titles: this.fb.array([
        ['', standardLocalizedControlValidators(Language.Arabic)],
        ['', standardLocalizedControlValidators(Language.English)]
      ]),
      documentType: ['', [Validators.required]],
      isUnifiedRelease: [false],
      hexColor: ['', [Validators.required]],
      rangeMin: ['', [Validators.required]],
      rangeMax: ['', [Validators.required]]
    });
  }
}