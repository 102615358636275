export enum DefaultAlertAction {
    ADDING = '_ACTION_ADDING',
    UPDATING = '_ACTION_UPDATING',
    DELETING = '_ACTION_DELETING',
    TRANSFERING = '_ACTION_TRANSFERRING',

    REVOKE_REQUESTING_CANCEL = '_ACTION_REVOKE_REQUESTING_CANCEL',
    REQUESTING = '_ACTION_REQUESTING',
    APPROVING = '_ACTION_APPROVING',
    REJECTING = '_ACTION_REJECTING'

}