import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
  selector: 'app-view-files-dialog',
  templateUrl: './view-files-dialog.component.html',
  styleUrls: ['./view-files-dialog.component.scss']
})
export class ViewFilesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewFilesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  generateDownloadLink(filePath: string): string {
    const fileName = filePath.split('\\').pop();
    return `https://gomrok-api.macidp.org/api/Upload/download?tripTikDocumentId=${this.data.tripTikDocumentId}&fileName=${encodeURIComponent(fileName)}`;
  }

  openImageDialog(file: string): void {
    const imageSrc = this.generateDownloadLink(file);
    this.dialog.open(ImageDialogComponent, {
      data: { imageSrc }
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
