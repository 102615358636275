import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import {
    DocumentValidityVm,
    TryRevokeDocumentCommand,
    ResolveTryRevokeDocumentCommand,
    PaginationQuery,
    DocumentsRevokeRequestsVm,
    IssuedDocumentsQuery
} from '../models/issuing.models';

@Injectable()
export class IssuingService {
    constructor(private api: ApiHandler) { }

    verify(key: string) {
        return this.api.get<DocumentValidityVm>(EndPoint.ISSUING_VERIFY, key).toPromise();
    }

    // searchForExpiredDocument(stockTypeId: number, serial: number, passportIDorIdentityID: string) {
    //     //search/{stockTypeId}/{serial}/{passportIDorIdentityID}
    //     return this.api.get<DocumentValidityVm>(EndPoint.ISSUING_EXPIRED_DOCUMENTS_QUERY, [stockTypeId, serial, passportIDorIdentityID]).toPromise();
    // }


    searchForIssuedDocument(stockTypeId: number, serial: number, query: IssuedDocumentsQuery) {
        return this.api.get<DocumentValidityVm>(EndPoint.ISSUING_ISSUED_DOCUMENTS_QUERY, [stockTypeId, serial], { ...query }).toPromise();
    }


    tryRevokeDocument(cmd: TryRevokeDocumentCommand) {
        return this.api.create<TryRevokeDocumentCommand, number>(EndPoint.ISSUING_REVOKE_TRY, cmd).toPromise();
    }

    cancelTryRevokeDocument(id: number) {
        return this.api.create<{}, boolean>(EndPoint.ISSUING_REVOKE_CANCEL, { id }).toPromise();
    }

    resolveTryRevokeDocument(cmd: ResolveTryRevokeDocumentCommand) {
        return this.api.create<ResolveTryRevokeDocumentCommand, boolean>(EndPoint.ISSUING_REVOKE_RESOLVE, cmd).toPromise();
    }

    getDocumentsRevokeRequests(pagination: PaginationQuery, stockTypeId = 0, query: string = '') {
        return this.api.get<DocumentsRevokeRequestsVm>(EndPoint.ISSUING_REVOKE_REQUESTS, [stockTypeId], { ...pagination, query });
    }

    cancelDocument(id: string) {
        return this.api.delete(EndPoint.ISSUING_DOCUMENT_CANCEL, id).toPromise();
    }
}