import { Injectable, TemplateRef } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface ConfirmOptions {
    confirmType?: 'update' | 'delete' | 'form' | 'display',// string,  //delete, update
    type: string,
    title: string
    content?: string;
}

export interface IModalOptions {
    action?: string,
    type: string,
    confirmQuestion?: string,
    title?: string,
    note?: string,
    dangerNote?: string,
    submitClass?: string,
    submitText?: string,
    cancelText?: string,
    inputNote? : string,
    requestType?: boolean,
    innerHTML?: string,
    attachment?: string,
    
}

export class DeleteModal implements IModalOptions {
    constructor(data: IModalOptions) {
        for (var property in data) {
            if (data.hasOwnProperty(property))
                (<any>this)[property] = (<any>data)[property];
        }

        this.action = this.action ? this.action : '_ACTION_DELETE';
        this.confirmQuestion = this.confirmQuestion ? this.confirmQuestion : '_CONFIRM_DELETE_CONFIRMATION_QUESTION';
        this.note = this.note ? this.note : '_CONFIRM_DELETE_NOTE';
        this.dangerNote = this.dangerNote ? this.dangerNote : '_CONFIRM_DELETE_DANGER_NOTE';
        this.submitClass = this.submitClass ? this.submitClass : 'btn-danger';
        this.submitText = this.submitText ? this.submitText : '_ACTION_DELETE';
        this.cancelText = this.cancelText ? this.cancelText : '_ACTION_CANCEL';
    }
    action?: string;
    type: string;
    confirmQuestion?: string;
    title: string;
    note?: string;
    dangerNote?: string;
    submitClass?: string;
    submitText?: string;
    cancelText?: string;
    inputNote? : string;
    attachment?: string;
    requestType?: boolean;
    innerHTML?: string;
}

// export interface IModal {
//     header: { action: string; type?: string; };
//     body: { confirmQuestion: string; title?: string; note: string; dangerNote: string; };
//     footer: { submitClass: string; submitText: string; cancelText: string; };
// }


export class UpdateModal implements IModalOptions {
    constructor(data: IModalOptions) {
        for (var property in data) {
            if (data.hasOwnProperty(property))
                (<any>this)[property] = (<any>data)[property];
        }

        this.action = this.action ? this.action : '_ACTION_UPDATE';
        this.confirmQuestion = this.confirmQuestion ? this.confirmQuestion : '_CONFIRM_UPDATE_CONFIRMATION_QUESTION';
        this.note = this.note ? this.note : '_CONFIRM_UPDATE_NOTE';
        this.dangerNote = this.dangerNote ? this.dangerNote : '_CONFIRM_UPDATE_DANGER_NOTE';
        this.submitClass = this.submitClass ? this.submitClass : 'btn-warning';
        this.submitText = this.submitText ? this.submitText : '_ACTION_UPDATE';
        this.cancelText = this.cancelText ? this.cancelText : '_ACTION_CANCEL';
        
        
    }
    action?: string;
    type: string;
    confirmQuestion?: string;
    title: string;
    note?: string;
    dangerNote?: string;
    submitClass?: string;
    submitText?: string;
    cancelText?: string;
    inputNote? : string;
    innerHTML?: string;
    
    
    
}


export class DisplayModal implements IModalOptions {
    constructor(data: IModalOptions) {
        for (var property in data) {
            if (data.hasOwnProperty(property))
                (<any>this)[property] = (<any>data)[property];
        }

        this.action = this.action ? this.action : '_ACTION_VIEW';
        //this.confirmQuestion = this.confirmQuestion ? this.confirmQuestion : '_CONFIRM_UPDATE_CONFIRMATION_QUESTION';
        this.note = this.note ? this.note : '_CONFIRM_UPDATE_NOTE';
        //this.dangerNote = this.dangerNote ? this.dangerNote : '_CONFIRM_UPDATE_DANGER_NOTE';
        //this.submitClass = this.submitClass ? this.submitClass : 'btn-warning';
        //this.submitText = this.submitText ? this.submitText : '_ACTION_UPDATE';
        this.cancelText = this.cancelText ? this.cancelText : '_ACTION_CANCEL';
    }
    action?: string;
    type: string;
    confirmQuestion?: string;
    title?: string;
    note?: string;
    innerHTML?: string;
    //dangerNote?: string;
    //submitClass?: string;
    //submitText?: string;
    cancelText?: string;
    //inputNote? : string;
}




// export class FormModal implements IModalOptions {
//     constructor(data: IModalOptions) {
//         for (var property in data) {
//             if (data.hasOwnProperty(property))
//                 (<any>this)[property] = (<any>data)[property];
//         }

//         this.action = this.action ? this.action : '_ACTION_UPDATE';
//         this.confirmQuestion = this.confirmQuestion ? this.confirmQuestion : '_CONFIRM_UPDATE_CONFIRMATION_QUESTION';
//         this.note = this.note ? this.note : '_CONFIRM_UPDATE_NOTE';
//         this.dangerNote = this.dangerNote ? this.dangerNote : '_CONFIRM_UPDATE_DANGER_NOTE';
//         this.submitClass = this.submitClass ? this.submitClass : 'btn-warning';
//         this.submitText = this.submitText ? this.submitText : '_ACTION_UPDATE';
//         this.cancelText = this.cancelText ? this.cancelText : '_ACTION_CANCEL';
//         this.inputNote = this.inputNote ? this.inputNote : '';
//     }
//     action?: string;
//     type: string;
//     confirmQuestion?: string;
//     title: string;
//     note?: string;
//     dangerNote?: string;
//     submitClass?: string;
//     submitText?: string;
//     cancelText?: string;
//     inputNote?: string;
// }



@Injectable()
export class ConfirmState {
    options: IModalOptions;
    modal: NgbModalRef;
    template: TemplateRef<any>;
    // updateTemplate: TemplateRef<any>;
    // deleteTemplate: TemplateRef<any>;
}