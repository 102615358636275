import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { merge, fromEvent } from 'rxjs';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AlertService } from 'src/app/shared/services/alert.service';
import { exportToExcel } from 'src/app/shared/helpers/export-to-excel';
import { UpdateModal } from 'src/app/shared/models/modal';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';
import { IssuingService } from '../../services/issuing.service';
import { undoRequestStatusLocale, UndoRequestStatus } from 'src/app/features/stock/models/undo-requests';
import { DocumentsRevokeRequestsDataSource } from '../../services/documents-revoke-requests.datasource';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DocumentRevokeRequestDto, ResolveTryRevokeDocumentCommand } from '../../models/issuing.models';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { MatSelect } from '@angular/material/select';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { Language } from 'src/app/core/models/enums/language';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
//import { StockType } from 'src/app/core/models/enums/stock-type';

@Component({
  selector: 'mac-documents-revoke-requests',
  templateUrl: './documents-revoke-requests.component.html',
  styleUrls: ['./documents-revoke-requests.component.scss'],
  providers: [IssuingService, StockTypesService, FileLoaderService]
})
export class DocumentsRevokeRequestsComponent implements OnInit {
  moment = moment;
  undoRequestStatusLocale = undoRequestStatusLocale;
  displayedColumns: string[] = ['created', 'serial', 'stockType', 'accountTitles', 'requestedBy', 'requestReason', 'undoRequestStatus', 'requestType', 'resolvedBy', 'resolved', 'resolveNote', 'tools'];
  dataSource: DocumentsRevokeRequestsDataSource;
  stockTypes: StockTypeDto[] = [];
  lang: Language;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild('filterBtn') filterBtn: ElementRef;
  @ViewChild(MatSelect, { static: true }) stockType: MatSelect;

  get Role() { return Role; };
  get UndoRequestStatus() { return UndoRequestStatus; };
  //get StockType() { return StockType; };
  get _DocumentType() { return _DocumentType; };



  constructor(private loader: LoadingService,
    private issuingService: IssuingService,
    private stockTypesService: StockTypesService,
    private alert: AlertService,
    private fileLoader: FileLoaderService,
    private translate: TranslateService,
    private confirmService: ConfirmService,
    public auth: AuthService) { }

  ngOnInit() {
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    if (!this.auth.currentUser.isInRole(Role.INT_LICENSES_REVOKE_REQUESTS_UPDATE)
      && !this.auth.currentUser.isInRole(Role.TRIPTIKS_REVOKE_REQUESTS_UPDATE))
      this.displayedColumns.splice(this.displayedColumns.length - 1, 1);

    //bug: will take a second to be loaded
    this.stockTypesService.get(this.lang).then(result => this.stockTypes = result.list);

    this.loader.load(LoaderType.Nav);
    this.sort.active = 'created';
    this.sort.direction = 'desc';
    this.paginator.pageSize = 10;
    this.dataSource = new DocumentsRevokeRequestsDataSource(this.issuingService, this.loader, this.fileLoader);
    this.loadDocumentsRevokeRequests();
  }

  ngAfterViewInit() {
    this.dataSource.counter$
      .pipe(
        tap((count) => {
          this.paginator.length = count;
        })
      ).subscribe();

    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap(() => this.loadDocumentsRevokeRequests())
      ).subscribe(() => this.loader.load(LoaderType.Body));

    merge(fromEvent(this.filter.nativeElement, 'search'), fromEvent(this.filterBtn.nativeElement, 'click'), this.stockType.valueChange)
      .pipe(
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadDocumentsRevokeRequests();
        })
      ).subscribe(() => this.loader.load(LoaderType.Body));
  }

  private loadDocumentsRevokeRequests() {
    this.dataSource.loadDocumentsRevokeRequests({ pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sort: this.sort.active, dir: this.sort.direction }, this.stockType.value, this.filter.nativeElement.value.trim());
  }

  getUndoRequestStatusClass(status: UndoRequestStatus) {
    switch (status) {
      case UndoRequestStatus.Rejected:
        return `text-danger`;

      case UndoRequestStatus.Approved:
        return `text-success`;

      default:
        return '';
    }
  }

  tryResolveRequest(row: DocumentRevokeRequestDto, approve: boolean = false) {
    if (row.stockType.documentType == _DocumentType.DrivingLicense && !this.auth.currentUser.isInRole(Role.INT_LICENSES_REVOKE_REQUESTS_UPDATE)
      || (row.stockType.documentType == _DocumentType.TripTikDocument && !this.auth.currentUser.isInRole(Role.TRIPTIKS_REVOKE_REQUESTS_UPDATE)))
      return;

    let modal = this.confirmService.confirm(new UpdateModal({
      type: 'ISS_TITLE_DOCUMENT_REVOKE_REQUEST',
      confirmQuestion: approve ? 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_UPDATE_STATUS_CONFIRMATION_QUESTION_APPROVE' : 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_UPDATE_STATUS_CONFIRMATION_QUESTION_REJECT',
      title: `${row.accountTitles[0]} / ${row.requestedBy}`,
      action: approve ? '_ACTION_APPROVE' : '_ACTION_REJECT',
      submitText: approve ? '_ACTION_APPROVE' : '_ACTION_REJECT',
      submitClass: approve ? 'btn-warning' : 'btn-danger',
      note: !row.isDamaged ? (approve ? 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_UPDATE_STATUS_APPROVE_NOTE' : 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_UPDATE_STATUS_REJECT_NOTE')
        : (approve ? 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_UPDATE_STATUS_ISDAMAGED_APPROVE_NOTE' : 'ISS_DOCUMENT_REVOKE_REQUEST_CONFIRM_UPDATE_STATUS_REJECT_NOTE'),
      dangerNote: approve ? '' : ' ',
      inputNote: 'ISS_LABEL_RESOLVE_NOTE'
    }));
    modal.then(m => {
      //console.log(m);
      if (m.indexOf('CONFIRMED') > -1)
        this.resolveRequest(row.id, approve, m[1].inputNote);
    });
  }

  onExportToExcel(all: boolean = true) {
    this.loader.load(LoaderType.Body);
    if (all)
      this.issuingService.getDocumentsRevokeRequests({ pageIndex: 0, pageSize: this.paginator.length, sort: this.sort.active, dir: this.sort.direction })
        .toPromise()
        .then(data => {
          this.exportToExcel(data.revokeRequests);
        }).finally(() => this.loader.load(LoaderType.Body, false));
    else
      this.dataSource.connect().subscribe(data => {
        this.exportToExcel(data);
        this.loader.load(LoaderType.Body, false);
      }).unsubscribe();
  }

  private resolveRequest(id: number, approve: boolean, note: string) {
    this.loader.load(LoaderType.Body);
    const cmd: ResolveTryRevokeDocumentCommand = {
      id: id,
      approve: approve,
      note: note
    }
    this.issuingService.resolveTryRevokeDocument(cmd)
      .then(result => {
        if (result) {
          this.alert.success(DefaultAlertAction.APPROVING)
          //console.log(this.auth.currentUser.username);
          const i = this.dataSource.revokeRequestsSubject.value.findIndex(i => i.id == id);
          this.dataSource.revokeRequestsSubject.value[i].undoRequestStatus = approve ? UndoRequestStatus.Approved : UndoRequestStatus.Rejected;
          this.dataSource.revokeRequestsSubject.value[i].resolvedBy = this.auth.currentUser.username;
          this.dataSource.revokeRequestsSubject.value[i].resolveNote = note;
          this.dataSource.revokeRequestsSubject.next([...this.dataSource.revokeRequestsSubject.value]);
        }
        else this.alert.failure(DefaultAlertAction.REJECTING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  private exportToExcel(data: DocumentRevokeRequestDto[]) {
    const wscols = [
      { width: 14 },
      { width: 24 },
      { width: 24 },
      { width: 14 },
      { width: 14 },
      { width: 14 },
      { width: 14 },
      { width: 14 },
      { width: 14 },
      { width: 14 },
      { width: 16 },
    ];
    const autofilter = { ref: 'A1:J1' };
    const rows: string[][] = [];

    const headers = [
      this.translate.instant('_LABEL_DATE'),
      this.translate.instant('_LABEL_SERIAL'),
      this.translate.instant('LKP_FIELD_STOCK_TYPE'),
      this.translate.instant('ACC_FIELD_ACCOUNT_TITLE'),
      this.translate.instant('ISS_LABEL_REQUESTED_BY'),
      this.translate.instant('_FIELD_REASON'),
      this.translate.instant('STK_LABEL_UNDO_REQUEST_STATUS'),
      this.translate.instant('ISS_LABEL_REQUEST_TYPE'),
      this.translate.instant('ISS_LABEL_RESOLVED_BY'),
      this.translate.instant('ISS_LABEL_RESOLVE_DATE'),
      this.translate.instant('ISS_LABEL_RESOLVE_NOTE')
    ];

    rows.push(headers);
    for (let row of data) {
      rows.push([
        moment(row.created).format('DD/MM/YYYY'),
        row.serial.toString(),
        row.stockType.titles[0],
        row.accountTitles[0],
        row.requestedBy,
        row.requestReason,
        this.translate.instant(undoRequestStatusLocale(row.undoRequestStatus)),
        this.translate.instant(row.isDamaged ? '_ACTION_DESTROY' : '_ACTION_RECOVER'),
        row.resolvedBy ? row.resolvedBy : '-',
        row.resolved ? moment(row.resolved).format('DD/MM/YYYY') : '-',
        row.resolveNote ? row.resolveNote : '-'
      ]);
    }

    exportToExcel(rows, wscols, autofilter, this.translate.currentLang == 'ar', 'Documents-Revoke-Requests');
  }
}