import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from '../upload.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {
  form: FormGroup;
  files: File[] = [];
  tripTikDocumentId: string;

  constructor(
    private fb: FormBuilder,
    private uploadService: UploadService,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.tripTikDocumentId = data.tripTikDocumentId;
    console.log('Received tripTikDocumentId:', this.tripTikDocumentId); // Debugging line
    this.form = this.fb.group({
      files: ['']
    });
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
  }

  uploadFiles() {
    this.uploadService.uploadFiles(this.files, this.tripTikDocumentId).subscribe(response => {
      this.snackBar.open('Files uploaded successfully', 'Close', {
        duration: 3000,
      });
      this.dialogRef.close('uploaded');
    }, error => {
      console.error(error);
      this.snackBar.open('Failed to upload files', 'Close', {
        duration: 3000,
      });
    });
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }
}
