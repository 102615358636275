import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  private apiUrl = 'https://gomrok-api.macidp.org/api/Documents'; // Replace with your actual API endpoint
  private apiUrl2 = 'https://gomrok-api.macidp.org/api/Upload';

  constructor(private http: HttpClient) { }

  getData(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl).pipe(
      map(response => response.$values) // Adjust this based on the actual response structure
    );
  }

  generateNweeba(id: string): Observable<Blob> {
    return this.http.get(`https://gomrok-api.macidp.org/api/Nweeba/generate/${id}`, { responseType: 'blob' });
  }

  generateImage(id: string): Observable<Blob> {
    return this.http.get(`https://gomrok-api.macidp.org/api/Image/generate/${id}`, { responseType: 'blob' });
  }
  checkFiles(tripTikDocumentId: string): Observable<{ exists: boolean, files?: string[] }> {
    return this.http.get<{ exists: boolean, files?: string[] }>(`${this.apiUrl2}/check?tripTikDocumentId=${tripTikDocumentId}`);
  }
  
}
