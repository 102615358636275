import { Injectable, EventEmitter } from '@angular/core';

Injectable()
export class BreadcrumbService {
    _links: EventEmitter<string[][]>;
    links: string[][];

    constructor(){
        this._links = new EventEmitter();
        this._links.subscribe(l => this.links = l);
    }

    public setLinks(links: string[][]){
        this._links.emit(links);
    }
}