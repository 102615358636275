import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
//import { VehicleColorDto, VehicleColorsVm } from 'src/app/core/nswag.api';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { LookupVm, LookupDto } from '../models/lookup';

@Injectable()
export class VehicleColorsService {
    constructor(private api: ApiHandler) { }

    get(lang: Language = null) {
        return this.api.get<LookupVm>(EndPoint.VEHICLE_COLORS, null, { lang }).toPromise();
    }

    create(dto: LookupDto) {
        return this.api.create<LookupDto, number>(EndPoint.VEHICLE_COLORS, dto).toPromise();
    }

    update(dto: LookupDto) {   
        return this.api.update(EndPoint.VEHICLE_COLORS, dto).toPromise();
    }

    delete(id: number) {                     
        return this.api.delete(EndPoint.VEHICLE_COLORS, id).toPromise();
    }
}