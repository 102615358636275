import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { CurrencyDto, CurrenciesVm } from '../models/currency';

@Injectable()
export class CurrenciesService {
    constructor(private api: ApiHandler) { }

    get(lang: Language = null) {
        return this.api.get<CurrenciesVm>(EndPoint.CURRENCIES, null, { lang }).toPromise();
    }

    create(dto: CurrencyDto) {
        return this.api.create<CurrencyDto, number>(EndPoint.CURRENCIES, dto).toPromise();
    }

    update(dto: CurrencyDto) {   
        return this.api.update(EndPoint.CURRENCIES, dto).toPromise();
    }

    delete(id: number) {                     
        return this.api.delete(EndPoint.CURRENCIES, id).toPromise();
    }
}