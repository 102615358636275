import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { PostTripTikCmd } from '../models/create.cmd';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { TripTikVm, TripTiksVm, TripTiksSearchQuery } from '../models/trip-tik';
import { PrintTripTikCmd } from '../models/print.cmd';
import { PaginationQuery } from '../../_shared/models/issuing.models';
import { SerialHashVm } from '../../int-licenses/models/int-license';

@Injectable()
export class TripTiksService {
    constructor(private api: ApiHandler) { }

    delete(id: string) {
        return this.api.delete(EndPoint.TRIPTIK, id).toPromise();
    }

    update(cmd: PostTripTikCmd) {
        return this.api.update(EndPoint.TRIPTIK, cmd).toPromise();
    }

    create(cmd: PostTripTikCmd) {
        return this.api.create<PostTripTikCmd, string>(EndPoint.TRIPTIK, cmd).toPromise();
    }

    get(id: string) {
        return this.api.get<TripTikVm>(EndPoint.TRIPTIK, id).toPromise();
    }

    getByHashedSerial(key: string){
        return this.api.get<TripTikVm>(EndPoint.TRIPTIK_GET_BY_HASHED_SERIAL, key).toPromise();
    }

    getAll(pagination: PaginationQuery, query: string = '') {
        return this.api.get<TripTiksVm>(EndPoint.TRIPTIK, null, { ...pagination, query });
    }


    search(pagination: PaginationQuery, query: TripTiksSearchQuery) {
        return this.api.get<TripTiksVm>(EndPoint.TRIPTIK_SEARCH, null, { ...pagination, ...query });
    }

    print(cmd: PrintTripTikCmd) {
        return this.api.create<PrintTripTikCmd, SerialHashVm>(EndPoint.TRIPTIK_PRINT, cmd).toPromise();
    }
}

