import { FormControl, AbstractControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  keyframes,

} from '@angular/animations';

import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'mac-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  animations: [
    trigger('bgImgTrigger', [
      transition('void => *', [
        animate('.25s', keyframes([
          style({ transform: 'translateY(-5px)', offset: 0 }),
          style({ transform: 'translateY(5px)', offset: 0.25 }),
          style({ transform: 'translateY(0px)', offset: 1 })
        ]))])
    ])
  ]
})
export class ValidationErrorComponent {
  @Input() control: FormControl | AbstractControl;
  @Input() fieldLocale: string;
  isOpen = false;
  state: string = 'none';


  animationState = true;
  loading = true;

  constructor(private validation: ValidationService) { }

  get errorMessage() {
    if (this.control) {
      this.animationState = true;
      this.loading = true;
      this.state = 'maximum';
      for (let propertyName in this.control.errors) {
        if (
          this.control.errors.hasOwnProperty(propertyName) &&
          this.control.touched
        ) {
          return this.validation.getValidatorErrorMessage(
            propertyName,
            this.control.errors[propertyName]
          );
        }
      }
    }

    else {
      this.animationState = false;
      this.loading = false;
      return null;
    }

  }

  isArray(obj: any) {
    return Array.isArray(obj)
  }
}
