import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mac-body-loader',
  templateUrl: './body-loader.component.html',
  styleUrls: ['./body-loader.component.scss']
})
export class BodyLoaderComponent implements OnInit {

  constructor() { }
  ngOnInit(): void {
  }

}
