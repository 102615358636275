import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mac-transaction-details-label',
  templateUrl: './transaction-details-label.component.html',
  styleUrls: ['./transaction-details-label.component.scss']
})
export class TransactionDetailsLabelComponent implements OnInit {

  @Input() details: any;
  constructor() { }

  ngOnInit(): void {
    //console.log(this.details);
  }

}
