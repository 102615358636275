export enum SystemObject {
    CITY,
    COUNTRY,
    VEHICLE_COLOR,
    VEHICLE_MAKE,
    STOCK_TYPE,
    ACCOUNT_STATUS,
    ACCOUNT_TYPE,
    ACCOUNT,
    USER,
    SECURITY_GROUP,
}