import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//import * as AppConfig from '../../../assets/config.json';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { environment } from 'src/environments/environment';

@Injectable()
export class FileLoaderService {
    unavailableFile: string = 'assets/images/default.jpg';
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }


    private sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    async loadAsync(url: string) {
        const _url = environment.apiBaseUrl + url;
        //const _url = 'http://api.dev.mac.org.sa' + url;

        try {
            const file = await this.http.get(_url, { responseType: 'blob' })
                .pipe(map(blob => { return this.convertToByteArray(blob) }))
                .toPromise();

            //if (file)
            return file;

            //throw new Error();
        } catch (error) {
            return null;
            // return this.unavailableFile;
        }
    }

    private convertToByteArray(file: Blob) {
        return new Promise<SafeUrl>((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = () => {
                const src = this.sanitize(fr.result.toString());
                resolve(src);
            };
            fr.readAsDataURL(file);
        });
    }
}