import { TranslateService } from '@ngx-translate/core';
import { DefaultAlertAction } from './../../../../shared/models/default-alert-action';
import { nonEnglishCharactersValidator, nonArabicCharactersValidator } from 'src/app/shared/services/custom-validators';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { SecurityGroupsVm, SecurityRolesVm } from './../../models/security-groups.model';
// import { SecurityService } from './../../services/security.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SecurityAction, ObjectActionDto, SecurityGroupDto } from '../../models/security-groups.model';
import { SecurityService } from '../../services/security.service';
import { ActivatedRoute, Router, NavigationExtras, Navigation } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { Location } from '@angular/common';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { Utils } from 'src/app/shared/services/utils';
import { DeleteModal } from 'src/app/shared/models/modal';
import { Role } from 'src/app/core/models/enums/role';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Language } from 'src/app/core/models/enums/language';

@Component({
    selector: 'mac-security-groups',
    templateUrl: './security-groups.component.html',
    styleUrls: ['./security-groups.component.scss'],
    providers: [SecurityService]
})
export class SecurityGroupsComponent implements OnInit {

    displayedColumns: any[] = []; // 'action-1'];//, 'action-2', 'action-3', 'action-4'];
    securityGroupForm: FormGroup;
    securityActions: SecurityAction[] = [];
    securityGroups: SecurityGroupDto[] = [];
    securityRoles: SecurityRolesVm = new SecurityRolesVm();
    selectedGroup: SecurityGroupDto;
    dataSource: MatTableDataSource<ObjectActionDto>;
    selections: SelectionModel<string>[] = [];
    formEnabled: boolean = true;
    objectsCol: {};
    mode: string;
    submitted: boolean;
    loading: boolean;
    navigation: Navigation;
    lang: number = 0;

    get titles() { return this.securityGroupForm.get('titles') as FormArray; }
    //get descriptions() { return this.securityGroupForm.get('descriptions') as FormArray; }
    get objectsActions() { return this.securityGroupForm.get('objectsActions') as FormArray; }
    get displayedColumnsTitles() { return this.displayedColumns.map(c => c.titles[this.lang]); }
    get Role() {
        return Role;
    }

    constructor(private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private alert: AlertService,
        private loader: LoadingService,
        private securityService: SecurityService,
        private location: Location,
        private confirmService: ConfirmService,
        private translate: TranslateService,
        public auth: AuthService) {

        this.navigation = this.router.getCurrentNavigation();
        this.mode = 'LIST';
        this.objectsCol = { id: 0, titles: ['الكائنات', 'Objects'] };
    }

    async ngOnInit() {
        this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
        this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);
        this.formInit();
        this.dataSource = new MatTableDataSource<ObjectActionDto>();
        this.loader.load(LoaderType.Nav);
        this.mode = this.route.snapshot.data.mode;

        this.securityRoles = await this.securityService.getAccountRoles();
        this.securityGroups = (await this.securityService.getAccountGroups(null)).groups;

        if (this.mode == 'LIST' || this.mode == 'UPDATE') {
            //this.securityService.getAccountGroups(null).then(vm => {
            //    console.log(vm);
            //   this.securityGroups = vm.groups;
            let groupId;
            if (this.mode == 'UPDATE') {
                groupId = this.route.snapshot.paramMap.get('id');
            }
            else if (this.navigation && this.navigation.extras.state) {
                const a = this.navigation.extras.state as { id: string };
                groupId = a.id;
            } else groupId = this.securityGroups[0].id;

            this.onSelectGroup(groupId);
            //})
            //.finally(() => this.loader.load(LoaderType.Nav, false));
        } else if (this.mode == 'ADD') {
            //   this.securityService.getAccountRoles().then(vm => {


            let _group = new SecurityGroupDto();
            _group.id = null;
            _group.titles = ['', ''];
            //_group.descriptions = ['', ''];
            _group.availableActions = this.securityRoles.availableActions;
            _group.objectsActions = this.securityRoles.objectsActions;
            _group.isSystemGroup = false;

            this.onSelectGroup(_group);
            this.mode = 'ADD';
            //this.location.replaceState(`/${this.translate.currentLang}/security/groups/add`);
            this.securityGroupForm.enable();
            this.formEnabled = true;


            //   this.selectedGroup.objectsActions.filter(x => x.securityObject.id == 1 && x.actions.indexOf(2) > -1)[0].
            //console.log(this.selectedGroup);

            // }).catch(e => {
            //     //   //console.log(e);
            //     //  this.alert.error('_MESSAGE_ERROR_LOADING_DATA');
            // }).finally(() => this.loader.load(LoaderType.Nav, false));
        } else this.loader.load(LoaderType.Nav, false);
    }

    private formInit() {
        this.securityGroupForm = this.fb.group({
            titles: this.fb.array([
                ['', [Validators.required, nonEnglishCharactersValidator, Validators.minLength(3), Validators.maxLength(50)]],
                ['', [Validators.required, nonArabicCharactersValidator, Validators.minLength(3), Validators.maxLength(50)]]
            ]),
            objectsActions: this.fb.array([])
        });
    }

    private addCheckboxes(objectsActions: ObjectActionDto[], empty: boolean = false) {
        this.clearSelections();
        let matrixIndex = 0;
        let formArray = (this.securityGroupForm.controls.objectsActions as FormArray);
        formArray.clear();
        objectsActions.forEach((objAct, i) => {
            objAct.actions.forEach((actionId, _i) => {
                const value = !empty && actionId != 0 ? objAct.securityObject.id + "," + actionId : null;
                const control = new FormControl(value);
                formArray.push(control);

                if (!empty && actionId != 0)
                    this.cellToggle(objAct, actionId, matrixIndex, false);

                matrixIndex++;
            });
        });
    }

    onSelectGroup(group: string | SecurityGroupDto, path: string = null) {
        //console.log(group);
        this.loader.load(LoaderType.Nav);
        this.selectedGroup = group instanceof SecurityGroupDto ?
            group :
            _.cloneDeep(this.securityGroups.find(g => g.id == group));

        //console.log(this.selectedGroup);

        this.dataSource = new MatTableDataSource<ObjectActionDto>(this.selectedGroup.objectsActions);
        this.securityActions = this.selectedGroup.availableActions;
        this.selections = [];
        this.securityActions.forEach(() => this.selections.push(new SelectionModel<string>(true, [])));
        this.displayedColumns = [this.objectsCol];
        this.displayedColumns.push(...this.securityActions);

        this.securityGroupForm.patchValue({
            titles: this.selectedGroup.titles,
            //descriptions: ['', '']
        });

        this.addCheckboxes(this.selectedGroup.objectsActions, group instanceof SecurityGroupDto && !group.id);

        this.securityGroupForm.disable();
        this.formEnabled = false;
        this.mode = 'LIST';
        if (path)
            this.location.replaceState(`/${this.translate.currentLang}/${path}`);

        //this.location.replaceState(`/${this.translate.currentLang}/security/groups/`);


        this.loader.load(LoaderType.Nav, false);
    }

    isColSelected(actionId: number) {
        const i = this.getActionIndex(actionId);

        const numSelected = this.selections[i].selected.length;
        const numRows = [...this.dataSource.data].filter(d => [...d.actions].map(x => Math.abs(x)).indexOf(actionId) > -1).length;

        return numSelected == numRows;
    }

    isRowSelected(row: ObjectActionDto, actionId: number) {
        const i = this.getActionIndex(actionId);
        return this.selections[i].isSelected(row.securityObject.id + "," + actionId);
    }

    colToggle(actionId: number) {
        const i = this.getActionIndex(actionId);
        const colSelected = this.isColSelected(actionId);

        this.dataSource.data
            .forEach((row, _i) => {
                ////console.warn(actionId);
                ////console.warn(row);
                if (row.actions[i] != 0) {
                    ////console.warn(row.actions[i]);
                    const value = row.securityObject.id + "," + actionId;
                    //const value = row.securityObject.id + "," + Math.abs(row.actions[i]);
                    const matrixIndex = (this.displayedColumns.length - 1) * _i + i;
                    if (!colSelected) {
                        if (!this.selections[i].isSelected(value)) {
                            this.cellToggle(row, actionId, matrixIndex);
                            this.selections[i].select(value);
                        }
                    } else {
                        this.cellToggle(row, actionId, matrixIndex);
                        this.selections[i].deselect(value);
                    }
                }
            });
    }

    cellToggle(row: ObjectActionDto, actionId: number, matrixIndex: number, event: boolean = true) {

        const i = this.getActionIndex(actionId);
        const value = row.securityObject.id + "," + this.securityActions[i].id;
        //const value = row.securityObject.id + "," + actionId;
        let control = (this.securityGroupForm.controls.objectsActions as FormArray).controls[matrixIndex];
        ////console.log(actionId, value);
        if (actionId < 0 && !event) {
            control.reset();
            this.selections[i].deselect(value);
        }
        else {
            if (!this.isRowSelected(row, this.securityActions[i].id)) {
                control.setValue(value);
                this.selections[i].select(value);
            } else {
                control.reset();
                this.selections[i].toggle(value);
            }
        }
    }

    getActionIndex(actionId: number) {
        return this.securityActions.findIndex(x => x.id == Math.abs(actionId));
    }

    //todo: include confirmation pop-up
    clearSelections() {
        //confirm first
        this.selections.forEach(s => s.clear());
    }

    totalSelected() {
        let total = 0;
        this.selections.forEach(s => {
            total += s.selected.length;
        })
        return total;
    }

    exit() {
        this.onSelectGroup(this.securityGroups[0].id, 'security/groups');
    }

    update() {


        let _group = new SecurityGroupDto();
        _group.id = this.selectedGroup.id;
        _group.titles = this.selectedGroup.titles;
        //_group.descriptions = ['', ''];
        _group.availableActions = this.securityRoles.availableActions;
        _group.objectsActions = this.securityRoles.objectsActions;
        _group.isSystemGroup = false;

        //console.log(this.securityRoles);
        //map
        _group.objectsActions.forEach((oa, i) => {
            // console.log(oa.securityObject.id);
            // console.log(this.selectedGroup.objectsActions[i]);
            if (this.selectedGroup.objectsActions.map(x => x.securityObject.id).indexOf(oa.securityObject.id) > -1)
                oa.actions = this.selectedGroup.objectsActions.filter(x => x.securityObject.id == oa.securityObject.id)[0].actions;
            else oa.actions = oa.actions.map(x => x > 0 ? -x : (x < 0 ? x : 0))
            // if (oa.securityObject.id == this.selectedGroup.objectsActions[i].securityObject.id)
            //     oa.actions = [...this.selectedGroup.objectsActions[i].actions];


        })
        //console.log(this.totalSelected())

        this.onSelectGroup(_group, 'security/groups/update/' + this.selectedGroup.id);

        //bind all roles,
        //fetch current data
        //this.location.replaceState(`/${this.translate.currentLang}/security/groups/update/${this.selectedGroup.id}`);
        this.mode = 'UPDATE';

        //this.objectsActions.controls.forEach(c => { c.enable(); console.log(c); });
        this.securityGroupForm.enable();
        this.formEnabled = true;

    }

    reset() {
        this.securityGroupForm.reset();
        this.clearSelections()
        // if (this.mode == 'ADD')
        //     this.router.navigate(['/', this.translate.currentLang, 'security', 'groups']);

        // //reset ?
        // this.location.replaceState(`/${this.translate.currentLang}/security/groups/list`);
        // this.onSelectGroup(this.selectedGroup.id);  //to refresh
    }

    delete(id: string) {
        //this._loading();
        this.loader.load(LoaderType.Body);
        this.securityService.deleteAccountGroup(id)
            .then(r => {
                //  //console.log(r);
                if (r) {
                    this.alert.success(DefaultAlertAction.DELETING);
                    const groupIndex = this.securityGroups.findIndex(g => g.id == id);
                    this.securityGroups.splice(groupIndex, 1);

                    //display the first group as a default option
                    this.onSelectGroup(this.securityGroups[0].id, 'security/groups');
                } else {
                    this.alert.failure(DefaultAlertAction.DELETING);
                }
            })
            .finally(() => {
                this.loader.load(LoaderType.Body, false);
            });
    }

    tryDelete() {
        let modal = this.confirmService.confirm(new DeleteModal({
            type: 'SEC_TITLE_SECURITY_GROUP',
            title: this.selectedGroup.titles[this.lang],
            note: 'SEC_GROUP_CONFIRM_DELETE_NOTE',
            dangerNote: 'SEC_GROUP_CONFIRM_DELETE_DANGER_NOTE'
        }));
        modal.then(m => {
            if (m.indexOf('CONFIRMED') > -1)
                this.delete(this.selectedGroup.id);
        });
    }

    onSubmit() {
        if (!this.securityGroupForm.valid)
            return Utils.validateAllFormFields(this.securityGroupForm);

        this.loader.load(LoaderType.Body);
        const [availableActions, objectsActions] = this.mapCheckboxes();

        //console.log(this.selectedGroup);
        const _form = _.cloneDeep(this.selectedGroup);

        _form.titles = this.securityGroupForm.controls['titles'].value;
        //_form.descriptions = this.securityGroupForm.controls['descriptions'].value;
        _form.availableActions = availableActions;
        _form.objectsActions = objectsActions;
        _form.isSystemGroup = false;

        //console.log(_form);
        if (this.mode == 'ADD') {
            _form.id = null;
            this.securityService.createAccountGroup(_form)
                .then(result => {
                    //console.log(result)
                    if (result != null) {
                        this.alert.success(DefaultAlertAction.ADDING);
                        const navigationExtras: NavigationExtras = { state: { id: result } };
                        this.router.navigate(['/', this.translate.currentLang, 'security', 'groups'], navigationExtras);
                    } else this.alert.failure(DefaultAlertAction.ADDING);
                })
                .finally(() => this.loader.load(LoaderType.Body, false));
        }
        else if (this.mode == 'UPDATE') {
            const isDelete = this.securityGroupForm.value.objectsActions.filter(oa => oa != null).length == 0;
            if (isDelete) {
                this.tryDelete();
            }
            else {
                //console.log(_form);
                this.securityService.updateAccountGroup(_form)
                    .then(r => {
                        if (r) {

                            this.alert.success(DefaultAlertAction.UPDATING);
                            const groupIndex = this.securityGroups.findIndex(g => g.id == _form.id);


                            //add recently saved version to cache
                            // console.log(this.securityGroups[groupIndex]);

                            // //map 'n/a' values 
                            // const __form = _.cloneDeep(_form);;

                            // __form.objectsActions.forEach(x)


                            this.securityGroups[groupIndex] = _.cloneDeep(_form);
                        } else {
                            this.alert.failure(DefaultAlertAction.UPDATING);
                        }
                    })
                    .finally(() => {
                        this.loader.load(LoaderType.Body, false);
                        this.onSelectGroup(this.selectedGroup.id, 'security/groups');
                    });
            }
        }

    }

    private mapCheckboxes(): [SecurityAction[], ObjectActionDto[]] {
        // console.log(this.securityGroupForm.controls.objectsActions);
        const formArr = this.securityGroupForm.controls.objectsActions as FormArray;
        const availableActions = _.cloneDeep(this.selectedGroup.availableActions);
        const objectsActions = _.cloneDeep(this.selectedGroup.objectsActions);

        // console.log(formArr);
        // console.log(availableActions);
        // console.log(objectsActions);

        let matrixIndex = 0;
        objectsActions.forEach(oa => {
            const _actions = [...oa.actions];
            //console.log(_actions);
            oa.actions = [];
            availableActions.forEach((action, i) => {
                let val = formArr.controls[matrixIndex++].value;

                //let _action = _actions[i] == 0 ? 0 : (val ? action.id : -action.id);

                // if (action.id == 2 && oa.securityObject.id == 1) {
                //     val = 'value';
                // }
                const _action = _actions[i] == 0 ? 0 : (val ? action.id : -action.id);
                //console.log(_action);

                oa.actions.push(_action);
            });

        });
        return [availableActions, objectsActions];
    }

    // private _loading(status: boolean = true) {
    //     this.loading = status;
    // }



    add() {
        this.router.navigate(['/', this.translate.currentLang, 'security', 'groups', 'add']);
        this.ngOnInit();
    }
    undo(mode: string) {
        if (mode == 'ADD')
            this.router.navigate(['/', this.translate.currentLang, 'security', 'groups', 'add']);
    }
}
