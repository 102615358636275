import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { IssuingsPerMonthVm } from '../models/statistics.model';

@Injectable()
export class StatisticsService {
    constructor(private api: ApiHandler) { }

    getIssuingsPerMonth() {
        return this.api.get<IssuingsPerMonthVm>(EndPoint.STATISTICS_ISSUINGS_PER_MONTH).toPromise();
    }
}

