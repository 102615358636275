import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntLicensesService } from '../../services/int-licenses.service';
import { IntLicenseVm, IntLicenseDetailsDto, IntLicenseDto } from '../../models/int-license';
import * as moment from 'moment';
import { Gender } from 'src/app/core/models/enums/gender';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/models/enums/language';
import * as _ from 'lodash';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { IntLicensePrintComponent } from '../../components/int-license-print/int-license-print.component';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { UpdateModal } from 'src/app/shared/models/modal';
import { Role } from 'src/app/core/models/enums/role';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CommitmentPrintComponent } from '../../components/commitment-print/commitment-print.component';
import { PrintLogDto } from '../../../_shared/models/issuing.models';
import { Utils } from 'src/app/shared/services/utils';
import { utils } from 'protractor';
import { StaticConfig } from 'src/app/features/lookups/services/config';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import { has } from 'lodash';

@Component({
  selector: 'mac-view-int-license',
  templateUrl: './view-int-license.component.html',
  styleUrls: ['./view-int-license.component.scss'],
  providers: [IntLicensesService, FileLoaderService]
})
export class ViewIntLicenseComponent implements OnInit {
  cashedVm: IntLicenseVm;
  dto: IntLicenseDetailsDto = new IntLicenseDetailsDto();
  moment = moment;
  lang: Language;
  dateFormat = 'L';
  qRCodeImg: string;
  qRCodeUrl: string = '';
  // _personalPhoto: string;
  // _passportPhoto: string;
  // _licensePhoto: string;
  confirmed: boolean;
  //_baseUrl = 'https://idl.mac.org.sa';
  maxPrintLimit: number;
  defaultImage = 'assets/images/default.jpg';

  @ViewChild('printElm') printElm: IntLicensePrintComponent;
  @ViewChild('comPrintElm') comPrintElm: CommitmentPrintComponent;

  get Gender() { return Gender };
  get PrintObject() { return PrintObject; };
  get Role() { return Role; };
  get Language() { return Language; };
  get Math() { return Math; };

  constructor(private route: ActivatedRoute,
    private intLicenseService: IntLicensesService,
    private loader: LoadingService,
    public translate: TranslateService,
    private fileLoader: FileLoaderService,
    private confirmService: ConfirmService,
    public auth: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    moment.locale('en-gb');
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.maxPrintLimit = Number(StaticConfig.getConfigKeyValuePairs(ConfigObject.IntLicenseMaxPrintLimit)[0].value);

    this.loader.load(LoaderType.Nav);
    const intLicenseId = this.route.snapshot.paramMap.get('id');
    if (intLicenseId) {
      this.intLicenseService.get(intLicenseId)
        .then(async result => {
          if (result.intLicense) {
            this.dto = result.intLicense;

            if (this.dto.issued) {
              this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);// `${this._baseUrl}/verify-license/${this.dto.hashedSerial}`;

              setTimeout(() => {
                this.qRCodeImg = document.getElementsByTagName('ngx-kjua')[0]
                  .getElementsByTagName('div')[0]
                  //.getElementsByTagName('img')[0].src;
                  .getElementsByTagName('canvas')[0].toDataURL("image/png");
              });
            }

            // await this.dto.attachments.forEach( att => {
            //   const file = await this.fileLoader.loadAsync(att);
            //   att = file ? file : this.defaultImage;
            //   ////console.log(att);
            // });

            this.dto.attachments[0] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[0] as string)).changingThisBreaksApplicationSecurity;
            this.dto.attachments[1] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[1] as string)).changingThisBreaksApplicationSecurity;
            this.dto.attachments[2] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[2] as string)).changingThisBreaksApplicationSecurity;

console.log(this.dto.documentTemplateVersion)

            // this.dto.attachments.forEach((att, i) => {
            //   //const _attachments = this.attachments.controls[i];
            //   //for (let ctrl of Object.keys((<FormGroup>grp).controls))
            //   // if (ctrl.value instanceof Object)
            //   this.fileLoader.loadAsync(att).then(r => {
            //     //console.log(r);
            //     this.dto.attachments[i] = (<any>r).changingThisBreaksApplicationSecurity;
            //    // console.log(this.dto.attachments);

            //   });//.changingThisBreaksApplicationSecurity;
            //   //  ctrl.setValue(ctrl.value.changingThisBreaksApplicationSecurity)
            // });


            this.displayPreview();
          } else this.router.navigate([`/${this.translate.currentLang}/not-found`]);
        })
        .finally(() => this.loader.load(LoaderType.Nav, false));
    }
  }
  getTranslatedTemplateVersion(version: number): string {
    switch (version) {
      case 1:
        return this.translate.instant('_LABEL_TEMPLATE_1');
      case 2:
        return this.translate.instant('_LABEL_TEMPLATE_2');
      // Add more cases as needed
      default:
        return '';
    }
  }
  isPrintable() {
    return !this.dto.issued
      || this.printObjectsCount(this.dto.printLogs, PrintObject.IntLicenseCard) < this.maxPrintLimit
      || this.printObjectsCount(this.dto.printLogs, PrintObject.IntLicenseSticker) < this.maxPrintLimit
  }

  isExpired(expiryDate) {
    return moment(expiryDate).isBefore(Date.now());
  }

  printObjectsCount(list: PrintLogDto[], printObject: PrintObject) {
    if (list)
      return list.filter(l => l.printObject == printObject).length;
  }

  print(printObject: PrintObject) {
    //console.log(printObject);
    if (printObject == PrintObject.IntLicenseCommitment) {
      //console.warn('try');
      this.comPrintElm.print(this.dto);
      return;

    }


    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    //confirm if not yet issued
    if (!this.dto.issued) {
      let modal = this.confirmService.confirm(new UpdateModal({
        title: this.dto.serial.toString(),
        type: 'LIC_TITLE_LICENSE',
        action: '_ACTION_ISSUE',
        confirmQuestion: 'LIC_CONFIRM_ISSUE_CONFIRMATION_QUESTION',
        note: 'LIC_CONFIRM_ISSUE_NOTE',
        dangerNote: 'LIC_CONFIRM_ISSUE_DANGER_NOTE',
        submitText: '_ACTION_ISSUE'
      }));

      modal.then(async m => {
        if (m.indexOf('CONFIRMED') > -1)
          await this._print(printObject);
      });
    } else this._print(printObject);
  }

  async _print(printObject: PrintObject) {

    //check if print limit allowed
    if (this.printObjectsCount(this.dto.printLogs, printObject) < this.maxPrintLimit) {
      this.loader.load(LoaderType.Body);
      //request to server print
      try {
        const hash = await this.intLicenseService.print({ id: this.dto.id, printObject });
        //console.log(hash);
        if (hash && hash.value) {
          //get new hash if just issued
          //calcualte issue date in FE
          //calculate expiry date in FE
          this.dto.hashedSerial = hash.value
          //this.qRCodeUrl = `${this._baseUrl}/verify-license/${this.dto.hashedSerial}`;
          this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);
          setTimeout(() => {
            this.qRCodeImg = document.getElementsByTagName('ngx-kjua')[0]
              .getElementsByTagName('div')[0]
              //.getElementsByTagName('img')[0].src;
              .getElementsByTagName('canvas')[0].toDataURL("image/png");
          });


          this.dto.issued = new Date();
          this.dto.expiryDate = moment(this.dto.issued).add(this.dto.validityYears, 'year').subtract(1, 'day').toDate();
        }

        //increment print objects
        this.dto.printLogs.push({ created: new Date(), printObject: printObject });

        //update ui
        await this.displayPreview();

        //open printing object
        await this.printElm.print(this.dto, printObject);

      }
      finally {
        this.loader.load(LoaderType.Body, false)
      }
    }
  }

  private async displayPreview() {
    const _issueDate = moment(this.dto.issued ? this.dto.issued : new Date()).format(this.dateFormat),
      _expiryDate = this.dto.issued ? moment(this.dto.expiryDate).format(this.dateFormat)
        : moment(new Date()).add(this.dto.validityYears, 'year').subtract(1, 'day').format(this.dateFormat);
    // _personalPhoto = await this.fileLoader.loadAsync(this.dto.personalPhoto);

    if (this.dto.documentTemplateVersion === 1) {
    let canvas1 = document.getElementById('lic-canvas') as HTMLCanvasElement;
    if (canvas1.getContext) {
      let ctx = canvas1.getContext('2d');

      const lic = new Image();

      lic.onload = () => {
        ctx.textAlign = 'left';
        ctx.font = 'bold 13px arial';
        ctx.drawImage(lic, 0, 0);

        let v = 75;
        const h = 210;
        const rh = 17.4;
        ctx.fillText(this.dto.name, h, v);
        ctx.fillText(moment(this.dto.dateOfBirth).format(this.dateFormat), h, v += rh);
        ctx.fillText(this.dto.nationalityTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.countryTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.passportID, h, v += rh);
        ctx.fillText(Gender[this.dto.gender], h, v += rh);
        ctx.fillText(this.dto.bloodGroup, h, v += rh);
        ctx.fillText(`${this.dto.accordingToTitles[Language.English]} License`, h, v += rh);
        ctx.fillText(this.dto.permitClasses.join(', '), h, v += rh);

        ctx.fillText(_issueDate, 85, 49);

        ctx.font = 'bold 20px arial';
        ctx.fillText(Math.abs(this.dto.serial).toString(), 50, 29);

        let photo = new Image();
        photo.onload = () => {
          let x = 20, y = 66, width = 98, height = 133, radius = 5;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
          ctx.closePath();
          ctx.clip();

          ctx.drawImage(photo, x, y, width, height);
          ctx.restore();

          const qr = new Image();
          qr.onload = () => {
            ctx.drawImage(qr, 319, 92.4, 88, 88);
          }
          qr.src = this.dto.issued ? this.qRCodeImg : 'assets/images/qr.png';

          const ptt = new Image();
          ptt.onload = () => {

            ctx.save();
            ctx.globalAlpha = 0.25;
            const ptrn = ctx.createPattern(ptt, 'repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0, 0, 425, 265);
            ctx.restore();

            //draw thumb
            let canvas1Thumb = document.getElementById('lic-canvas-thumb') as HTMLCanvasElement;
            let thumbCtx = canvas1Thumb.getContext('2d');
            //thumbCtx.scale(0.2, 0.2);
            thumbCtx.drawImage(ctx.canvas, 0, 0);
          }
          ptt.src = 'assets/images/sample-stamp.png';
        }
        photo.src = this.dto.attachments[0] as string;
        //photo.src = (<any>this.dto.attachments[0]).changingThisBreaksApplicationSecurity;
      }
      lic.src = "assets/images/license-empty.png";
    }
  } 
  else if (this.dto.documentTemplateVersion === 2) {
    let canvas1 = document.getElementById('lic-canvas') as HTMLCanvasElement;
    if (canvas1.getContext) {
      let ctx = canvas1.getContext('2d');

      const lic = new Image();

      lic.onload = () => {
        ctx.textAlign = 'left';
        ctx.font = 'bold 13px arial';
        ctx.drawImage(lic, 0, 0);

        let v = 82;
        const h = 201;
        const rh = 20;
        ctx.fillText(this.dto.name, h, v);
        ctx.fillText(moment(this.dto.dateOfBirth).format(this.dateFormat), h, v += rh);
        ctx.fillText(this.dto.nationalityTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.countryTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.passportID, h, v += rh);
        ctx.fillText(Gender[this.dto.gender], h, v += rh);
        ctx.fillText(this.dto.bloodGroup, h, v += rh);
        ctx.fillText(`${this.dto.accordingToTitles[Language.English]} License`, h, v += rh);
        ctx.fillText(this.dto.permitClasses.join(', '), h, v += rh);

        ctx.fillText(_issueDate, 62, 220);
        ctx.fillText(_expiryDate, 62, 241);

        ctx.font = 'bold 20px arial';
        ctx.fillText(Math.abs(this.dto.serial).toString(), 24, 60);

        let photo = new Image();
        photo.onload = () => {
          let x = 20, y = 66, width = 98, height = 133, radius = 5;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
          ctx.closePath();
          ctx.clip();

          ctx.drawImage(photo, x, y, width, height);
          ctx.restore();

          const qr = new Image();
          qr.onload = () => {
            ctx.drawImage(qr, 319, 92.4, 88, 88);
          }
          qr.src = this.dto.issued ? this.qRCodeImg : 'assets/images/qr.png';

          const ptt = new Image();
          ptt.onload = () => {

            ctx.save();
            ctx.globalAlpha = 0.25;
            const ptrn = ctx.createPattern(ptt, 'repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0, 0, 425, 265);
            ctx.restore();

            //draw thumb
            let canvas1Thumb = document.getElementById('lic-canvas-thumb') as HTMLCanvasElement;
            let thumbCtx = canvas1Thumb.getContext('2d');
            //thumbCtx.scale(0.2, 0.2);
            thumbCtx.drawImage(ctx.canvas, 0, 0);
          }
          ptt.src = 'assets/images/sample-stamp.png';
        }
        photo.src = this.dto.attachments[0] as string;
        //photo.src = (<any>this.dto.attachments[0]).changingThisBreaksApplicationSecurity;
      }
      lic.src = "assets/images/license-empty-new.png";
    }
  }

    let canvas2 = document.getElementById('stk-canvas') as HTMLCanvasElement;
    if (canvas2.getContext) {
      let ctx = canvas2.getContext('2d');

      let stk = new Image();
      stk.onload = () => {
        ctx.textAlign = 'left';
        ctx.font = 'bold 14px arial';
        ctx.drawImage(stk, 0, 0);

        let v = 162;
        const h = 152;
        const rh = 25.75;

        ctx.fillText(this.dto.name, h, v);
        ctx.fillText(moment(this.dto.dateOfBirth).format(this.dateFormat), h, v += rh);
        ctx.fillText(Gender[this.dto.gender], h, v += rh);
        ctx.fillText(this.dto.nationalityTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.passportID, h, v += rh);
        ctx.fillText(this.dto.countryTitles[Language.English], h, v += rh);
        ctx.fillText(`${this.dto.accordingToTitles[Language.English]} License`, h, v += rh);
        ctx.fillText(this.dto.permitClasses.join(', '), h, v += rh);
        ctx.fillText(this.dto.bloodGroup, h, v += rh);
        ctx.fillText(_issueDate, h, 485);
        ctx.fillText(_expiryDate, h, 508);

        ctx.font = 'bold 25px arial';
        ctx.fillText(Math.abs(this.dto.serial).toString(), h, 120);

        const photo = new Image();
        photo.onload = () => {
          const x = 270, y = 355, width = 110, height = 152, radius = 5;

          ctx.rect(x, y, width, height);
          //ctx.stroke();
          ctx.drawImage(photo, x, y, width, height);

          const qr = new Image();
          qr.onload = () => {
            ctx.drawImage(qr, 287, 230, 95, 95);
          }
          qr.src = this.dto.issued ? this.qRCodeImg : 'assets/images/qr.png';

          const ptt = new Image();
          ptt.onload = () => {
            ctx.save();
            ctx.globalAlpha = 0.2;
            const ptrn = ctx.createPattern(ptt, 'repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0, 0, 425, 600);
            ctx.restore();

            //draw thumb
            let canvas2Thumb = document.getElementById('stk-canvas-thumb') as HTMLCanvasElement;
            let thumbCtx = canvas2Thumb.getContext('2d');
            //thumbCtx.scale(0.2, 0.2);
            thumbCtx.drawImage(ctx.canvas, 0, 0);
          }
          ptt.src = 'assets/images/sample-stamp.png';
        }
        //photo.src = this._personalPhoto;
        photo.src = this.dto.attachments[0] as string;
        //photo.src = (<any>this.dto.attachments[0]).changingThisBreaksApplicationSecurity;

      }
      stk.src = "assets/images/sticker-empty.png";
    }
  }
}
