import { Directive, TemplateRef } from '@angular/core';
import { ConfirmState } from '../models/modal';


// @Directive({
//     selector: "[deleteConfirm]"
//   })
//   export class DeleteConfirmTemplateDirective {
//     constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
//       state.deleteTemplate = confirmTemplate;
//     }
//   }

//   @Directive({
//     selector: "[updateConfirm]"
//   })
//   export class UpdateConfirmTemplateDirective {
//     constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
//       state.updateTemplate = confirmTemplate;
//     }
//   }

@Directive({
  selector: "[confirm]"
})
export class ConfirmTemplateDirective {
  constructor(template: TemplateRef<any>, state: ConfirmState) {
    state.template = template;
  }
}