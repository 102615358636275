import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { VehicleModelsVm, VehicleModelDto } from '../models/vehicle-model';

@Injectable()
export class VehicleModelsService {
    constructor(private api: ApiHandler) { }

    get(makeId = 0, stockTypeId = null, lang = null) {
        // const params = [makeId];
        // if (stockTypeId)
        //     params.push(stockTypeId ? stockTypeId : nul);

        return this.api.get<VehicleModelsVm>(EndPoint.VEHICLE_MODELS, [makeId, stockTypeId], { lang }).toPromise();
    }

    create(dto: VehicleModelDto) {
        return this.api.create<VehicleModelDto, number>(EndPoint.VEHICLE_MODELS, dto).toPromise();
    }

    update(dto: VehicleModelDto) {
        return this.api.update(EndPoint.VEHICLE_MODELS, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.VEHICLE_MODELS, id).toPromise();
    }
}