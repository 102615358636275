import { Router } from '@angular/router';
import { AccountDto } from './../../models/account';
import {
    AccountsService,
    DynamicFlatNode,
    DynamicDataSource,
    //  DynamicDatabase
} from './../../services/accounts.service';

import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ViewChild, ViewEncapsulation, ɵConsole } from '@angular/core';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DeleteModal, UpdateModal } from 'src/app/shared/models/modal';
import { AccountTypesService } from 'src/app/features/lookups/services/account-types';
import { TranslateService } from '@ngx-translate/core';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { Role } from 'src/app/core/models/enums/role';
import { Language } from 'src/app/core/models/enums/language';
import { AccountType } from 'src/app/core/models/enums/account-type';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';

@Component({
    selector: 'mac-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss'],
    providers: [AccountsService, AccountTypesService]
})

export class AccountsComponent {
    tooltipInfo = 'Info about the action';
    currentAccountId: number;
    treeControl: FlatTreeControl<DynamicFlatNode>;
    dataSource: DynamicDataSource;
    accountTypes: number[] = [];
    badgeClasses: string[] = [];
    langIndex: number = 0;
    accountUsersCount: number = 0;
    //totalUserAccounts: number = 0;
    usersCount: number = 0;

    updateAllowed: boolean;
    deleteAllowed: boolean;
    createAllowed: boolean;
    usersDisplay: boolean;

    @ViewChild('tree', { static: true }) tree;

    getLevel = (node: DynamicFlatNode) => node.level;
    isExpandable = (node: DynamicFlatNode) => node.expandable;
    hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.item.hasChildren; // _nodeData.item.children.length > 0;// .expandable;
    Role() {
        return Role;
    }

    constructor(/*private database: DynamicDatabase,*/
        private router: Router,
        private service: AccountsService,
        private confirmService: ConfirmService,
        private loader: LoadingService,
        public auth: AuthService,
        private alert: AlertService,
        //private accountTypesService: AccountTypesService,
        public translate: TranslateService) {
        this.currentAccountId = auth.currentUser.accountId;
        this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new DynamicDataSource(this.treeControl, this.service);
    }

    ngOnInit() {
        document.querySelector('mat-card').classList.remove('overflow-hidden');
        //document.querySelector('mat-card').setAttribute('style', '')
        this.updateAllowed = this.auth.currentUser.isInRole(Role.ACCOUNTS_UPDATE);
        this.deleteAllowed = this.auth.currentUser.isInRole(Role.ACCOUNTS_DELETE);
        this.createAllowed = this.auth.currentUser.accountTypeId <= AccountType.Distributor && this.auth.currentUser.isInRole(Role.ACCOUNTS_CREATE);
        this.usersDisplay = this.auth.currentUser.isInRole(Role.USERS_READ);

        this.langIndex = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
        this.translate.onLangChange.subscribe(t => this.langIndex = t.lang == 'ar' ? Language.Arabic : Language.English);

        this.badgeClasses = [
            'branches bg-primary',
            'agent bg-success',
            'distributor bg-danger',
            'Sub-Distributor bg-orange'
        ];

        //this.dataSource.dataChange.subscribe(d => //console.warn(d));


        this.loader.load(LoaderType.Nav);
        // this.accountTypesService.get().then(d => {
        //     this.accountTypes = d.accountTypes.map(d => d.id);
        // })
        this.service.initialData()
            .then(data => {
                ////console.warn(data);
                this.dataSource.data = data[0];
                this.accountUsersCount = data[0].find(d => d.item.id == this.currentAccountId).item.usersCount;
                this.usersCount = data[1];

                //   //console.warn(this.service.dataMap.entries);

                // this.dataSource.data.splice(1, 1);
                // this.dataSource.dataChange.


            })
            .finally(() => this.loader.load(LoaderType.Nav, false));
    }

    getAccountType() {
        const prefix = 'ACC_TITLE_ACCOUNTS_';

        if (this.auth.currentUser.accountTypeId == 1 && this.auth.currentUser.isSystemAccount)
            return prefix + "BRANCHES";

        if (this.auth.currentUser.accountTypeId == 1)
            return prefix + "AGENTS";

        if (this.auth.currentUser.accountTypeId == 2)
            return prefix + "DISTRIBUTORS";

        return prefix + "SUB_DISTRIBUTORS";
    }

    getDocumentTypes(stockTypes: StockTypeDto[]) {
        //todo: refactor
        const drivingLicense = ['رخصة قيادة', 'Driving License'];
        const triptikDocument = ['دفتر تربتيك', 'Trip-Tik Document'];
        return Array.from(new Set(stockTypes.map(x => { return x.documentType == 1 ? drivingLicense[this.langIndex] : triptikDocument[this.langIndex] })));
    }

    ngAfterViewInit() {

    }
    onSelectInactive(checked: boolean) {
        //console.warn(checked);

        this.dataSource.isActiveChange.next(checked);

        //   const _data = this.dataSource.data.filter(d => !checked || d.item.isActive)
        // this.dataSource.dataChange.next(_data);
    }

    getBadgeClass(accountTypeId: number) {
        // const i = this.accountTypes.findIndex(id => id == accountTypeId);
        // return this.badgeClasses[i >= this.badgeClasses.length ? this.badgeClasses.length - 1 : i];

        const i = accountTypeId <= this.badgeClasses.length ? accountTypeId - 1 : 0;
        return this.badgeClasses[i];
    }

    tryDelete(node: AccountDto) {
        let modal = this.confirmService.confirm(new DeleteModal({
            type: 'ACC_LABEL_ACCOUNT',
            confirmQuestion: 'SEC_USER_CONFIRM_DELETE_CONFIRMATION_QUESTION',
            title: node.titles[this.langIndex].toString(),
            note: 'ACC_CONFIRM_DELETE_NOTE',
            dangerNote: 'ACC_CONFIRM_DELETE_DANGER_NOTE'
        }));

        modal.then(m => {
            if (m.indexOf('CONFIRMED') > -1)
                this.delete(node.id);
        });
    }

    delete(id: number) {
        this.loader.load(LoaderType.Body);
        this.service.deleteAccount(id)
            .then(r => {
                if (r) {
                    this.dataSource.dataChange.next(this.dataSource.data.filter(d => d.item.id != id));
                    this.alert.success(DefaultAlertAction.DELETING);
                } else this.alert.failure(DefaultAlertAction.DELETING);
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }

    tryUpdateStatus(row, active) {
        //  //console.warn(row);
        //const _data = this.dataSource.data;
        const _data = this.dataSource.dataChange.value;
        //const i = _data.filter(d => d.item.id == row.id);

        if (!active) {
            let modal = this.confirmService.confirm(new UpdateModal({
                type: 'ACC_LABEL_ACCOUNT',
                confirmQuestion: 'SEC_USER_CONFIRM_DEACTIVATE_CONFIRMATION_QUESTION',
                title: row.titles[this.langIndex].toString(),
                note: 'ACC_CONFIRM_UPDATE_NOTE',
                dangerNote: 'ACC_CONFIRM_UPDATE_DANGER_NOTE',
                action: '_ACTION_DEACTIVATE',
                submitText: '_ACTION_DEACTIVATE'
            }));
            const i = this.dataSource.data.findIndex(n => n.item.id == row.id);
            modal.then(m => {
                if (m.indexOf('CONFIRMED') > -1)
                    this.updateStatus(row.id, active);
            }).catch(e => {
                _data[i].item.isActive = true;
            })
        }
        else this.updateStatus(row.id, active);
    }

    //client-side only, after server update
    private _updateNodeStatus(id: number, active: boolean) {

        const i = this.dataSource.dataChange.value.findIndex(n => n.item.id == id);
        if (i > -1) {
            this.dataSource.dataChange.value[i].item.isActive = active;
            this.dataSource.dataChange.value.filter(d => d.item.parentId == id).forEach(element => {
                this._updateNodeStatus(element.item.id, active);
            });

            // this.service.dataMap.forEach((dto, key) => {
            //     dto.filter(d => d.id == id)[0].isActive = active;
            //     dto.filter(d => d.parentId == id).forEach(d => {
            //         this._updateNodeStatus(d.id, active);
            //     })
            // });
        }
    }

    updateStatus(id, active) {
        this.loader.load(LoaderType.Body);
        //const _data = this.dataSource.data;
        const _data = this.dataSource.dataChange.value;
        const i = this.dataSource.data.findIndex(n => n.item.id == id);
        //const _val = _data[i].item.isActive;
        //console.log(_val);

        this.service.updateAccountStatus({ id: id, active: active })
            .then(r => {
                if (r) {
                    //clear cache
                    this.service.dataMap.clear();
                    this.alert.success(active ? '_ACTION_ACTIVATING' : '_ACTION_DEACTIVATING');
                    //cascade change to all nodes
                    _data.filter(d => d.item.parentId == id).forEach(element => {
                        this._updateNodeStatus(element.item.id, active);
                    });
                    //this._updateNodeStatus(id, active);
                    ////console.log(this.service.dataMap);

                    //flush isactive status
                    this.dataSource.isActiveChange.next(this.dataSource.isActiveChange.value);
                }
                else {
                    _data[i].item.isActive = true;
                    this.alert.failure(active ? '_ACTION_ACTIVATING' : '_ACTION_DEACTIVATING');
                }

                // this.dataSource.statusChange.next([id, active]);
            }).catch(e => {
                _data[i].item.isActive = !active;
            })
            .finally(() => this.loader.load(LoaderType.Body, false));
    }

    update(id: number) {
        this.router.navigate(['/', this.translate.currentLang, 'accounts', 'update', id]);
    }

    filterChanged(filterText: string) {
    }
}
