import { AuthService } from './../../auth/auth.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utils } from 'src/app/shared/services/utils';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'mac-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  styleLoading: boolean;
  form: FormGroup;
  error: any;

  constructor(private auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private loader: LoadingService,
    private fb: FormBuilder) {


    this.translate.setDefaultLang('ar');
    this.translate.get("last.dummy");
    LoadingService.style.next(false);
  }

  private loadStyle(lang: string) {

    const head = this.document.getElementsByTagName('head')[0];
    const body = this.document.getElementsByTagName('body')[0];

    body.setAttribute('dir', lang == 'ar' ? 'rtl' : 'ltr');
    const styleFileName = lang == 'ar' ? 'bootstrap-rtl.css' : 'bootstrap.css';
    let themeLink = this.document.getElementById('bootstrap') as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleFileName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'bootstrap';
      style.rel = 'stylesheet';
      style.href = styleFileName;
      //will be always overriden by global styles.scss
      head.prepend(style);
    }
  }

  ngOnInit() {
    //AuthService.logout();

    this.formInit();
    this.translate.get("last.dummy");

    //this.translate.use('ar');
    this.translate.onLangChange.subscribe(l => {
      //this.loader.load(null);
      this.loadStyle(l.lang);
      // this.loader.load(null, false);
    });

    // this.loader.styleLoading.subscribe(status => {
    //   this.styleLoading = status;
    // });

    LoadingService.style.subscribe(status => {
      this.styleLoading = status;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loader.load(LoaderType.Nav, false);
      //LoadingService.style.next(false);
    }, 500);

    //this.loader.load(null, false);
  }

  onSubmit() {
    this.error = null;

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Nav);
    this.form.disable();

    this.auth.login(this.form.value)
      .then(result => {
        LoadingService.style.next(true);
        
        this.route.snapshot.queryParamMap.get('returnUrl') ?
          this.router.navigateByUrl(this.route.snapshot.queryParamMap.get('returnUrl'))
          : this.router.navigate(['/', this.translate.currentLang ? this.translate.currentLang : 'ar']);
      })
      .catch(e => {
        this.form.enable();
        if (e instanceof HttpErrorResponse) {
          if (e.status == 400) {
            this.error = e.error.login ? e.error.login[0] : null;
          } else
            this.error = "_MESSAGE_SERVER_ERROR";
        }
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  private formInit() {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false]
    });
  }
}
