import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { result } from 'lodash';
import { _DocumentType } from 'src/app/core/models/enums/document-type';
import { Language } from 'src/app/core/models/enums/language';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { IssuingsPerMonthDataSetDto } from 'src/app/features/statistics/models/statistics.model';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoaderType, LoadingService } from 'src/app/shared/services/loading';
import { Utils } from 'src/app/shared/services/utils';
import { DocumentValidityVm } from '../../models/issuing.models';
import { IssuingService } from '../../services/issuing.service';

@Component({
  selector: 'mac-expired-documents-query',
  templateUrl: './expired-documents-query.component.html',
  styleUrls: ['./expired-documents-query.component.scss'],
  providers: [StockTypesService, IssuingService]
})
export class ExpiredDocumentsQueryComponent implements OnInit {

  form: FormGroup;
  stockTypes: StockTypeDto[] = [];
  lang: Language;
  dto: DocumentValidityVm;

  get stockType() { return this.form.get('stockTypeId'); };
  get serial() { return this.form.get('serial'); };
  get passportIDorIdentityID() { return this.form.get('passportIDorIdentityID'); };

  constructor(private fb: FormBuilder,
    private stockTypesService: StockTypesService,
    private issuingService: IssuingService,
    public translate: TranslateService,
    private loader: LoadingService,
    private alert: AlertService) { }

  ngOnInit(): void {
    this.loader.load(LoaderType.Nav);
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);
    this.formInit();

    this.stockTypesService.get(this.lang)
      .then((result) => this.stockTypes = result.list.filter(x => x.documentType == _DocumentType.TripTikDocument))
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }

  private formInit() {
    this.form = this.fb.group({
      stockTypeId: ['', [Validators.required]],
      serial: ['', [Validators.required]],
      passportIDorIdentityID: ['', [Validators.required]]
    })
  }

  onSubmit() {
    // console.log(this.form.value);

    // if (!this.form.valid)
    //   return Utils.validateAllFormFields(this.form);

    // this.loader.load(LoaderType.Body);
    // this.issuingService.searchForExpiredDocument(this.stockType.value, this.serial.value, this.passportIDorIdentityID.value)
    //   .then(result => {
    //     console.log(result);

    //     this.dto = result;
    //     if (!this.dto) {
    //       this.alert.failure("_MESSAGE_NO_SEARCH_RESULTS", false);
    //     }

    //   }).catch(e => console.error(e))
    //   .finally(() => this.loader.load(LoaderType.Body, false))
  }

}
