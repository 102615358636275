import { Subject } from 'rxjs';
import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class LoadingService{
    public navLoading: EventEmitter<boolean>;
    public bodyLoading: EventEmitter<boolean>;
    public styleLoading: EventEmitter<boolean>;

    constructor(){
        this.navLoading = new EventEmitter();
        this.bodyLoading = new EventEmitter();
        this.styleLoading = new EventEmitter();
    }

    static style: Subject<boolean> = new Subject();

    public load(type: LoaderType = null, loading: boolean = true){
        switch(type){
            case LoaderType.Nav:
                this.navLoading.emit(loading);
            break;
            case LoaderType.Body:
                this.bodyLoading.emit(loading);
            break;

            default:
                this.styleLoading.emit(loading);
            break;
        }
    }
}

export enum LoaderType {
    Nav,
    Body
}