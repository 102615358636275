import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndPoint } from '../models/enums/end-point';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem("jwt_token;" + environment.apiBaseUrl);

    if (idToken && !this.auth.isTokenExpired() && req.url.indexOf(`${environment.apiBaseUrl}/${EndPoint.AUTH}`) == -1) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + idToken)
      });
      //var decoded = jwt_decode(idToken);
      return next.handle(cloned);
    }
    else {
      AuthService.authActivity.next(false);
      return next.handle(req);
    }
  }
}

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];