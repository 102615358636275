import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
//import { AccountTypeDto, AccountTypesVm } from 'src/app/core/nswag.api';
import { Language } from 'src/app/core/models/enums/language';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { AccountTypesVm, AccountTypeDto } from '../models/account-type';

@Injectable()
export class AccountTypesService {
    constructor(private api: ApiHandler) { }

    getAllowed() {
        return this.api.get<AccountTypesVm>(EndPoint.ACCOUNT_TYPES_ALLOWED).toPromise();
    }

    get(lang: Language = null) {
        return this.api.get<AccountTypesVm>(EndPoint.ACCOUNT_TYPES, null, { lang }).toPromise();
    }

    create(dto: AccountTypeDto) {
        return this.api.create<AccountTypeDto, number>(EndPoint.ACCOUNT_TYPES, dto).toPromise();
    }

    update(dto: AccountTypeDto) {
        return this.api.update(EndPoint.ACCOUNT_TYPES, dto).toPromise();
    }

    delete(id: number) {
        return this.api.delete(EndPoint.ACCOUNT_TYPES, id).toPromise();
    }
}