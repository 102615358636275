import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { StockTypesService } from 'src/app/features/lookups/services/stock-types';
import { TranslateService } from '@ngx-translate/core';
import { AvailableStock, AvailableStocksVm } from '../../models/available-stocks';
import { StockSliderComponent } from '../../components/stock-slider/stock-slider.component';
import { StocksService } from '../../services/stocks.service';
import { AccountDto } from 'src/app/features/accounts/models/account';
import { AccountsService } from 'src/app/features/accounts/services/accounts.service';
import { TransactCmd } from '../../models/transact.cmd';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { Language } from 'src/app/core/models/enums/language';
import { AccountType } from 'src/app/core/models/enums/account-type';
import { StockTypeDto } from 'src/app/features/lookups/models/stock-type';
//import { StockType } from 'src/app/core/models/enums/stock-type';
import { Role } from 'src/app/core/models/enums/role';

@Component({
  selector: 'mac-transact',
  templateUrl: './transact.component.html',
  styleUrls: ['./transact.component.scss'],
  providers: [StockTypesService, StocksService, AccountsService]
})
export class TransactComponent implements OnInit {
  selectedStock: number[];
  availableStock: AvailableStock;
  stockTypes: StockTypeDto[];
  _stockTypes: StockTypeDto[] = [];
  stockType: StockTypeDto = { id: 0, titles: [] };
  fromAccounts: any[] = [];
  toAccounts: AccountDto[];
  lang: number;
  fromAccountId: number;
  toAccountId: number;
  defaultStock: number[] = [0];

  @ViewChild(StockSliderComponent) slider: StockSliderComponent;

  constructor(private loader: LoadingService,
    private router: Router,
    private alert: AlertService,
    private stockTypesService: StockTypesService,
    private stocksService: StocksService,
    private translate: TranslateService,
    private accountsService: AccountsService,
    private auth: AuthService) { }

  async ngOnInit() {
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.loader.load(LoaderType.Nav);


    if (this.auth.currentUser.isSystemAccount && this.auth.currentUser.isInRole(Role.SYSTEM_STOCKS_CREATE)) {
      this.fromAccounts.push({ id: 0, titles: [this.translate.instant('STK_LABEL_PRIMARY_STOCK')] });
      //this.stockTypes = (await this.stockTypesService.get(this.lang)).list;
    }


    // this.stockTypes = (await this.stockTypesService.getAllowed(this.auth.currentUser.accountId)).list;


    // //this.stockTypes = (await this.stockTypesService.getAllowed(this.auth.currentUser.accountId)).list.filter(x => x.id == StockType.License);
    // if (this.stockTypes.length == 1)
    //   this.stockType = this.stockTypes[0];

    this.fromAccounts.push({ id: this.auth.currentUser.accountId, titles: this.auth.currentUser.accountTitles[this.lang] });
    this.fromAccountId = this.fromAccounts[0].id;
    this.onSelectFromAccount();
  }

  reset() {
    this.fromAccountId = this.fromAccounts[0].id;
    this.toAccountId = this.toAccounts && this.toAccounts.length > 0 ? this.toAccounts[0].id : null;
    this.stockType = { id: 0, titles: [] };
    this.selectedStock = null;
  }

  async onSelectFromAccount() {
    this.loader.load(LoaderType.Body);
    const func = this.auth.currentUser.accountTypeId != AccountType.ReleaseCenter ?
      'getChildrenAccounts' : 'getAccounts';
    try {
      this.toAccounts = [];
      const accounts = await (await this.accountsService[func](this.fromAccountId)).accounts;
      // .then(d => {

      const _accounts = [...accounts]
        .filter(x => x.isActive)
        .map(x => {   //todo: filter by lang on server instead
          x.titles = [x.titles[this.lang]]
          return x;
        });




      //load stock-types here 

      if (this.auth.currentUser.isSystemAccount && this.auth.currentUser.isInRole(Role.SYSTEM_STOCKS_CREATE) && this.fromAccountId == 0) {
        this._stockTypes = (await this.stockTypesService.get(this.lang)).list;
        this.stockTypes = [...this._stockTypes];
      }
      else
        this.stockTypes = (await this.stockTypesService.getAllowed(this.auth.currentUser.accountId, true)).list;

      //console.log(this.stockTypes);


      //this.stockTypes = (await this.stockTypesService.getAllowed(this.auth.currentUser.accountId)).list.filter(x => x.id == StockType.License);
      this.stockType = (this.stockTypes.length == 1) ? this.stockTypes[0] : { id: 0, titles: [] };

      this.toAccounts = _accounts;
      this.toAccountId = this.toAccounts && this.toAccounts.length > 0 ? this.toAccounts[0].id : null;
      await this.onSelectToAccount();


    }
    finally {
      //  .finally(() => {

      this.loader.load(LoaderType.Nav, false);
      this.loader.load(LoaderType.Body, false);

    }
    //});

    //this.slider.fromAccount = this.fromAccounts.find(a => a.id == this.fromAccountId).titles;

    if (this.stockType.id != 0)
      await this.onSelectStockType();

  }

  async onSelectToAccount() {

    if (this.auth.currentUser.isSystemAccount && this.auth.currentUser.isInRole(Role.SYSTEM_STOCKS_CREATE) && this.fromAccountId == 0) {
      const toAccount = this.toAccounts.find(x => x.id == this.toAccountId);
      //filter stock-types
      this.stockTypes = [...this._stockTypes].filter(x => (toAccount.accountTypeId == AccountType.ReleaseCenter && x.isUnifiedRelease)
        || (toAccount.accountTypeId != AccountType.ReleaseCenter && !x.isUnifiedRelease));


    }
    else {
      //get destination stock-types
      this.accountsService
      this.stockTypes = (await this.stockTypesService.getAllowed(this.toAccountId, true)).list;
     // console.log(this.stockTypes);
    }


    this.onSelectStockType();

    if (this.slider)
      this.slider.transactionDetails.toAccount = this.toAccounts.find(a => a.id == this.toAccountId).titles[this.lang].toString();
  }

  //stockType: string = '';
  async onSelectStockType() {
    //this.loader.load(LoaderType.Nav);
    this.loader.load(LoaderType.Body);
    //this.stockType = this.stockTypes.find(s => s.id == this.stockTypeId).titles;
    this.selectedStock = null;

    let stock =
      this.auth.currentUser.isSystemAccount && this.fromAccountId == 0 ?
        await this.loadSystemAvailableStock() :
        await this.loadAvailableStock();


    this.renderAvailableStock(stock);

    this.loader.load(LoaderType.Body, false);


  }

  onSelectRange() {
    setTimeout(() => {
      this.slider.transactionDetails.alertClass = 'primary';
      this.slider.transactionDetails.operation = 'STK_TITLE_TRANSACT';
      this.slider.transactionDetails.stockType = this.stockType.titles[0];
      this.slider.transactionDetails.fromAccount = this.fromAccounts.find(a => a.id == this.fromAccountId).titles;
      this.slider.transactionDetails.toAccount = this.toAccounts.find(a => a.id == this.toAccountId).titles[this.lang].toString();
      this.slider.onSelectRange(this.selectedStock);
    });
  }

  onSubmit() {
    this.loader.load(LoaderType.Body);
    const cmd: TransactCmd = {
      stockTypeId: this.stockType.id,
      sourceId: this.fromAccountId,
      targetId: this.toAccountId,
      fromRange: this.slider.fromRange,
      toRange: this.slider.toRange
    }
    this.stocksService.transact(cmd)
      .then(result => {
        result ?
          this.alert.success(DefaultAlertAction.TRANSFERING)
          : this.alert.failure(DefaultAlertAction.TRANSFERING);

        if (result)
          this.router.navigate(['/', this.translate.currentLang, 'stocks', 'transactions']);
      })
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  private loadAvailableStock() {
    return this.stocksService
      .getAvailableStocks(this.stockType.id);
    // .then(data => this.renderAvailableStock(data))
    // .finally(() => {
    //   this.loader.load(LoaderType.Body, false);
    // });
  }

  private loadSystemAvailableStock() {
    return this.stocksService
      .getSystemAvailableStocks(this.stockType.id);
    // .then(data => this.renderAvailableStock(data))
    // .finally(() => {
    //   this.loader.load(LoaderType.Body, false);
    //   //this.selectedStock = [0];// [];// [0];// this.availableStock.ranges[0];
    // });
  }

  private renderAvailableStock(vm: AvailableStocksVm) {
    this.availableStock = new AvailableStock();
    this.availableStock.singles = [];
    this.availableStock.ranges = [];

    vm.stockTypesAvailableStocks[0].availableStock.forEach(s => {
      if (s[0] > 0)
        s.length > 1 ? this.availableStock.ranges.push(s)
          : this.availableStock.singles.push(s);
    });
  }
}
