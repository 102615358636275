import { Component, OnInit, ElementRef } from '@angular/core';
import { TransactionDto } from '../../models/transactions';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'src/app/shared/services/utils';

@Component({
  selector: 'mac-trp-transactions-print',
  templateUrl: './trp-transactions-print.component.html',
  styleUrls: ['./trp-transactions-print.component.scss']
})
export class TrpTransactionsPrintComponent implements OnInit {

  transaction: TransactionDto;
  receiptTemplate: string;
  //transactionLevel: number;

  constructor(private elRef: ElementRef, public translate: TranslateService) { }

  ngOnInit(): void {
  }

  print(transaction: TransactionDto) {
    //console.log(transaction);
    this.transaction = transaction;
    //this.transactionLevel = transaction.fromAccountId && transaction.toAccountId ? 1 : 0;
    this.receiptTemplate = `/assets/images/TripTikStockTransactionsReceipt-ar.jpg`;

    setTimeout(() => {
      const printHtmlContent = this.elRef.nativeElement.innerHTML;// document.getElementById('stockReceipt').innerHTML;
      Utils.printHtml(printHtmlContent);
    }, 500);
  }

}