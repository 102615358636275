import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { TripTiksService } from './trip-tiks.service';
import { TripTikDto, TripTiksVm, TripTiksSearchQuery } from '../models/trip-tik';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { PaginationQuery } from '../../_shared/models/issuing.models';
import { populateAccountsSelect } from 'src/app/shared/helpers/populate-accounts-select';


export class TripTiksDataSource implements DataSource<TripTikDto> {
    public TripTiksSubject = new BehaviorSubject<TripTikDto[]>([]);

    public countSubject = new BehaviorSubject<number>(0);
    public counter$ = this.countSubject.asObservable();

    constructor(private tripTiksService: TripTiksService, private loader: LoadingService) { }

    load(pagination: PaginationQuery, query: TripTiksSearchQuery | string = null) {
        //console.warn(query);

        this.tripTiksService[(query == null || typeof query == 'string') ? 'getAll' : 'search'](pagination, <any>query)
            .pipe(
                catchError(() => of([])),
                finalize(() => { })
            )
            .subscribe((result: TripTiksVm) => {
                result.list.forEach(document => {
                    document.parentAccounts = populateAccountsSelect([document.parentAccounts])[0];
                });
                ////console.log(result);
                this.TripTiksSubject.next(result.list);
                this.countSubject.next(result.recordsCount);
                this.loader.load(LoaderType.Nav, false);
                this.loader.load(LoaderType.Body, false);
            });
    }

    connect(): Observable<TripTikDto[]> {
        return this.TripTiksSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.TripTiksSubject.complete();
        this.countSubject.complete();
    }
}
