import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { PostIntLicenseCmd } from '../models/create.cmd';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { IntLicenseVm, IntLicensesVm, IntLicensesSearchQuery, SerialHashVm } from '../models/int-license';
import { PrintIntLicenseCmd } from '../models/print.cmd';
import { PaginationQuery } from '../../_shared/models/issuing.models';

@Injectable()
export class IntLicensesService {
    constructor(private api: ApiHandler) { }

    delete(id: string) {
        return this.api.delete(EndPoint.INT_lICENSE, id).toPromise();
    }

    update(cmd: PostIntLicenseCmd) {
        return this.api.update(EndPoint.INT_lICENSE, cmd).toPromise();
    }

    create(cmd: PostIntLicenseCmd) {
        return this.api.create<PostIntLicenseCmd, string>(EndPoint.INT_lICENSE, cmd).toPromise();
    }

    get(id: string) {
        return this.api.get<IntLicenseVm>(EndPoint.INT_lICENSE, id).toPromise();
    }

    getByHashedSerial(key: string){
        return this.api.get<IntLicenseVm>(EndPoint.INT_lICENSE_GET_BY_HASHED_SERIAL, key).toPromise();
    }

    getLicenses(pagination: PaginationQuery, query: string = '') {
        return this.api.get<IntLicensesVm>(EndPoint.INT_lICENSE, null, { ...pagination, query });
    }


    search(pagination: PaginationQuery, query: IntLicensesSearchQuery) {
        return this.api.get<IntLicensesVm>(EndPoint.INT_lICENSE_SEARCH, null, { ...pagination, ...query });
    }

    print(cmd: PrintIntLicenseCmd) {
        return this.api.create<PrintIntLicenseCmd, SerialHashVm>(EndPoint.INT_lICENSE_PRINT, cmd).toPromise();
    }

    // tryRevoke(cmd: PrintIntLicenseCmd) {
    //     return this.api.create<PrintIntLicenseCmd, string>(EndPoint.INT_lICENSE_REVOKE_TRY, cmd).toPromise();
    // }
}

