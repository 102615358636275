import { Component, OnInit } from '@angular/core';
import { TripTiksClaimsService } from '../../services/trip-tiks-claims.service';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { ActivatedRoute } from '@angular/router';
import { TripTikClaimAttachmentDto } from '../../models/trip-tik-claim.model';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';

@Component({
  selector: 'mac-trip-tik-claim-attachments',
  templateUrl: './trip-tik-claim-attachments.component.html',
  styleUrls: ['./trip-tik-claim-attachments.component.scss'],
  providers: [TripTiksClaimsService, FileLoaderService]
})
export class TripTikClaimAttachmentsComponent implements OnInit {

  displayedColumns = ['created', 'createdBy', 'name', 'tools'];
  dataSource: TripTikClaimAttachmentDto[] = [];
  tripTikClaimEntranceID: string;

  constructor(private loader: LoadingService,
    private tripTiksClaimsService: TripTiksClaimsService,
    private route: ActivatedRoute,
    private fileLoader: FileLoaderService) { }

  ngOnInit(): void {
    this.loader.load(LoaderType.Nav);
    const tripTikClaimId = this.route.snapshot.paramMap.get('id');

    this.tripTiksClaimsService.getClaimAttachments(tripTikClaimId)
      .then(result => {
        //console.log(result);
        this.tripTikClaimEntranceID = result.tripTikClaimEntranceID;



        result.list.forEach(async att => {
          const file = await this.fileLoader.loadAsync(att.url as string);
          //console.log(att.url, file);
          att.url = file ? file as string : null;
          this.dataSource.push(att);
        });

        // for (let i = 0; i < this.dataSource.length; i++) {
        //   this.dataSource[i].url = (<any>await this.fileLoader.loadAsync(this.dto.attachments[0] as string)).changingThisBreaksApplicationSecurity;
        // }


        // this.dto.attachments[0] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[0] as string)).changingThisBreaksApplicationSecurity;
        // this.dto.attachments[1] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[1] as string)).changingThisBreaksApplicationSecurity;
        // this.dto.attachments[2] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[2] as string)).changingThisBreaksApplicationSecurity;

        // this.dataSource.forEach(async (att, i) => {
        //   att.url = await this.fileLoader.loadAsync(<string>att.url)
        // });
      })
      .finally(() => this.loader.load(LoaderType.Nav, false));
  }
}
