import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { LoaderType } from 'src/app/shared/services/loading';
import * as noUiSlider from 'nouislider';
import * as wNumb from 'wnumb';
import { StockRange } from '../../models/stock-range';
import { Utils } from 'src/app/shared/services/utils';
import { TransactionLabelDetails } from '../../models/transaction-label-details';

export class Range {
  from: number;
  to: number;
  count: number;
}



@Component({
  selector: 'mac-stock-slider',
  templateUrl: './stock-slider.component.html',
  styleUrls: ['./stock-slider.component.scss']
})
export class StockSliderComponent implements OnInit {

  transactionDetails: TransactionLabelDetails = {};

  count: number = 1;
  startRange = 0;
  fromRange: number;
  toRange: number;
  @Input() selectedStock: number[];
  @ViewChild('slider', { static: true }) slider: ElementRef;
  rangeEnabled: boolean;
  countMin: number = 1;
  countMax: number;
  rangeMin: number;
  rangeMax: number;
  //alertClass: string;
  //operation: string;
  // fromAccount: string = '';
  // toAccount: string = '';
  // stockType: string = '';

  _range: number[] = [];// {title: number, value: number}[] = [];
  _rangeCount: number[] = [];

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    document.querySelector('mat-card').classList.remove('overflow-hidden');
  }

  ngAfterViewInit() {
    noUiSlider.create(this.slider.nativeElement, {
      start: [1, 1],
      direction: this.translate.currentLang == 'ar' ? 'rtl' : 'ltr',
      tooltips: [false, false],
      //keyboardSupport: false,
      animate: true,
      animationDuration: 300,
      step: 1,
      connect: true,
      range: {
        'min': 1,
        'max': 2
      }
    });
    // disable initially
    //this._rangeEnable(false);
    this.slider.nativeElement.noUiSlider.on('slide', (e) => {
      this.fromRange = parseInt(e[0]);
      this.toRange = parseInt(e[1]);
      this.count = this.toRange - this.fromRange + 1;

      this.transactionDetails.fromRange = this.fromRange;
      this.transactionDetails.toRange = this.toRange;
      this.transactionDetails.count = this.count;
    });
  }

  resetSlider(value: number = 1) {
    this.slider.nativeElement.noUiSlider.updateOptions({
      start: [value, value],
      tooltips: [false, false],
      range: {
        'min': value,
        'max': value + 1
      }
    });
    this._rangeEnable(false);
  }

  onSelectRange(range: number[]) {

    // console.warn(range);
    // this._range 
    this._range = Utils.spreadRange(range);
    this._rangeCount = Utils.spreadRange([1, range[1] - range[0] + 1]);

    this.selectedStock = range ? range : this.selectedStock;
    this.fromRange = range[0];
    this.toRange = range.length > 1 ? range[1] : range[0];
    this.count = range.length > 1 ? range[1] - range[0] + 1 : 1;
    this.countMax = this.count;
    this.rangeMin = this.fromRange;
    this.rangeMax = this.toRange;

    if (this.count <= 1)
      this.resetSlider(this.fromRange);
    else {
      this._rangeEnable();
      this.slider.nativeElement.noUiSlider.updateOptions({
        start: [this.fromRange, this.toRange],
        //tooltips: [wNumb({ decimal: 0 }), wNumb({ decimal: 0 })],
        range: {
          'min': this.fromRange,
          'max': this.toRange
        }
      });
    }

    this.transactionDetails.fromRange = this.fromRange;
    this.transactionDetails.toRange = this.toRange;
    this.transactionDetails.count = this.count;
  }

  onRangeInput(control: 'from' | 'count' | 'to', value: number) {

    this.count = this.count < this.countMin ? this.countMin
      : this.count > this.countMax ? this.countMax
        : this.count;

    this.fromRange = this.fromRange < this.rangeMin ? this.rangeMin
      : this.fromRange > this.rangeMax ? this.rangeMax
        : this.fromRange;

    this.toRange = this.toRange < this.rangeMin ? this.rangeMin
      : this.toRange > this.rangeMax ? this.rangeMax
        : this.toRange;

    if (control == 'from') {
      if ((this.fromRange + this.count - 1) <= this.rangeMax)
        this.toRange = this.fromRange + this.count - 1;
      else {
        this.toRange = this.rangeMax;
        this.count = this.toRange - this.fromRange + 1;
        //this.fromRange -= (this.count - (this.toRange - this.fromRange) - 1);
      }
    } else if (control == 'to') {
      if ((this.toRange - this.count + 1) >= this.rangeMin)
        this.fromRange = this.toRange - this.count;
      else {
        this.fromRange = this.rangeMin;
        this.count = this.toRange - this.fromRange + 1;
      }
    } else {
      //count
      if ((this.fromRange + this.count - 1) <= this.rangeMax)
        this.toRange = this.fromRange + this.count - 1;
      else {
        this.toRange = this.rangeMax;
        this.fromRange -= (this.count - (this.toRange - this.fromRange) - 1);
      }
    }


    this.transactionDetails.fromRange = this.fromRange;
    this.transactionDetails.toRange = this.toRange;
    this.transactionDetails.count = this.count;

    // flush slider
    this.slider.nativeElement.noUiSlider.set([this.fromRange, this.toRange]);


    // switch (control) {
    //   case 'from':
    //     // if((this.count + value) <= this.toRange)



    //     this.count = this.toRange - this.fromRange + 1;
    //     break;

    //   case 'count':
    //     break;

    //   case 'to':

    //     this.count = this.toRange - this.fromRange + 1;
    //     break;
    // }

    // // flush slider
    // this.slider.nativeElement.noUiSlider.set([this.fromRange, this.toRange]);
  }

  // onRangeInputChange(event) {
  //   console.log('from range: ' + this.fromRange);
  //   console.log('to range: ' + this.toRange);
  //   console.log('count: ' + this.count);

  //   this.count = this.count < this.countMin ? this.countMin
  //     : this.count > this.countMax ? this.countMax
  //       : this.count;

  //   this.fromRange = this.fromRange < this.rangeMin ? this.rangeMin
  //     : this.fromRange > this.rangeMax ? this.rangeMax
  //       : this.fromRange;

  //   this.toRange = this.toRange < this.rangeMin ? this.rangeMin
  //     : this.toRange > this.rangeMax ? this.rangeMax
  //       : this.toRange;

  //   if (event == 'from') {
  //     if ((this.fromRange + this.count - 1) <= this.rangeMax)
  //       this.toRange = this.fromRange + this.count - 1;
  //     else {
  //       this.toRange = this.rangeMax;
  //       this.count = this.toRange - this.fromRange + 1;
  //       //this.fromRange -= (this.count - (this.toRange - this.fromRange) - 1);
  //     }
  //   } else if (event == 'to') {
  //     if ((this.toRange - this.count + 1) >= this.rangeMin)
  //       this.fromRange = this.toRange - this.count;
  //     else {
  //       this.fromRange = this.rangeMin;
  //       this.count = this.toRange - this.fromRange + 1;
  //     }
  //   } else {
  //     //count
  //     if ((this.fromRange + this.count - 1) <= this.rangeMax)
  //       this.toRange = this.fromRange + this.count - 1;
  //     else {
  //       this.toRange = this.rangeMax;
  //       this.fromRange -= (this.count - (this.toRange - this.fromRange) - 1);
  //     }
  //   }

  //   // flush slider
  //   this.slider.nativeElement.noUiSlider.set([this.fromRange, this.toRange]);
  // }

  private _rangeEnable(value: boolean = true) {
    if (value)
      this.slider.nativeElement.removeAttribute('disabled');
    else
      this.slider.nativeElement.setAttribute('disabled', true);
  }
}
