import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { SidenavService } from '../../services/sidenav.service';
import { UsersService } from 'src/app/features/security/services/users.service';
import { Role } from 'src/app/core/models/enums/role';
import { AccountType } from '../../models/enums/account-type';
import { EndPoint } from '../../models/enums/end-point';

@Component({
  selector: 'mac-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  providers: [UsersService]
})
export class SidenavComponent implements OnInit {

  constructor(public auth: AuthService,
    public router: Router,
    private elm: ElementRef,
    private renderer: Renderer2,
    private service: SidenavService,
    private userService: UsersService,
    public translate: TranslateService) {
    this.user = { name: '', avatar: this.defaultAvatar }
  }

  get Role() {
    return Role;
  }

  get AccountType() {
    return AccountType;
  }

  get EndPoint() { return EndPoint; };

  defaultAvatar = 'assets/images/user.svg';
  opened: boolean = true;
  user: {
    name: string;
    avatar: string;
  };

  getAccountType() {
    const prefix = 'ACC_TITLE_ACCOUNTS_';

    if (this.auth.currentUser.accountTypeId == 1 && this.auth.currentUser.isSystemAccount)
      return prefix + "BRANCHES";

    if (this.auth.currentUser.accountTypeId == 1)
      return prefix + "AGENTS";

    if (this.auth.currentUser.accountTypeId == 2)
      return prefix + "DISTRIBUTORS";

    return prefix + "SUB_DISTRIBUTORS";
  }

  ngOnInit() {
    //console.warn(this.getAccountType());
    ////console.log(this.auth.accountTypeId);
    ////console.log(this.auth.accountTypeId == AccountType.ReleaseCenter);
    AuthService.userMeta.subscribe(u => {
      ////console.log(u);
      this.user.name = u.name;
      this.user.avatar = u.avatar ? u.avatar : this.defaultAvatar;
    });

    this.userService.getUserMeta().then(u => {
      //this.user.name = u.name;
      //this.user.avatar = u.avatar ? `data:image/png;base64,${u.avatar}` : this.defaultAvatar;
      const _avatar = u.avatar ? `data:image/png;base64,${u.avatar}` : this.defaultAvatar;
      AuthService.userMeta.next({ id: u.id, name: u.name, avatar: _avatar });
    });

    this.service.opened.subscribe(o => {
      this.opened = o;
      if (!this.opened) {
        let slib = this.elm.nativeElement.querySelectorAll('.cont');
        slib.forEach(element => {
          if (element.classList.contains('show'))
            this.renderer.removeClass(element, 'show');
        });
      }
    });
  }

  ngAfterViewInit() {
    const links = this.elm.nativeElement.querySelectorAll('.nav-item');
    links.forEach(link => {
      link.addEventListener('click', this.linkClick.bind(this));
    });

  }

  linkClick(event) {
    if (!this.opened) {
      let slib = this.elm.nativeElement.querySelectorAll('.cont');
      slib.forEach(element => {
        if (element.classList.contains('show'))
          this.renderer.removeClass(element, 'show');
      });
    }
  }


  logout() {
    AuthService.logout();
    //window.location.href = "/";
  }

  displayContext(event, id) {
    if (!this.opened) {
      let elm = this.elm.nativeElement.querySelector('#' + id);
      if (event.type != 'mouseleave')
        if (!elm.classList.contains('show')) {
          //make sure all other menus are closed
          let slib = this.elm.nativeElement.querySelectorAll('.cont');
          slib.forEach(element => {
            if (element.classList.contains('show'))
              this.renderer.removeClass(element, 'show');
          });
          this.renderer.addClass(elm, 'show');
        }

        else
          this.renderer.removeClass(elm, 'show');
    }
  }
}
