import { Component } from '@angular/core';


@Component({
  selector: 'mac-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  constructor() {}

}