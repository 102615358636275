import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TableService } from '../table.service';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ViewFilesDialogComponent } from '../view-files-dialog/view-files-dialog.component';
import { Role } from 'src/app/core/models/enums/role';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/models/enums/language';
import { LoadingService , LoaderType} from 'src/app/shared/services/loading';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  displayedColumns: string[] = ['serial', 'name', 'plateID', 'passportID', 'issued', 'expiryDate', 'actions'];
  dataSource: MatTableDataSource<any>;
  form: FormGroup;
  editingRowId: string = null;
  langIndex: number = 0;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  get Role() { return Role; };

  constructor(private fb: FormBuilder, private tableService: TableService, private dialog: MatDialog,public auth: AuthService, private translate: TranslateService ,private loader: LoadingService  ) { }

  ngOnInit() {
    this.langIndex = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.langIndex = t.lang == 'ar' ? Language.Arabic : Language.English);

    /* if (!this.auth.currentUser.isInRole(Role.LOOKUPS_UPDATE)
      && !this.auth.currentUser.isInRole(Role.LOOKUPS_DELETE)) 
      this.displayedColumns.splice(this.displayedColumns.length - 1, 1);*/

    this.loader.load(LoaderType.Nav);
    this.formInit();
    console.log('TableComponent initialized');
    this.loadData();
    this.loader.load(LoaderType.Nav, false);
  }

  loadData() {
    this.tableService.getData().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print1(id: string): void {
    this.tableService.generateNweeba(id).subscribe(response => {
      this.printImage(response, `Nweeba_${id}`);
    });
  }
  
  print2(id: string): void {
    this.tableService.generateImage(id).subscribe(response => {
      this.printImage(response, `Image_${id}`);
    });
  }
  
  printImage(imageData: Blob, imageName: string): void {
    const url = URL.createObjectURL(imageData);
    const img = new Image();
    img.src = url;
    img.onload = () => {
      const w = window.open('');
      w.document.write(`
        <html>
        <head>
          <title>${imageName}</title>
          <style>
            @page {
              size: A4;
              margin: 0;
            }
            body {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
            }
          </style>
        </head>
        <body>
          <img src="${url}" onload="window.print();window.close()" />
        </body>
        </html>
      `);
      w.document.close();
    };
  }
  private downloadFile(data: Blob, type: string, filename: string): void {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  openUploadDialog(tripTikDocumentId: string): void {
    this.tableService.checkFiles(tripTikDocumentId).subscribe(response => {
      if (response.exists) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: { message: 'هناك ملفات تخص هذا التريبتك مرفوعة من قبل هل تود الاستمرار فى رفع ملفات جديدة ؟ ام تريد رؤية الملفات المرفوعة مسبقا ؟' }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'yes') {
            this.dialog.open(UploadDialogComponent, {
              width: '400px',
              data: { tripTikDocumentId }
            });
          } else {
            this.dialog.open(ViewFilesDialogComponent, {
              width: '500px',
              data: { tripTikDocumentId, files: response.files }
            });
          }
        });
      } else {
        this.dialog.open(UploadDialogComponent, {
          width: '400px',
          data: { tripTikDocumentId }
        });
      }
    });
  }

  openViewFilesDialog(tripTikDocumentId: string): void {
    this.tableService.checkFiles(tripTikDocumentId).subscribe(response => {
      if (response.exists) {
        this.dialog.open(ViewFilesDialogComponent, {
          width: '500px',
          data: { tripTikDocumentId, files: response.files }
        });
      } else {
        // Handle case where no files exist
      }
    });
  }

  private formInit() {
    this.form = this.fb.group({
      id: [''],
      serial: [''],
      created: [''],
      createdBy: ['']
    });
  }
}
