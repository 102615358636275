import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';

import { ApiHandler } from 'src/app/core/api-handler';
import { AccountDto, AccountVm, AccountsVm } from './../models/account';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { PostAccountCmd } from '../models/account';
import { LookupDto, LookupVm } from '../../lookups/models/lookup';

export class DynamicFlatNode {
    constructor(public item: AccountDto, public level = 1, public expandable = false,
        public isLoading = false) { }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {

    dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
    isActiveChange = new BehaviorSubject<boolean>(false);

    get data(): DynamicFlatNode[] { return this.dataChange.value; }
    set data(value: DynamicFlatNode[]) {
        this._treeControl.dataNodes = value;
        this.dataChange.next(value);
    }

    constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
        private _database: AccountsService) { }

    connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
        this._treeControl.expansionModel.changed.subscribe(change => {
            if ((change as SelectionChange<DynamicFlatNode>).added ||
                (change as SelectionChange<DynamicFlatNode>).removed) {
                this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
            }
        });

        return merge(collectionViewer.viewChange, this.dataChange, this.isActiveChange)
            .pipe(map(() => {
                return this.data.filter(d => this.isActiveChange.value || d.item.isActive);
            }));
    }

    disconnect(collectionViewer: CollectionViewer): void { }

    /** Handle expand/collapse behaviors */
    handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
        if (change.added) {
            change.added.forEach(node => this.toggleNode(node, true));
        }
        if (change.removed) {
            change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
        }
    }

    toggleNode(node: DynamicFlatNode, expand: boolean) {
        node.isLoading = true;

        this._database.getChildren(node.item.id)
            .then(children => {
                const index = this.data.indexOf(node);
                if (!children || index < 0) { // If no children, or cannot find the node, no op
                    return;
                }

                if (expand) {
                    const nodes = children.map(_node =>
                        new DynamicFlatNode(_node, node.level + 1, this._database.isExpandable(_node.id)));
                    this.data.splice(index + 1, 0, ...nodes);
                }
                else {
                    let count = 0;
                    for (let i = index + 1; i < this.data.length
                        && this.data[i].level > node.level; i++, count++) { }
                    this.data.splice(index + 1, count);
                }

                this.dataChange.next(this.data);
            })
            .finally(() => node.isLoading = false);
    }
}


@Injectable()
export class AccountsService {
    dataMap = new Map<number, AccountDto[]>();
    rootLevelNodes: AccountDto[] = [];

    constructor(private api: ApiHandler) { }

    async initialData(): Promise<[DynamicFlatNode[], number]> {

        const data = await (await this.getChildrenAccounts(0));
        this.rootLevelNodes = data.accounts;
        //todo: include current account id
        this.dataMap.set(0, this.rootLevelNodes);

        return [this.rootLevelNodes.map(_node => new DynamicFlatNode(_node, 0, true)), data.usersCount]
    }

    async getChildren(parentId: number): Promise<AccountDto[] | undefined> {
        if (!this.dataMap.has(parentId)) {
            const nodes = await (await this.getChildrenAccounts(parentId)).accounts;
            //add to cache
            this.dataMap.set(parentId, nodes);
        }
        return this.dataMap.get(parentId);
    }

    isExpandable(parentId: number): boolean {
        if (this.dataMap.has(parentId)
            && this.dataMap.get(parentId).length > 0)
            return true;

        else
            return [...this.dataMap.values()]
                .filter((node) => node.find(x => x.id == parentId && x.hasChildren /*x.children.length > 0*/))
                .length > 0;
    }

    public getChildrenAccounts(id: number): Promise<AccountsVm> {
        return this.api.get<AccountsVm>(EndPoint.ACCOUNTS_CHILDREN, id).toPromise();
    }

    public getAccounts(id: number = 0): Promise<AccountsVm> {
        return this.api.get<AccountsVm>(EndPoint.ACCOUNTS).toPromise();
    }

    public getPossibleParentAccounts(accountId: number, accountTypeId: number): Promise<LookupVm> {
        return this.api.get<LookupVm>(EndPoint.ACCOUNTS, [accountId, accountTypeId]).toPromise();
    }

    public getChildrenAccountsLookup(accountId: number, isActiveOnly: boolean = true): Promise<LookupVm> {
        return this.api.get<LookupVm>(EndPoint.ACCOUNTS_CHILDREN_LOOKUP, [accountId, isActiveOnly]).toPromise();
    }

    // {{base_url}}/accounts/children/lookup/8

    public getAccount(id: number): Promise<AccountVm> {
        return this.api.get<AccountVm>(EndPoint.ACCOUNTS, id).toPromise();
    }

    public createAccount(cmd: PostAccountCmd) {
        return this.api.create<PostAccountCmd, Number>(EndPoint.ACCOUNTS, cmd).toPromise();
    }

    public updateAccount(cmd: PostAccountCmd) {
        return this.api.update(EndPoint.ACCOUNTS, cmd).toPromise();
    }

    public updateAccountStatus(cmd: { id: number, active: boolean }) {
        return this.api.update(EndPoint.ACCOUNTS, null, [cmd.id, cmd.active]).toPromise();
    }

    public deleteAccount(id: number) {
        return this.api.delete(EndPoint.ACCOUNTS, id).toPromise();
    }
}

