import { StockTypeDto } from '../../lookups/models/stock-type';
import { CustomsPortDto } from '../../lookups/models/customs-port';
import { CountryDto } from '../../lookups/models/country';
import { SafeUrl } from '@angular/platform-browser';
import { TripTikClaimStatusDto } from '../../lookups/models/trip-tik-claim-status';
import { LookupDto } from '../../lookups/models/lookup';

export interface TripTikClaimVm {
    item: TripTikClaimDto
}

export interface TripTiksClaimsVm {
    list: TripTikClaimDto[],
    recordsCount: number
}

export class TripTikClaimDto {
    id: string;
    entranceID: string;
    entranceDate: Date;
    created: Date;
    holderName: string;
    holderAddress: string;
    holderPhone: string;
    accountId: number;
    accountTitles: string[] = [];
    serial: number;
    stockType: StockTypeDto;
    country: CountryDto;
    customsPort: CustomsPortDto;
    pursuingZone: LookupDto;
    latestStatus: TripTikClaimStatusDto;
    vehiclePlateID: string;
    attachmentsCount: number;
    logsCount: number;
}

export interface TripTikClaimDetailsVm {
    item: TripTikClaimDetailsDto
}

export class TripTikClaimDetailsDto extends TripTikClaimDto {
    logs: TripTikClaimLogDetailsDto[] = [];
}

export interface UpdateStatusTripTikClaimCommand {
    id: string,
    statusId: number,
    note: string,
    attachments: { file: string, name: string, fileName: string }[]
}

export interface TripTikClaimAttachmentDto {
    name: string,
    url: string | SafeUrl,
    created: Date,
    createdBy: string
}

export interface TripTikClaimLogDetailsDto {
    created: Date,
    createdBy: string,
    note: string,
    status: TripTikClaimStatusDto,
    attachments: TripTikClaimAttachmentDto[]
}

export interface TripTiksClaimsLogsVm {
    list: TripTikClaimLogDto[],
    recordsCount: number
}

export interface TripTikClaimLogDto {
    created: Date,
    createdBy: string,
    holderName: string,
    serial: number,
    stockType: StockTypeDto,
    issued: Date,
    customPort: CustomsPortDto,
    status: TripTikClaimStatusDto
    note: string
}

export interface TripTiksClaimsSearchQuery {
    accountId?: number,
    serial?: number,
    stockTypeId?: number[],
    // stockTypes?: number[],
    fromEntranceDate?: string,
    toEntranceDate?: string,
    entranceID?: string,
    holderName?: string,
    vehiclePlateID?: number,
    countryId?: number[],
    customsPortId?: number[],
    // customsPorts?: number[],
    pursuingZoneId?: number,
    // latestStatusId?: number,
    baseStatusId?: number
    statusId?: number
}

export interface PostTripTikClaimCommand {
    entranceID: string,
    EntranceDate: Date,
    serial: number,
    stockTypeId: number,
    countryId: number,
    customsPortId?: number,
    pursuingZoneId: number
    value: number,
    currencyId: number,
    holderName: string,
    holderAddress: string,
    holderPhone: string,
}

export interface TripTikClaimAttachmentsVm {
    tripTikClaimEntranceID: string,
    list: TripTikClaimAttachmentDto[],
    recordsCount: number
}