import { Injectable } from '@angular/core';
import { ApiHandler } from 'src/app/core/api-handler';
import { EndPoint } from 'src/app/core/models/enums/end-point';
import { TripTikClaimsStatusesVm, TripTikClaimStatusDto } from '../models/trip-tik-claim-status';

@Injectable()
export class TripTikClaimsStatusesService {
    constructor(private api: ApiHandler) { }

    get(parentId, lang = null) {
        return this.api.get<TripTikClaimsStatusesVm>(EndPoint.TRIPTIK_CLAIMS_STATUSES, [parentId], { lang }).toPromise();
    }

    // getBaseStatuses(lang = null) {
    //     return this.api.get<TripTikClaimsStatusesVm>(EndPoint.TRIPTIK_CLAIMS_STATUSES_BASE, null, { lang }).toPromise();
    // }

    // create(dto: TripTikClaimStatusDto) {
    //     return this.api.create<TripTikClaimStatusDto, number>(EndPoint.TRIPTIK_CLAIMS_STATUSES, dto).toPromise();
    // }

    // update(dto: TripTikClaimStatusDto) {
    //     return this.api.update(EndPoint.TRIPTIK_CLAIMS_STATUSES, dto).toPromise();
    // }

    // delete(id: number) {
    //     return this.api.delete(EndPoint.TRIPTIK_CLAIMS_STATUSES, id).toPromise();
    // }
}