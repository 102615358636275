import { Component, OnInit } from '@angular/core';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { ActivatedRoute, Router } from '@angular/router';
import { TripTiksClaimsService } from '../../services/trip-tiks-claims.service';
import { TripTikClaimDto, UpdateStatusTripTikClaimCommand } from '../../models/trip-tik-claim.model';
import { FormBuilder, FormGroup, FormArray, FormControl, AbstractControl, Validators } from '@angular/forms';
// import { isArray } from 'util';
import { Utils } from 'src/app/shared/services/utils';
import { AlertService } from 'src/app/shared/services/alert.service';
import { DefaultAlertAction } from 'src/app/shared/models/default-alert-action';
import { TranslateService } from '@ngx-translate/core';
import { TripTikClaimsStatusesService } from 'src/app/features/lookups/services/trip-tik-claims-statuses.service';
import { Language } from 'src/app/core/models/enums/language';
import { result } from 'lodash';
import { TripTikClaimStatusDto } from 'src/app/features/lookups/models/trip-tik-claim-status';
import { stat } from 'fs';
import { TripTikClaimStatus } from 'src/app/core/models/enums/triptik-claim-status';
import { CurrenciesService } from 'src/app/features/lookups/services/currencies';
import { CurrencyDto } from 'src/app/features/lookups/models/currency';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Role } from 'src/app/core/models/enums/role';

@Component({
  selector: 'mac-update-status-trip-tik-claim',
  templateUrl: './update-status-trip-tik-claim.component.html',
  styleUrls: ['./update-status-trip-tik-claim.component.scss'],
  providers: [
    TripTiksClaimsService,
    TripTikClaimsStatusesService,
    CurrenciesService
  ]
})
export class UpdateStatusTripTikClaimComponent implements OnInit {
  lang: Language;
  dto = new TripTikClaimDto();
  form: FormGroup;
  id: string;
  currencies: CurrencyDto[] = [];
  baseStatuses: TripTikClaimStatusDto[] = [];
  nodeStatuses: TripTikClaimStatusDto[] = [];

  constructor(private tripTiksClaimsService: TripTiksClaimsService,
    private tripTikClaimsStatusesService: TripTikClaimsStatusesService,
    private currenciesService: CurrenciesService,
    private loader: LoadingService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public auth: AuthService,
    private fb: FormBuilder) { }

  async ngOnInit() {
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);
    this.id = this.route.snapshot.paramMap.get('id');

    this.loader.load(LoaderType.Nav);
    this.formInit();

    await Promise.all([
      this.tripTikClaimsStatusesService.get(null, this.lang)
        .then(result => this.baseStatuses = result.list),
      this.currenciesService.get(this.lang)
        .then(result => this.currencies = result.list)
    ]);

    this.tripTiksClaimsService.get(this.id)
      .then(async result => {
        this.dto = result.item;
        //console.log(this.dto);
        if (this.dto.latestStatus.parentId)
          await this.onSelectStatus(this.dto.latestStatus.parentId)

        this.form.patchValue({
          baseStatusId: this.dto.latestStatus.parentId,
          statusId: this.dto.latestStatus.id
        });
      })
      .finally(() => {
        if (!this.auth.currentUser.isInRole(Role.TRIPTIKS_CLAIMS_STATUS_UPDATE)) {
          this.baseStatus.disable();
          this.status.disable();
        }
        this.loader.load(LoaderType.Nav, false);
      });
  }

  get Object() { return Object; };
  get note() { return this.form.get('note'); };
  get attachments() { return this.form.get('attachments') as FormArray; };
  get baseStatus() { return this.form.get('baseStatusId') };
  get status() { return this.form.get('statusId') };
  get payment() { return this.form.get('payment') as FormGroup; };
  get receiptNo() { return this.payment.get('receiptNo'); };
  get currency() { return this.payment.get('currencyId'); };
  get value() { return this.payment.get('value'); };

  private formInit() {
    this.form = this.fb.group({
      baseStatusId: ['', [Validators.required]],
      statusId: ['', [Validators.required]],
      payment: this.fb.group({}),
      note: ['', [Validators.required]],
      attachments: this.fb.array([])
    });
  }

  private paymentFormInit() {
    this.payment.addControl('currencyId', this.fb.control(this.currencies.length > 0 ? this.currencies[0].id : '', [Validators.required]));
    this.payment.addControl('value', this.fb.control('', [Validators.required]));
    this.payment.addControl('receiptNo', this.fb.control('', [Validators.required]));
  }

  private attachmentFormInit() {
    this.attachments.push(this.fb.group({
      name: ['', [Validators.required]],
      file: ['', [Validators.required]],
      fileName: ['']
    }));
  }

  onSelectNestedStatus(status: number) {
    //console.log(status);
    if (status == TripTikClaimStatus.Paid) {
      this.paymentFormInit();
    } else {
      Object.keys(this.payment.controls).forEach(ctrl => {
        this.payment.removeControl(ctrl);
      });
    }
  }

  async onSelectStatus(statusId: number) {
    //console.log(statusId);
    this.loader.load(LoaderType.Body);
    this.nodeStatuses = await (await this.tripTikClaimsStatusesService.get(statusId, this.lang)).list;
    this.status.reset();
    this.loader.load(LoaderType.Body, false)
  }

  addAttachment() {
    this.attachmentFormInit();
  }

  removeAttachment(i) {
    this.attachments.removeAt(i);
  }

  onSubmit() {
    console.warn(this.form.value);

    if (!this.auth.currentUser.isInRole(Role.TRIPTIKS_CLAIMS_UPDATE))
      return;

    if (!this.auth.currentUser.isInRole(Role.TRIPTIKS_CLAIMS_STATUS_UPDATE)) {
      //this.baseStatus.setValue(this.dto.latestStatus.parentId);
      this.baseStatus.reset();
      this.baseStatus.setErrors(null);
      this.status.reset();
      this.status.setErrors(null);
    }

    if (!this.form.valid)
      return Utils.validateAllFormFields(this.form);

    this.loader.load(LoaderType.Body);

    const cmd = this.form.value as UpdateStatusTripTikClaimCommand;
    cmd.id = this.id;

    this.tripTiksClaimsService.updateStatus(this.form.value as UpdateStatusTripTikClaimCommand)
      .then(result => {
        if (result) {
          this.alert.success(DefaultAlertAction.ADDING);
          setTimeout(() => {
            this.router.navigate(['/', this.translate.currentLang, 'trip-tiks-claims', 'view', this.id])
          }, 1000);
        } else this.alert.failure(DefaultAlertAction.ADDING);
      })
      .finally(() => this.loader.load(LoaderType.Body, false))
  }

  //attachments
  preview($event, group: FormGroup) {
    const tgt = $event.target || window.event.srcElement,
      files = Array.isArray($event) ? $event : tgt.files;

    if (FileReader && files && files.length) {
      const fr = new FileReader();
      fr.onload = () => {
        //console.log(fr);
        if (fr.result.toString().length <= 2 * 2 ** 20) {
          group.get('file').setValue(fr.result.toString());
          group.get('fileName').setValue(files[0].name);
        }
        else
          alert('file limit is 2 MB.');
      };
      fr.readAsDataURL(files[0]);
    }
    else {
      //console.error('not supported!');
    }
  }

  fileDropped(file, group: FormGroup): void {
    //console.log(file);
    group.get('file').setValue(file.url);
    group.get('fileName').setValue(file.name);
  }
}
